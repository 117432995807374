export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,}$');
export const validName = new RegExp('^[a-zA-Z ]+$');
export const validPhoneNumber = new RegExp('^[0-9\\(\\)\\+\\-\\s]+$');
export const maxUserCount = 20;

export const columnPlaceholders = {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    phone: 'Phone Number',
    primaryLocation: 'Choose Primary Location',
    role: 'Choose Role',
    additionalLocations: 'Choose Secondary Location',
};

export const COLUMN_FIELD_NAME = {
    ROW_NO: 'id',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    PHONE: 'phone',
    PRIMARY_LOCATION: 'primaryLocation',
    ROLE: 'role',
    ADDITIONAL_LOCATIONS: 'additionalLocations',
};

export const COLUMN_HEADER_NAME = {
    ROW_NO: '#',
    FIRST_NAME: 'First Name*',
    LAST_NAME: 'Last Name*',
    EMAIL: 'Email*',
    PHONE: 'Phone',
    PRIMARY_LOCATION: 'Primary Location*',
    ROLE: 'Role*',
    ADDITIONAL_LOCATIONS: 'Additional Location(s)',
};

export const CELL_MODES = {
    EDIT: 'edit',
    VIEW: 'view',
};
