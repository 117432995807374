import { fetchWithAuthHeader } from './AuthUtils';

export const requestOems = async () => {
    const url = 'api/VehicleInfo/GetOemsAsync';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve oems', { status: response.status });
    return response.json();
};

export const requestOemHasVehicles = async (oemId, filterActive = false) => {
    const url = `api/VehicleInfo/Oem/${oemId}/HasVehicles?active=${filterActive}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve oems', { status: response.status });
    return response.json();
};

export const requestVehicle = async vehicleId => {
    const url = `api/VehicleInfo/GetVehicleById?vehicleId=${vehicleId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error(`Could not retrieve vehicle for vehicleId ${vehicleId}`);
    return response.json();
};

export const requestVehiclePublish = async (vehicleId, active) => {
    const url = `api/VehicleInfo/publish/${vehicleId}?active=${active}`;
    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}), //empty post body
    });

    if (!response.ok) throw new Error(`Failed to publish vehicle ${vehicleId}`);
};

export const requestVehiclePublishHistory = async (vehicleId, pageSize, page) => {
    let url = `api/VehicleInfo/GetVehiclePublishes?vehicleId=${vehicleId}`;

    if (pageSize) {
        url = `${url}&pageSize=${pageSize}`;
    }

    if (page) {
        url = `${url}&page=${page}`;
    }

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle publish history');
    return response.json();
};

export const requestLastVehiclePublishHistory = async vehicleId => {
    let url = `api/VehicleInfo/GetLastVehiclePublish?vehicleId=${vehicleId}`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle publish history');
    if (response.status === 200) return response.json();
    else return null;
};

/**
 * Retrieve list of groups (previously regions) for a vehicle using vehicleId
 * @deprecated: logic no longer used, please use requestRegions()
 * @param {number} vehicleId vehicle id
 * @returns a list of regions or undefined if not found (caller needs to deal with this situation)
 * @throws an error if the request failed
 */
export const requestGetVehicleRegions = async vehicleId => {
    const url = `api/VehicleInfo/GetVehicleRegions?vehicleId=${vehicleId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle regions');
    if (response.status === 204) return undefined;
    return await response.json();
};

export const requestGetVehicleTypesWithRegions = async () => {
    const url = 'api/VehicleInfo/GetVehicleTypesWithRegions';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle types with regions');
    return response.json();
};

export const requestVehicleByOem = async (oemId, modelId = null) => {
    let url = `api/VehicleInfo/GetVehiclesByOem?oemId=${oemId}`;

    //qualify by by model if filtering down to specified model
    if (modelId) url += `&modelId=${modelId}`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicles by oemId');
    return response.json();
};

export const requestSearchVehicleColors = async colorName => {
    const url = `api/VehicleInfo/ExteriorColor/SearchExteriorColorsByName/${colorName}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not find exterior color');
    return response.json();
};

export const requestCreateNewColor = async (colorName, colorHex) => {
    const url = `api/VehicleInfo/ExteriorColor/CreateNewColor/${colorName}/${colorHex}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not create color');
    return response.json();
};

export const requestMapExteriorColorToVehicle = async (vehicleId, externalColorId) => {
    const url = `api/VehicleInfo/ExteriorColor/MapExteriorColorToVehicle/${vehicleId}/${externalColorId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not map external color');
};

export const requestUnMapExteriorColorToVehicle = async (vehicleId, externalColorId) => {
    const url = `api/VehicleInfo/${vehicleId}/ExteriorColor/${externalColorId}`;
    let response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
    });
    if (!response.ok) throw new Error('Could not map external color');
};

export const requestUpdateExteriorColor = async exteriorColor => {
    const url = `api/VehicleInfo/ExteriorColor/UpdateExteriorColor/${exteriorColor.exteriorColorId}`;
    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            exteriorColorId: exteriorColor.exteriorColorId,
            exteriorColorName: exteriorColor.exteriorColorName,
            exteriorColorHex: exteriorColor.exteriorColorHex,
        }),
    });

    if (!response.ok) throw new Error('Could not update exterior color');
};

export const requestVehicleByYMM = async (yearId, oemId, modelId) => {
    const url = `api/VehicleInfo/Vehicle/GetVehicleByYMM/${yearId}/${oemId}/${modelId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get vehicle');
    return await response.json();
};

/**
 * Retrieve all groups available
 * @returns a list of regions if found
 * @returns undefined if not found (caller needs to deal with this situation)
 * @throws an error if the request failed
 */
export const requestRegions = async () => {
    const url = 'api/VehicleInfo/Region';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get regions');
    if (response.status === 204) return undefined;
    return await response.json();
};

export const requestYears = async () => {
    const url = 'api/VehicleInfo/Years';
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get years');
    return await response.json();
};

export const requestModelsForOem = async oemId => {
    const url = `api/VehicleInfo/Oem/${oemId}/models`;
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get models');
    return await response.json();
};

export const requestPatchVehicle = async (vehicleId, isTrimLevelFilteringEnabled) => {
    const url = 'api/VehicleInfo/PatchVehicle';
    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            vehicleId: vehicleId,
            isTrimLevelFilteringEnabled: isTrimLevelFilteringEnabled,
        }),
    });

    if (!response.ok) throw new Error('Could not update vehicle record');
};

export const requestFindVehicleVisualModel = async (modelName, years = []) => {
    let url = `api/VehicleInfo/FindVehicleVisualModel?modelName=${modelName}`;

    //expect the list of years are valid numbers
    const yearIds = years.filter(element => typeof element === 'number');

    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            modelName,
            years: yearIds,
        }),
    });

    if (!response.ok) throw new Error(`Failed to find vehicle visual models for ${modelName}`);
    return await response.json();
};

export const requestFindVehicleOemModel = async oemId => {
    const url = `api/VehicleInfo/GetOemModels/${oemId}`;
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error(`Failed to find vehicle OEM models for OemId ${oemId}`);

    return await response.json();
};

//assigns a given visual model id to specified vehicle
export const requestAssignVehicleVisualModel = async (visualModelId, vehicleId) => {
    let url = 'api/VehicleInfo/AssignVehicleVisualModel';

    let response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            visualModelId,
            vehicleId,
        }),
    });

    if (!response.ok) throw new Error(`Failed to set vehicle visual model ${visualModelId} for vehicle ${vehicleId}`);

    let result = await response.json();

    return result;
};

//unassign the visual model from vehicle
export const requestUnassignVehicleVisualModel = async vehicleId => {
    let url = 'api/VehicleInfo/UnassignVehicleVisualModel';
    let response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            vehicleId,
        }),
    });

    if (!response.ok) throw new Error(`Failed to unassign vehicle visual model from vehicle ${vehicleId}`);

    let result = await response.json();

    if (!result.succeeded) throw new Error(`Failed to unassign vehicle visual model from vehicle ${vehicleId}`);

    return result.succeeded;
};

export const requestUpdateVehicleOemModel = async (vehicleId, oemModelId, oemId) => {
    let url = 'api/VehicleInfo/UpdateVehiclesMapWithOemModel';
    let vehicleIds = [vehicleId];
    let response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            vehicleIds,
            oemModelId,
            oemId,
        }),
    });

    if (!response.ok) throw new Error(`Failed to unassign vehicle oemModel from vehicle ${vehicleId}`);

    return response.ok;
};

/**
 * To bulk update vehicle
 * @summary If a param is undefined then that corresponding props of selected vehicles are ignored, i.e. no update
 */
export const requestBulkUpdateVehicles = async (
    vehicleIds,
    isTrimLevelFilteringEnabled = undefined,
    visualModelId = undefined,
    oemModelId = undefined,
    oemId = undefined,
    isDisplayingTagsEnabled = undefined
) => {
    const url = 'api/VehicleInfo/BulkUpdate';
    const body = { vehicleIds: vehicleIds };
    if (isTrimLevelFilteringEnabled !== undefined) {
        body.isTrimLevelFilteringEnabled = isTrimLevelFilteringEnabled;
    }
    if (visualModelId !== undefined) {
        body.visualModelId = visualModelId == null ? 0 : visualModelId;
    }
    if (oemModelId !== undefined) {
        body.oemModelId = oemModelId == null ? 0 : oemModelId;
        body.oemId = oemId ?? null;
    }
    if (isDisplayingTagsEnabled !== undefined) {
        body.isDisplayingTagsEnabled = isDisplayingTagsEnabled;
    }
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error(`Failed to bulk update vehicle ${JSON.stringify(body)}`);
    }
    return await response.json();
};

/**
 * To update displaying tags bit of a vehicle
 */
export const requestUpdateVehicleDisplayingTags = async (vehicleId, isDisplayingTagsEnabled) => {
    const url = `api/VehicleInfo/${vehicleId}/IsDisplayingTagsEnabled`;
    const body = JSON.stringify({ vehicleId: vehicleId, isDisplayingTagsEnabled: isDisplayingTagsEnabled });

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    if (!response.ok) {
        throw new Error(`Failed to update vehicle displaying tags bit ${body}`);
    }
    return await response.json();
};

export const requestGetOemRefreshSchedules = async () => {
    const url = 'api/VehicleInfo/OemRefreshSchedules';
    const response = await fetchWithAuthHeader(url);
    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestSkipNextRefreshById = async id => {
    const url = `api/VehicleInfo/OemRefreshSchedules/${id}/SkipNextRefresh`;

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestUpdateRefreshScheduleById = async (id, isActive) => {
    const url = 'api/VehicleInfo/UpdateRefreshSchedules';
    const body = JSON.stringify({ id, isActive });

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};
