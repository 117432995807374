import React from 'react';
import { LargeCheckbox } from 'oemiq-common';

const ProcedureFilterCheckboxes = ({ filterCheckboxes, setFilterCheckboxes }) => {
    const { filterNoGroups, filterNoType, filterRefreshedPending, filterOnlyHotSheet } = filterCheckboxes;

    return (
        <>
            <div className="d-flex align-items-center my-2">
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-groups"
                            label="Filter no group"
                            className="large-checkbox-check mb-0"
                            checked={filterNoGroups}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterNoGroups: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-no-type"
                            label="Filter no type"
                            className="large-checkbox-check mb-0"
                            checked={filterNoType}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterNoType: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-refreshed-pending"
                            label="Filter refreshed pending"
                            className="large-checkbox-check mb-0"
                            checked={filterRefreshedPending}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterRefreshedPending: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center me-4">
                    <div style={{ height: '21px' }}>
                        <LargeCheckbox
                            id="filter-only-hotsheet"
                            label="Filter HotSheet group only"
                            className="large-checkbox-check mb-0"
                            checked={filterOnlyHotSheet}
                            onChange={e =>
                                setFilterCheckboxes({
                                    ...filterCheckboxes,
                                    filterOnlyHotSheet: e.target.checked,
                                })
                            }
                            readOnly={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProcedureFilterCheckboxes;
