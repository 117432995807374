import { OemMetadata } from './MetaModel/BuildOemMetadata';
import { OemEngineService } from './MetaModel/types';
import { OemId } from 'helpers/OemId';
import { NissanMappingEngineService, NISSAN_PROCEDURE_METADATA } from './MetaModel/NissanProcedureMetadata';
import { ToyotaMappingEngineService, TOYOTA_PROCEDURE_METADATA } from './MetaModel/ToyotaProcedureMetadata';
import { FordMappingEngineService, FORD_PROCEDURE_METADATA } from './MetaModel/FordProcedureMetadata';
import { GMMappingEngineService, GM_PROCEDURE_METADATA } from './MetaModel/GMProcedureMetadata';
import { HondaMappingEngineService, HONDA_PROCEDURE_METADATA } from './MetaModel/HondaProcedureMetadata';
import { OemIqFordMappingEngineService, OEMIQ_FORD_PROCEDURE_METADATA } from './MetaModel/OemIqFordProcedureMetadata';
import { STELLANTIS_PROCEDURE_METADATA, StellantisMappingEngineService } from './MetaModel/StellantisProcedureMatadata';
import { VWMappingEngineService, VW_PROCEDURE_METADATA } from './MetaModel/VWProcedureMetadata';
import { SUBARU_PROCEDURE_METADATA, SubaruMappingEngineService } from './MetaModel/SubaruProcedureMatadata';
import {
    FordProceduresApiService,
    OemIqFordProceduresApiService,
    ToyotaProceduresApiService,
    NissanProceduresApiService,
    GMProceduresApiService,
    HondaProceduresApiService,
    StellantisProceduresApiService,
    VWProceduresApiService,
    SubaruProceduresApiService,
    OemProceduresApiService,
} from 'api/RepairProcedures/OemProceduresApiService';

const oemToConfiguration = {
    0: { oemMetadata: null, oemEngineService: null, oemService: null },
    [OemId.Ford]: {
        oemMetadata: FORD_PROCEDURE_METADATA,
        oemEngineService: FordMappingEngineService,
        oemService: FordProceduresApiService,
    },
    [OemId.OEMiQ]: {
        oemMetadata: OEMIQ_FORD_PROCEDURE_METADATA,
        oemEngineService: OemIqFordMappingEngineService,
        oemService: OemIqFordProceduresApiService,
    },
    [OemId.Toyota]: {
        oemMetadata: TOYOTA_PROCEDURE_METADATA,
        oemEngineService: ToyotaMappingEngineService,
        oemService: ToyotaProceduresApiService,
    },
    [OemId.Nissan]: {
        oemMetadata: NISSAN_PROCEDURE_METADATA,
        oemEngineService: NissanMappingEngineService,
        oemService: NissanProceduresApiService,
    },
    [OemId.GMC]: {
        oemMetadata: GM_PROCEDURE_METADATA,
        oemEngineService: GMMappingEngineService,
        oemService: GMProceduresApiService,
    },
    [OemId.Honda]: {
        oemMetadata: HONDA_PROCEDURE_METADATA,
        oemEngineService: HondaMappingEngineService,
        oemService: HondaProceduresApiService,
    },
    [OemId.Chrysler]: {
        oemMetadata: STELLANTIS_PROCEDURE_METADATA,
        oemEngineService: StellantisMappingEngineService,
        oemService: StellantisProceduresApiService,
    },
    [OemId.Volkswagen]: {
        oemMetadata: VW_PROCEDURE_METADATA,
        oemEngineService: VWMappingEngineService,
        oemService: VWProceduresApiService,
    },
    [OemId.Subaru]: {
        oemMetadata: SUBARU_PROCEDURE_METADATA,
        oemEngineService: SubaruMappingEngineService,
        oemService: SubaruProceduresApiService,
    },
};

const useOemService = (
    oemId: number | string
): {
    oemMetadata: OemMetadata;
    oemEngineService: OemEngineService<unknown>;
    oemService: OemProceduresApiService<unknown>;
} => {
    const oemIdAsNumber = typeof oemId === 'number' ? oemId : parseInt(oemId, 10);
    if (!oemToConfiguration[oemIdAsNumber]) return oemToConfiguration[0];
    return oemToConfiguration[oemIdAsNumber];
};

export default useOemService;
