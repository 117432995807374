import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { LoadingContext } from 'components/Layout';
import { requestVehicleByOem, requestLastVehiclePublishHistory } from 'api/vehicleInfo';
import { getVehicleStats } from 'api/RepairProcedureApi';
import { requestMappingStatistics, requestBookTaggerStats, requestBooksByVehicle } from 'api/RepairProcedureApi';

const getBooksForVehicle = async vehicle => {
    const books = await requestBooksByVehicle(
        vehicle.year.yearId,
        vehicle.oem.oemId,
        vehicle.model.modelId,
        vehicle.isTrimLevelFilteringEnabled ? vehicle.trim.trimId : null
    );

    return books.map(b => {
        return {
            ...b,
            id: `${b.bookId}${vehicle.vehicleId}`,
            vehicleRefId: vehicle.vehicleId,
            isVehicle: false,
            isLoaded: false,
            label: `${b.bookName}`,
        };
    });
};

const getVehicles = async (oemId, modelId) => {
    const vehicles = await requestVehicleByOem(oemId, modelId);

    return vehicles
        .map(v => {
            return {
                ...v,
                id: `${v.vehicleId}${modelId}`,
                isVehicle: true,
                isLoaded: false,
                published: v.isActive,
                label: `${v.year.yearValue} ${v.model.modelName} - ${v.trim.trimName || 'Base'}`,
                datePublished: {
                    vehicle: v,
                },
                trim: { ...v.trim, trimName: v.trim.trimName || 'Base' },
            };
        })
        .sort(function (a, b) {
            return b.year.yearId - a.year.yearId || a.trim.trimName.localeCompare(b.trim.trimName);
        });
};

const useVehicles = (oemId, modelId, showBooks, notifications) => {
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const [vehicles, setVehicles] = useState([]);
    const [books, setBooks] = useState([]);
    const [data, setData] = useState([]);
    const vehiclesStats = useRef([]);

    const loadVehicleStats = useCallback(async vehicle => {
        let vehicleStats = vehiclesStats.current.find(
            v =>
                v.oemId === vehicle.oem.oemId && v.yearId === vehicle.year.yearId && v.modelId === vehicle.model.modelId
        );
        if (!vehicleStats) {
            vehicleStats = await getVehicleStats(vehicle.oem.oemId, vehicle.year.yearId, vehicle.model.modelId);
            vehiclesStats.current.push({
                ...vehicleStats,
                oemId: vehicle.oem.oemId,
                yearId: vehicle.year.yearId,
                modelId: vehicle.model.modelId,
            });
        }

        setData(currentVehicles => {
            return currentVehicles.map(v => {
                if (
                    v.oem.oemId === vehicle.oem.oemId &&
                    v.year.yearId === vehicle.year.yearId &&
                    v.model.modelId === vehicle.model.modelId
                ) {
                    return {
                        ...v,
                        isLoaded: true,
                        mappingStatus: vehicleStats.mappingStats,
                        totalTags: vehicleStats.totalTags,
                        isCompleted: vehicleStats.isCompleted,
                    };
                }
                return v;
            });
        });
    }, []);

    const loadBookStats = useCallback(async bookId => {
        const [bookStats, tagStats] = await Promise.all([
            requestMappingStatistics(bookId),
            requestBookTaggerStats(bookId),
        ]);

        setData(current => {
            current.forEach((b, i) => {
                if (b.bookId === bookId) {
                    current[i] = {
                        ...b,
                        isLoaded: true,
                        mappingStatus: `${bookStats.proceduresCount.completed}/${bookStats.proceduresCount.total} (${(
                            (bookStats.proceduresCount.completed / bookStats.proceduresCount.total) *
                            100
                        ).toFixed(2)}%)`,
                        totalTags: tagStats.totalTagsCount,
                        isCompleted: bookStats.proceduresCount.completed === bookStats.proceduresCount.total,
                    };
                }
            });

            current.forEach((v, i) => {
                if (v.isVehicle) {
                    const booksForVehicle = current.filter(d => !d.isVehicle && d.vehicleRefId === v.vehicleId);
                    current[i] = {
                        ...current[i],
                        isLoaded: booksForVehicle.every(b => b.isLoaded),
                        isCompleted: booksForVehicle.length > 0 && booksForVehicle.every(b => b.isCompleted),
                    };
                }
            });

            return [...current];
        });
    }, []);

    useEffect(() => {
        (async () => {
            try {
                incrementLoading();
                const data = await getVehicles(oemId, modelId);
                setVehicles(data);
                setData(data);
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                decrementLoading();
            }
        })();
    }, [decrementLoading, incrementLoading, modelId, notifications, oemId]);

    useEffect(() => {
        if (!showBooks) return;

        let isMounted = true;
        (async () => {
            try {
                if (vehicles) {
                    let books = [];
                    for (let i = 0; i < vehicles.length && isMounted; i++) {
                        const vehicle = vehicles[i];
                        const booksForVehicle = await getBooksForVehicle(vehicle);

                        setData(currentVehicles => {
                            const vehicleToEditIndex = currentVehicles.findIndex(
                                v => v.vehicleId === vehicle.vehicleId
                            );
                            currentVehicles.splice(vehicleToEditIndex + 1, 0, ...booksForVehicle);
                            return [...currentVehicles];
                        });

                        books = books.concat(booksForVehicle.map(b => b.bookId));
                    }

                    setBooks([...new Set(books)]);
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [notifications, vehicles, showBooks]);

    useEffect(() => {
        if (!showBooks) return;

        let isMounted = true;
        (async () => {
            try {
                if (books) {
                    for (let i = 0; i < books.length && isMounted; i++) {
                        const book = books[i];
                        await loadBookStats(book);
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [books, loadBookStats, notifications, showBooks]);

    useEffect(() => {
        if (showBooks) return;

        let isMounted = true;
        (async () => {
            try {
                if (vehicles) {
                    for (let i = 0; i < vehicles.length && isMounted; i++) {
                        const vehicle = vehicles[i];
                        await loadVehicleStats(vehicle);
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [loadVehicleStats, notifications, showBooks, vehicles]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                if (vehicles) {
                    for (let i = 0; i < vehicles.length && isMounted; i++) {
                        const vehicle = vehicles[i];
                        const vehicleHistory = await requestLastVehiclePublishHistory(vehicle.vehicleId);

                        isMounted &&
                            setData(currentVehicles => {
                                const vehicleToEditIndex = currentVehicles.findIndex(
                                    v => v.vehicleId === vehicle.vehicleId
                                );
                                currentVehicles[vehicleToEditIndex] = {
                                    ...currentVehicles[vehicleToEditIndex],
                                    datePublished: {
                                        ...currentVehicles[vehicleToEditIndex].datePublished,
                                        vehicleHistory: vehicleHistory,
                                    },
                                };

                                return [...currentVehicles];
                            });
                    }
                }
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [notifications, vehicles]);

    return {
        data,
        setData,
    };
};

export default useVehicles;
