import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useOemService from 'hooks/OemModels/useOemService';
import RulableValuePicker from './RulableValuePicker';
import {
    requestCreateNewMappingRule,
    requestRuleableValuesAnalytics,
    formatParamsForCreateRule,
    requestColumnValuesAnalytics,
} from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { ColumnChangeAction, ColumnChangeCommand, Operator } from './RulableValuePicker/types';
import { cyrb53 } from 'components/Shared/Helpers';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import PreviewProcedures from './PreviewProceduresModal/PreviewProcedures';
import { getUrlFilter } from 'components/Shared/TableFilters/tableFilterHelpers';
import { translateMetadataFilters } from './PreviewProceduresModal/PreviewProcedures';
import CreateRule from './CreateRule/CreateRule';
import { match } from 'ts-pattern';

interface Filters {
    propertyName: string;
    rulePropertyName: string;
    operator: Operator;
    value: string;
}

const DEFAULT_OPERATOR = Operator.Equal;

const generateFiltersHash = (filters: Filters[]): string =>
    cyrb53(filters.reduce((pv, cv) => `${pv}${`${cv.operator}${cv.propertyName}${cv.value}`}`, '')).toString();

const RulesCreatorTool = () => {
    const { notifications } = useContext(NotificationsContext);
    const { oemId } = useParams();
    const { oemMetadata } = useOemService(oemId);
    const [columnsData, setColumnsData] = useState(
        oemMetadata.ruleable.map(r => ({
            id: r.id.toUpperCase(),
            label: r.displayName,
            rulePropertyName: r.rulePropertyName,
            operator: DEFAULT_OPERATOR,
            hasActiveFilter: false,
            value: '',
        }))
    );
    const [columnsValues, setColumnsValues] = useState(
        oemMetadata.ruleable.map(r => ({
            id: r.id.toUpperCase(),
            values: [],
            count: 0,
            isLoading: false,
            isLoadingAll: false,
            isLoadAll: false,
        }))
    );
    const [filters, setFilters] = useState<Filters[]>([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const requestFiltersHash = useRef('');

    const [urlFilters, setUrlFilters] = useState([]);

    const setColumnsValuesProperty = useCallback((columnId: string, properties: Record<string, unknown>) => {
        setColumnsValues(columnsValues =>
            columnsValues.map(cv => (cv.id === columnId ? { ...cv, ...properties } : cv))
        );
    }, []);

    const setPickerLoading = useCallback(isLoading => {
        setColumnsValues(columnsValues => columnsValues.map(cd => ({ ...cd, isLoading })));
    }, []);

    const onColumnDataChange = useCallback((columnChangeCommand: ColumnChangeCommand) => {
        setColumnsData(columnsData =>
            columnsData.map(cd => {
                if (cd.id !== columnChangeCommand.id) return cd;

                return match(columnChangeCommand.action)
                    .with(ColumnChangeAction.SetOperator, () => ({
                        ...cd,
                        hasActiveFilter: false,
                        operator: columnChangeCommand.operator,
                        value: null,
                    }))
                    .with(ColumnChangeAction.SetContainsText, () => ({
                        ...cd,
                        hasActiveFilter: true,
                        value: columnChangeCommand.value,
                    }))
                    .with(ColumnChangeAction.SetValue, () =>
                        // - when user clicks on the same value we need to remove filter
                        // - but when on the different value we need to add equal filter
                        ({
                            ...cd,
                            hasActiveFilter: cd.value !== columnChangeCommand.value,
                            operator: DEFAULT_OPERATOR,
                            value: cd.value !== columnChangeCommand.value ? columnChangeCommand.value : null,
                        })
                    )
                    .exhaustive();
            })
        );
    }, []);

    useEffect(() => {
        setFilters(
            columnsData
                .filter(cd => cd.hasActiveFilter)
                .map(cd => ({
                    propertyName: cd.id,
                    rulePropertyName: cd.rulePropertyName,
                    operator: cd.operator,
                    value: cd.value,
                }))
        );
    }, [columnsData]);

    const updateColumnsDataFromResponse = useCallback(response => {
        setColumnsValues(columnsValues =>
            columnsValues.map(cv => {
                const column = response.find(rd => rd.propertyName.toUpperCase() === cv.id);
                return {
                    ...cv,
                    ...(column ? { values: column?.values, count: column?.count } : {}),
                };
            })
        );
    }, []);

    const onLoadAllClick = useCallback(
        (columnId: string) => {
            setColumnsValuesProperty(columnId, { isLoadAll: true });
        },
        [setColumnsValuesProperty]
    );

    useEffect(() => {
        const translated = filters.map(r => translateMetadataFilters(r));
        const filterUrls = translated.map(r => ({
            id: r.id,
            urlFilter: getUrlFilter({ id: r.id, operator: r.operator, property: null }, `'${r.value}'`),
        }));
        setUrlFilters(filterUrls);
    }, [filters]);

    const onLoadAllColumnValues = useCallback(
        async (columnId: string) => {
            setColumnsValuesProperty(columnId, { isLoading: true });
            const filtersWithoutColumn = filters.filter(item => item.propertyName !== columnId);
            const response = await requestColumnValuesAnalytics(oemId, filtersWithoutColumn, columnId);
            updateColumnsDataFromResponse([response]);
            setColumnsValuesProperty(columnId, { isLoading: false });
        },
        [filters, oemId, setColumnsValuesProperty, updateColumnsDataFromResponse]
    );

    const createRule = useCallback(async () => {
        try {
            await requestCreateNewMappingRule(oemId, formatParamsForCreateRule(filters, selectedGroups, selectedType));
            notifications.pushSuccess('New mapping rule created');
        } catch (error) {
            notifications.pushExceptionDanger(error);
        }
    }, [filters, notifications, oemId, selectedGroups, selectedType]);

    const resetView = useCallback(() => {
        setColumnsData(cData =>
            cData.map(cd => ({
                ...cd,
                operator: DEFAULT_OPERATOR,
                hasActiveFilter: false,
                containsText: null,
                value: null,
            }))
        );
        setColumnsValues(columnsValues => columnsValues.map(cv => ({ ...cv, isLoadAll: false })));
    }, []);

    const requestColumnsValues = useCallback(async () => {
        try {
            setPickerLoading(true);
            const response = await requestRuleableValuesAnalytics(oemId, filters);
            requestFiltersHash.current = generateFiltersHash(filters);
            updateColumnsDataFromResponse(response);
        } catch (error) {
            notifications.pushExceptionDanger(error);
        } finally {
            setPickerLoading(false);
        }
    }, [filters, notifications, oemId, setPickerLoading, updateColumnsDataFromResponse]);

    useEffect(() => {
        const filtersHash = generateFiltersHash(filters);
        if (requestFiltersHash.current !== filtersHash) {
            requestFiltersHash.current = filtersHash;
            requestColumnsValues();
        }
    }, [filters, requestColumnsValues]);

    return (
        <>
            <div className="pt-3 pe-3 ps-3 d-flex flex-column">
                <div className="d-flex mb-3">
                    <h2 className="flex-grow-1">Rules Creator</h2>
                    <CreateRule
                        selectedGroups={selectedGroups}
                        selectedType={selectedType}
                        setSelectedGroups={setSelectedGroups}
                        setSelectedType={setSelectedType}
                        onCreateRule={createRule}
                        isCreateDisabled={filters.length < 1 || (selectedGroups.length < 1 && !selectedType)}
                    />
                    <PreviewProcedures urlFilters={urlFilters} oemMetadata={oemMetadata} oemId={parseInt(oemId)} />
                </div>
                <div className="ruleable-value-pickers-group d-flex flex-row">
                    <button
                        data-testid="clear-all-filters-button"
                        className="clear-filters-button btn btn-warning me-3"
                        style={{ maxHeight: '3rem' }}
                        onClick={resetView}>
                        X
                    </button>
                    {columnsData.map((cd, i) => (
                        <RulableValuePicker
                            key={cd.id}
                            columnData={cd}
                            columnValues={columnsValues.find(cv => cv.id === cd.id)}
                            onChange={onColumnDataChange}
                            onLoadAllClick={onLoadAllClick}
                            onLoadAll={onLoadAllColumnValues}
                            className={`flex-fill ${columnsData.length - i > 1 ? 'me-3' : ''}`}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default RulesCreatorTool;
