import OrganizationModal from './OrganizationModal/OrganizationModal';
import CustomerBreadCrumbs from '../CustomerBreadCrumbs';
import useOrganizations from './useOrganizations';
import { Table } from 'oemiq-common';

const Organizations = () => {
    const {
        tableColumnDetails,
        showModal,
        editOrgId,
        searchTerm,
        displayOrgs,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleToggle,
        setSearchTerm,
        setShowModal,
        handleRowClick,
        sortClickCallback,
    } = useOrganizations();

    return (
        <div>
            {showModal && (
                <OrganizationModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={editOrgId}
                    isOpen={showModal}
                    toggle={handleToggle}
                />
            )}
            <CustomerBreadCrumbs />
            <div className="row mt-3">
                <div className="col-2">
                    <input
                        id="org-search"
                        className="form form-control"
                        type="text"
                        placeholder="Search Organizations"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.currentTarget.value)}
                    />
                </div>
                {/* If user is customer support, hide create new org button */}
                {!userHasCustomerSupportRole && (
                    <div className="col">
                        <button
                            type="button"
                            id="create-new-org"
                            className="btn btn-sm btn-primary h-100"
                            onClick={() => setShowModal(!showModal)}>
                            Create New Organization
                        </button>
                    </div>
                )}
            </div>
            <div className="mt-3">
                <Table
                    tableId="org-table"
                    tableClassName="mt-3"
                    isTableRowsClickable={true}
                    columnDetails={tableColumnDetails}
                    data={displayOrgs}
                    sortClickCallback={sortClickCallback}
                    rowClickCallback={handleRowClick}
                />
            </div>
        </div>
    );
};

export default Organizations;
