import Breadcrumb from 'components/Navigation/items/Breadcrumb';
import { useParams, useLocation } from 'react-router-dom';
import { CUST_TOOLS, CUST_DICT } from 'components/locations/ManageCustomers/ManageCustomersConstants';

const titleCase = str => {
    return str
        .split(' ')
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(' ');
};

// top of page navigation for customer management
const ManageCustomersNavigation = () => {
    const { customerTool, organizationId, orgTool, locationId, locTool } = useParams();
    const { context } = useLocation(); // todo: pass in location info as the customized breadcrumbs do on the customer pages
    const orgName = context?.org?.organizationName;
    const locationName = context?.location?.locationName;

    const orgSelected = (organizationId && !orgName) || (orgName && !organizationId);
    const displayOrg = orgSelected
        ? orgName
            ? orgName
            : `${titleCase(CUST_TOOLS.orgTool.terminus)} ${organizationId}`
        : CUST_TOOLS.orgTool.name;

    const compSelected = orgSelected && ((locationId && !locationName) || (locationName && !locationId));
    const displayComp = compSelected
        ? locationName
            ? locationName
            : `${titleCase(CUST_TOOLS.compTool.terminus)} ${locationId}`
        : CUST_TOOLS.compTool.name;

    return (
        <ol className="breadcrumb">
            <Breadcrumb
                isShown={true}
                isCurrent={!customerTool}
                link={`/${CUST_TOOLS.baseTool.route}`}
                text={`${CUST_TOOLS.baseTool.name}`}
            />
            {customerTool && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={!orgSelected}
                    link={`${CUST_TOOLS.orgTool.route}`}
                    text={`${CUST_DICT[customerTool]}`}
                />
            )}
            {orgSelected && (
                <>
                    <Breadcrumb
                        isShown={true}
                        isCurrent={!orgTool}
                        link={`${CUST_TOOLS.orgTool.Path(organizationId)}`}
                        text={`${displayOrg}`}
                    />
                    <Breadcrumb
                        isCurrent={!locationId}
                        isShown={true}
                        link={`${CUST_TOOLS.orgTool.Path(organizationId)}`}
                        text={
                            compSelected
                                ? `${displayComp}`
                                : orgTool
                                ? `${titleCase(orgTool)}`
                                : `${CUST_TOOLS.compTool.name}`
                        }
                    />
                </>
            )}
            {compSelected && (
                <Breadcrumb
                    isShown={true}
                    isCurrent={true}
                    link={CUST_TOOLS.compUserTool.Path(organizationId, locationId)}
                    text={`${titleCase(locTool)}`}
                />
            )}
        </ol>
    );
};

export default ManageCustomersNavigation;
