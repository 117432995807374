import { useState, useEffect, useContext, useCallback, useLayoutEffect } from 'react';
import {
    requestGetTagsByProcedureIdApi,
    requestGetFlagsByProcedureIdApi,
    requestProcedureDetailsApi,
    loadProcedureHtml,
} from 'api/RepairProcedureApi';
import { useParams } from 'react-router-dom';
import useQuantityConditions from 'hooks/useQuantityConditions';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { escapeSpecialIdCharacters } from 'helpers/TaggerHelper';
import { partTypes } from 'helpers/PartTypes';

const getHighlightStyle = (startPath, endPath, colorHex, isHidden = false) => {
    if (!startPath || !endPath) return;

    let startNode, endNode;

    try {
        const startPathClean = escapeSpecialIdCharacters(startPath);
        const endPathClean = escapeSpecialIdCharacters(endPath);
        startNode = document.querySelector(startPathClean);
        endNode = document.querySelector(endPathClean);

        if (!startNode || !endNode) return;

        let range = document.createRange();
        range.setStartBefore(document.querySelector(startPathClean), 0);
        range.setEndAfter(document.querySelector(endPathClean), 0);

        const clientRect = range.getBoundingClientRect();
        const spacing = 3;

        let style = {
            display: isHidden ? 'none' : 'block',
            border: `4px dashed ${colorHex}80`,
            position: 'absolute',
            zIndex: 0,
            pointerEvents: 'none',
            left: `${clientRect.x + window.scrollX - spacing}px`,
            top: `${clientRect.y + window.scrollY - spacing}px`,
            width: `${clientRect.width + 2 * spacing}px`,
            height: `${clientRect.height + 2 * spacing}px`,
        };

        return style;
    } catch (err) {
        return;
    }
};

const highlightFlag = flag => {
    if (!flag || !flag.oneTimeUseFlagElement) return;

    let startPath = flag.oneTimeUseFlagElement.contentStart;
    let endPath = flag.oneTimeUseFlagElement.contentEnd;

    let style = getHighlightStyle(startPath, endPath, flag.colorHex, flag.isHidden);

    if (!style) return;

    return { id: flag.oneTimeUseFlagId, style: style };
};

const highlightTag = tag => {
    let element = null;
    if (tag?.oneTimeUseTagElements?.length) {
        element = tag.oneTimeUseTagElements[0];
    }
    if (element) {
        let startPath = element.contentStart;
        let endPath = element.contentEnd;

        let style = getHighlightStyle(startPath, endPath, tag.colorHex, tag.isHidden);

        return { id: tag.stagedOneTimeUseTagId, style: style };
    }
};

const scrollToContentStart = contentStart => {
    if (!contentStart) return;
    let region;
    try {
        region = document.querySelector(escapeSpecialIdCharacters(contentStart));
        if (!region) return;
        region.scrollIntoView({ block: 'center', behavior: 'smooth' });
    } catch (err) {
        return;
    }
};

const useTaggerTool = () => {
    const { procedureId } = useParams();
    const [procedure, setProcedure] = useState(null);
    const [tags, setTags] = useState([]);
    const [flags, setFlags] = useState([]);
    const [highlightStylesTags, setHighlightStylesTags] = useState([]);
    const [highlightStylesFlags, setHighlightStylesFlags] = useState([]);
    const [isAddingNewTag, setIsAddingNewTag] = useState(false);
    const { quantityConditions, setQuantityConditions } = useQuantityConditions();
    const [hideAll, setHideAll] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const [isScrollToContentEnabled, setIsScrollToContentEnabled] = useState(true);

    useEffect(() => {
        let isUnmounted = false;
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContentHeight(entry.contentBoxSize[0].blockSize);
            }
        });
        (async () => {
            try {
                incrementLoading();

                const results = await Promise.all([
                    requestGetTagsByProcedureIdApi(procedureId),
                    requestGetFlagsByProcedureIdApi(procedureId),
                    requestProcedureDetailsApi(procedureId, false),
                ]);
                const tags = results[0];
                let flags = results[1];
                const procedureWithDetails = results[2];
                const latestProcedureDetails = procedureWithDetails.procedureDetails.filter(pd => pd.isLatest);
                const procedureHtml = await loadProcedureHtml(procedureId, latestProcedureDetails, false);
                procedureWithDetails.procedureHtml = {
                    html: procedureHtml,
                };
                // get rid of flags that have been tagged
                const mappedFlagIds = new Set([...tags.map(t => t.oneTimeUseFlagId)].filter(id => id));
                flags = flags.filter(f => !mappedFlagIds.has(f.oneTimeUseFlagId));

                if (!isUnmounted) {
                    setProcedure(procedureWithDetails);
                    setTags(tags);
                    setFlags(flags);

                    const procedureHtmlElement = document.querySelector('#procedure-html');
                    if (procedureHtmlElement) {
                        resizeObserver.observe(procedureHtmlElement);
                    }
                }
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        })();

        return () => {
            isUnmounted = true;
            const procedureHtml = document.querySelector('#procedure-html');
            if (procedureHtml) resizeObserver.unobserve(procedureHtml);
        };
    }, [procedureId, showToast, incrementLoading, decrementLoading]);

    useEffect(() => {
        //sync the hide all button if you click through each button
        const allFlagsHidden = flags.length === 0 || flags.reduce((acc, f) => acc && !!f.isHidden, true);
        const allTagsHidden = tags.length === 0 || tags.reduce((acc, t) => acc && !!t.isHidden, true);
        if ((allFlagsHidden && allTagsHidden) !== hideAll) setHideAll(allTagsHidden && allFlagsHidden);
    }, [tags, flags, hideAll]);

    const calculateFlagsTagsStyle = useCallback(() => {
        const tagStyles = tags.map(tag => highlightTag(tag)).filter(h => h); //Filters out any issues from highlight tags
        const flagStyles = flags.map(flag => highlightFlag(flag)).filter(h => h); //Filters out any issues from highlight flags
        setHighlightStylesTags(tagStyles);
        setHighlightStylesFlags(flagStyles);
    }, [tags, flags]);

    useEffect(calculateFlagsTagsStyle, [calculateFlagsTagsStyle, contentHeight]);

    const onDocumentLoad = useCallback(
        event => {
            if (event.target.nodeName === 'IMG') {
                calculateFlagsTagsStyle();
            }
        },
        [calculateFlagsTagsStyle]
    );

    useLayoutEffect(() => {
        document.addEventListener('load', onDocumentLoad, true);

        return () => document.removeEventListener('load', onDocumentLoad);
    }, [onDocumentLoad]);

    const toggleFlag = useCallback((e, flagId) => {
        e.stopPropagation();
        setFlags(prev => prev.map(f => (f.oneTimeUseFlagId === flagId ? { ...f, isHidden: !f.isHidden } : f)));
    }, []);

    const toggleTag = useCallback((e, tagId) => {
        e.stopPropagation();
        setTags(prev => prev.map(t => (t.stagedOneTimeUseTagId === tagId ? { ...t, isHidden: !t.isHidden } : t)));
    }, []);

    const toggleAll = () => {
        const isHidden = !hideAll;
        setFlags(prev => prev.map(f => ({ ...f, isHidden })));
        setTags(prev => prev.map(t => ({ ...t, isHidden })));
        setHideAll(!hideAll);
    };

    const handleEnableScrollToContentChanged = () => {
        setIsScrollToContentEnabled(prev => !prev);
    };

    return {
        procedure,
        tags,
        setTags,
        flags,
        setFlags,
        highlightStylesTags,
        highlightStylesFlags,
        scrollToContentStart,
        isAddingNewTag,
        setIsAddingNewTag,
        quantityConditions,
        setQuantityConditions,
        partTypes,
        toggleFlag,
        toggleTag,
        hideAll,
        toggleAll,
        isScrollToContentEnabled,
        handleEnableScrollToContentChanged,
    };
};

export default useTaggerTool;
