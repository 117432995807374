import React, { useContext } from 'react';
import type { CellProps, ItemReturnEvent } from 'components/Shared/Table/types';
import { RefreshDashboardRowDataType, RefreshDashboardActionTypes } from '../types';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';

const ActionsCell: React.FC<CellProps> = ({ item, index, idKey, cellEventCallback }) => {
    const { isLoading, lastImportJobSummary: summary, oemRefreshSchedules } = item as RefreshDashboardRowDataType;
    const { hasAccess } = useContext(AccessControlContext);

    const buttonHandlerFactory = (actionId: string, id?: number | null | undefined): (() => void) => {
        return (): void => {
            const payload: ItemReturnEvent = {
                id: id?.toString() ?? (item[idKey] as string),
                item: item,
                index: index,
                actionId: actionId,
            };

            cellEventCallback(payload);
        };
    };

    if (isLoading) return <td></td>;

    const id = `import-manager-item-${item.oemId}-actions`;
    const refreshId = `${id}-refresh`;
    const detailsId = `${id}-details`;
    const historyId = `${id}-history`;

    return (
        <td className="px-0" style={{ verticalAlign: 'middle' }} id={id}>
            <div className="d-flex flex-row align-items-center justify-content-center py-2">
                {!summary ? (
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={buttonHandlerFactory(RefreshDashboardActionTypes.ReloadOemStatistics)}
                        title="Reload this OEM">
                        Reload
                    </button>
                ) : (
                    <>
                        {hasAccess('importManager.runRefresh') && (
                            <div className="d-flex flex-column flex-grow-1">
                                {oemRefreshSchedules.length > 0 ? (
                                    oemRefreshSchedules.map((schedule, index) => {
                                        const IS_TESTING_RECORD = schedule.oemId === 100;
                                        const refreshScheduleId = `${id}-${schedule.id}`;
                                        return (
                                            <div
                                                key={schedule.id}
                                                className={`d-flex justify-content-end py-4 ${
                                                    index === 0 ? 'border-0' : 'border-top'
                                                }`}>
                                                <button
                                                    id={refreshScheduleId}
                                                    key={schedule.id}
                                                    disabled={IS_TESTING_RECORD}
                                                    type="button"
                                                    className={`btn ${
                                                        summary.success === null ? 'btn-warning' : 'btn-primary'
                                                    } btn-sm mx-3`}
                                                    onClick={buttonHandlerFactory(
                                                        RefreshDashboardActionTypes.RunRefresh,
                                                        schedule.id
                                                    )}>
                                                    {IS_TESTING_RECORD ? 'Testing Record' : 'Run Refresh'}
                                                </button>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="d-flex justify-content-end py-4 border-0">
                                        <button
                                            id={refreshId}
                                            type="button"
                                            className="btn btn-secondary btn-sm mx-3"
                                            disabled={true}>
                                            Run Refresh
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="flex-grow-1">
                            <button
                                id={detailsId}
                                type="button"
                                className="btn btn-link mx-5"
                                onClick={buttonHandlerFactory(RefreshDashboardActionTypes.NavigateToDetailsPage)}>
                                Details
                            </button>
                            <button
                                id={historyId}
                                type="button"
                                className="btn btn-link mx-3"
                                onClick={buttonHandlerFactory(RefreshDashboardActionTypes.NavigateToHistoryPage)}>
                                History
                            </button>
                        </div>
                    </>
                )}
            </div>
        </td>
    );
};

export default ActionsCell;
