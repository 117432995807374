import React from 'react';
import useGetOemInfo from './useGetOemInfo';
import OemItem from './OemItem/OemItem';
import { OemId } from 'helpers/OemId';

interface OemsProps {
    mode;
    oemsConfiguration: OemId[] | undefined;
}

const Oems = ({ mode, oemsConfiguration }: OemsProps) => {
    const { oemInfo } = useGetOemInfo(mode);

    const oems = oemsConfiguration ? oemInfo.filter(oem => oemsConfiguration.includes(oem.oemId)) : oemInfo;

    return (
        <div className="container mt-5">
            <span className="font-weight-bold">Choose a Make</span>
            <div className="mt-5 d-flex flex-wrap">
                {Object.values(oems)
                    .sort((a, b) => {
                        if (b.isActive && !a.isActive) return 1;
                        else if (!b.isActive && a.isActive) return -1;
                        else if (a.oemName > b.oemName) return 1;
                        return -1;
                    })
                    .map(oem => (
                        <OemItem key={oem.oemId} disabled={!oem.isActive} oemInfo={oem} mode={mode} />
                    ))}
            </div>
        </div>
    );
};

export default Oems;
