import { useState, useEffect, useContext } from 'react';
import {
    requestFordBooks,
    requestOemIqFordBooks,
    requestNissanBooks,
    requestHondaBooks,
    requestStellantisBooks,
    requestVWBooks,
    requestSubaruBooks,
    requestBookByBookId,
    requestNissanProceduresByBookApi,
    requestFordProceduresByBookApi,
    requestOemIqProceduresByBookApi,
    requestGMCProceduresByBookApi,
    requestHondaProceduresByBookApi,
    requestStellantisProceduresByBookApi,
    requestVWProceduresByBookApi,
    requestToyotaBookByRpBookId,
    requestToyotaProceduresByToyotaBookApi,
    requestSubaruProceduresByBookApi,
} from 'api/RepairProcedureBookApi';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { OemId } from 'helpers/OemId';
import { match } from 'ts-pattern';

const getOEMiQRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const oemIqFordBooks = await requestOemIqFordBooks();
    const oemIqFordBook = oemIqFordBooks.find(b => b.rpBookId == bookId);
    if (oemIqFordBook) {
        const rawProcedures = await requestOemIqProceduresByBookApi(oemIqFordBook.fordBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getFordRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const fordBooks = await requestFordBooks();
    const fordBook = fordBooks.find(fb => fb.rpBookId === bookId);
    if (fordBook) {
        const rawProcedures = await requestFordProceduresByBookApi(fordBook.fordBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getToyotaRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const toyotaBooks = await requestToyotaBookByRpBookId(bookId);
    const toyotaBook = toyotaBooks.sort((a, b) => new Date(b.version).getTime() - new Date(a.version).getTime())[0];
    if (toyotaBook) {
        const rawProcedures = await requestToyotaProceduresByToyotaBookApi(bookId, toyotaBook.toyotaBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getNissanRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const nissanBooks = await requestNissanBooks();
    const nissanBookList = nissanBooks
        .filter(fb => fb.rpBookId === bookId)
        .sort((a, b) => new Date(b.version).getTime() - new Date(a.version).getTime());
    if (nissanBookList) {
        const rawProcedures = await requestNissanProceduresByBookApi(nissanBookList[0].nissanBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getGMCRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const rawProcedures = await requestGMCProceduresByBookApi(bookId, removed);
    return rawProcedures;
};

const getHondaRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const hondaBooks = await requestHondaBooks();
    const hondaBook = hondaBooks.filter(b => b.rpBookId === bookId).sort((a, b) => (a.version > b.version ? -1 : 1))[0];
    if (hondaBook) {
        const rawProcedures = await requestHondaProceduresByBookApi(hondaBook.hondaBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getStellantisRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const stellantisBooks = await requestStellantisBooks();
    const stellantisBook = stellantisBooks
        .filter(b => b.rpBookId === bookId)
        .sort((a, b) => (a.version > b.version ? -1 : 1))[0];
    // Check if a book is missing in case we are still in the process of importing data on the backend
    if (stellantisBook) {
        const rawProcedures = await requestStellantisProceduresByBookApi(stellantisBook.stellantisBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getVWRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const vwBooks = await requestVWBooks({ filter: `rpBookId eq ${bookId}` });
    // take the latest one (alternative solution: set some flag in the api call above (not implemented yet))
    const vwBook = vwBooks.sort((a, b) => (a.version > b.version ? -1 : 1))[0];
    if (vwBook) {
        const rawProcedures = await requestVWProceduresByBookApi(vwBook.vwBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const getSubaruRawProceduresForRpBook = async (bookId: number, removed: boolean) => {
    const subaruBooks = await requestSubaruBooks({ filter: `rpBookId eq ${bookId}` });
    const subaruBook = subaruBooks
        .filter(b => b.rpBookId === bookId)
        .sort((a, b) => (a.version > b.version ? -1 : 1))[0];
    if (subaruBook) {
        const rawProcedures = await requestSubaruProceduresByBookApi(subaruBook.subaruBookId, removed);
        return rawProcedures;
    }

    return [{}];
};

const isOemIdOrTestBookMatched = (oemIds: Array<OemId>, oemId: OemId, book) => {
    return oemIds.find(id => id === oemId || (id === book.testBookCopiedFromOemId && oemId == OemId.OEMiQ));
};

const getOemRawProceduresByOemId = async (oemId: number, bookId: number, removed: boolean) => {
    const book = await requestBookByBookId(bookId);
    const oemRawProcedures = match(oemId)
        .with(OemId.OEMiQ, () => getOEMiQRawProceduresForRpBook(bookId, removed))
        .with(OemId.Ford, () => getFordRawProceduresForRpBook(bookId, removed))
        .when(
            () => isOemIdOrTestBookMatched([OemId.Toyota, OemId.Lexus], oemId, book),
            () => getToyotaRawProceduresForRpBook(bookId, removed)
        )
        .when(
            () => isOemIdOrTestBookMatched([OemId.Nissan, OemId.Infiniti], oemId, book),
            () => getNissanRawProceduresForRpBook(bookId, removed)
        )
        .when(
            () => isOemIdOrTestBookMatched([OemId.GMC], oemId, book),
            () => getGMCRawProceduresForRpBook(bookId, removed)
        )
        .when(
            () => isOemIdOrTestBookMatched([OemId.Honda, OemId.Acura], oemId, book),
            () => getHondaRawProceduresForRpBook(bookId, removed)
        )
        .when(
            () =>
                isOemIdOrTestBookMatched(
                    [OemId.Chrysler, OemId.RAM, OemId.Fiat, OemId.Dodge, OemId.Jeep, OemId.AlfaRomeo],
                    oemId,
                    book
                ),
            () => getStellantisRawProceduresForRpBook(bookId, removed)
        )
        .when(
            () => isOemIdOrTestBookMatched([OemId.Audi, OemId.Volkswagen], oemId, book),
            () => getVWRawProceduresForRpBook(bookId, removed)
        )
        .when(
            () => isOemIdOrTestBookMatched([OemId.Subaru], oemId, book),
            () => getSubaruRawProceduresForRpBook(bookId, removed)
        )
        .otherwise(() => [{}]);

    return oemRawProcedures;
};

const useOemRawProcedure = (oemId, bookId, removed = false) => {
    const [oemRawProcedures, setOemRawProcedures] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    useEffect(() => {
        const getOemRawProcedures = async () => {
            try {
                incrementLoading();
                setOemRawProcedures(await getOemRawProceduresByOemId(parseInt(oemId), parseInt(bookId), removed));
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        getOemRawProcedures();
    }, [oemId, bookId, showToast, incrementLoading, decrementLoading, removed]);

    return { oemRawProcedures };
};

export default useOemRawProcedure;
