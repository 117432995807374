import { buildProcedureProperty } from './ProcedureProperty';
import { COMMON_FILTERS, COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const RULABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'system',
        displayName: 'System',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'system',
    }),
    buildProcedureProperty({
        name: 'group',
        displayName: 'Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'group',
    }),
    buildProcedureProperty({
        name: 'section',
        displayName: 'Section',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'section',
    }),
    buildProcedureProperty({
        name: 'procTitle',
        displayName: 'Proc Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procTitle',
    }),
    buildProcedureProperty({
        name: 'sectionTitle',
        displayName: 'Section Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sectionTitle',
    }),
    buildProcedureProperty({
        name: 'subSectionTitle',
        displayName: 'SubSection Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'subSectionTitle',
    }),
];

export const FORD_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 1,
    oemName: 'Ford',
    properties: [
        ...COMMON_PROPERTIES,
        ...RULABLE_PROPERTIES,
        buildProcedureProperty({
            name: 'fordProcedureId',
            displayName: 'Ford Procedure Id',
            type: PROPERTY_TYPE.number,
        }),
        buildProcedureProperty({ name: 'oemProcedureId', displayName: 'Oem Procedure Id', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'variantId', displayName: 'Variant Id', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'bookCode', displayName: 'Book Code', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'market', displayName: 'Market', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'language', displayName: 'Language', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'year', displayName: 'Year', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'modelName', displayName: 'Model Name', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'procId', displayName: 'Proc Id', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'revisionDate', displayName: 'Revision Date', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'type', displayName: 'Type', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'procSMGLId', displayName: 'Proc SMGL Id', type: PROPERTY_TYPE.string }),
    ],
    ruleKey: 'fordMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type FordMappingRule = OemMappingRuleBase & {
    fordMappingRuleId: number;
};

class _FordMappingEngineService implements OemEngineService<FordMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = FORD_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: FordMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: FordMappingRule): Promise<FordMappingRule> => {
        const newRule = { ...rule };
        const fordMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.fordMappingRuleId = fordMappingRuleId;

        return newRule;
    };
}

export const FordMappingEngineService = new _FordMappingEngineService();
