import Confirmation from 'components/Shared/Confirmation/Confirmation';
import Table from 'components/Shared/Table/Table';
import React, { useMemo } from 'react';
import useImportManagerDashboard from './useImportManagerDashboard';
import EditScheduleModal from './EditScheduleModal/EditScheduleModal';
import ImportManagerDashboardContext, { ImportManagerDashboardContextType } from './importManagerDashboardContext';

const ImportManagerDashboard: React.FC = () => {
    const { headers, data, tableHandlers, confirmationRef, selectedSchedule, modalHandlers, importJobDownloadTypes } =
        useImportManagerDashboard();

    const contextValue = useMemo<ImportManagerDashboardContextType>(
        () => ({ importJobDownloadTypes }),
        [importJobDownloadTypes]
    );

    return (
        <ImportManagerDashboardContext.Provider value={contextValue}>
            <Table
                headers={headers}
                data={data}
                idKey="oemId"
                cellEventCallbacks={tableHandlers}
                tableHtmlId="import-manager-dashbaord"
            />
            <Confirmation ref={confirmationRef} />
            <EditScheduleModal
                data={selectedSchedule}
                onToggle={modalHandlers.onToggle}
                onPause={modalHandlers.onPause}
                onSave={modalHandlers.onSave}
            />
        </ImportManagerDashboardContext.Provider>
    );
};

export default ImportManagerDashboard;
