import { buildProcedureProperty } from './ProcedureProperty';
import { COMMON_FILTERS, COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const RULEABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'level1',
        displayName: 'Level 1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'level1',
    }),
    buildProcedureProperty({
        name: 'level2',
        displayName: 'Level 2',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'level2',
    }),
    buildProcedureProperty({
        name: 'level3',
        displayName: 'Level 3',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'level3',
    }),
    buildProcedureProperty({
        name: 'level4',
        displayName: 'Level 4',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'level4',
    }),
    buildProcedureProperty({
        name: 'bookType',
        displayName: 'Book type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'bookType',
    }),
    buildProcedureProperty({
        name: 'procedureTitle',
        displayName: 'Procedure title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureTitle',
    }),
];

const NON_RULEABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'subaruProcedureId',
        displayName: 'Subaru Procedure Id',
        type: PROPERTY_TYPE.number,
    }),
    buildProcedureProperty({ name: 'oemProcedureId', displayName: 'Oem Procedure Id', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'version', displayName: 'Version', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'htmlFileName', displayName: 'Html File Name', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'htmlSelector', displayName: 'Html Selector', type: PROPERTY_TYPE.string }),
];

export const SUBARU_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 23,
    oemName: 'Subaru',
    properties: [...COMMON_PROPERTIES, ...RULEABLE_PROPERTIES, ...NON_RULEABLE_PROPERTIES],
    ruleKey: 'subaruMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type SubaruMappingRule = OemMappingRuleBase & {
    subaruMappingRuleId: number;
};

class _SubaruMappingEngineService implements OemEngineService<SubaruMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = SUBARU_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: SubaruMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: SubaruMappingRule): Promise<SubaruMappingRule> => {
        const newRule = { ...rule };
        const subaruMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.subaruMappingRuleId = subaruMappingRuleId;

        return newRule;
    };
}

export const SubaruMappingEngineService = new _SubaruMappingEngineService();
