import { fetchWithAuthHeader } from './AuthUtils';

/**
 * Sends HTTP GET REST request with OData parameters
 *
 * @param {string} url - API endpoint URL without OData parameters
 * @param {object} [oDataParams] - object contains OData parameters (), every parameter value will be encoded using encodeURIComponent function
 * @param {string} [oDataParams.select] - OData $select
 * @param {string} [oDataParams.expand] - OData $expand
 * @param {string} [oDataParams.filter] - OData $filter
 * @param {string} [oDataParams.orderBy] - OData $orderBy
 * @param {number} [oDataParams.top] - OData $top
 * @param {number} [oDataParams.skip] - OData $skip
 * @param {boolean} [oDataParams.count] - OData $count
 * @param {string} requestName - endpoint description for error reporting and logging
 */
export async function RequestRestGetWithOdataParams(url, oDataParams, requestName = null) {
    const params = Object.entries(oDataParams)
        .filter(([, val]) => val !== undefined && val !== null && val !== '')
        .map(([key, val]) => `$${key}=${val}`)
        .join('&');

    const urlWithParams = url.includes('?') ? `${url}&${params}` : `${url}?${params}`;

    const response = await fetchWithAuthHeader(urlWithParams, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) throw new Error(`Failed to ${requestName ? requestName : urlWithParams}`);

    return await response.json();
}
