export const getValidationCondition = filter => {
    return filter.property && filter.operator && filter.term?.length > 0;
};

export const checkIfSelectionsAreValid = dataFilters => {
    return dataFilters.filter(f => f.property).every(getValidationCondition);
};

export const buildFiltersFromCheckboxes = filterCheckboxes => {
    const { filterNoGroups, filterNoType, filterRefreshedPending, filterOnlyHotSheet } = filterCheckboxes;

    const filterExprs = [];

    const hotSheetGroupId = 80;

    filterNoGroups && filterExprs.push('rpProcedure/stageArea/groups/$count eq 0');

    filterNoType && filterExprs.push('rpProcedure/stageArea/type/typeId eq null');

    filterRefreshedPending &&
        filterExprs.push(
            "rpProcedure/procedureDetails/any(p:p/isLatest eq true and p/isPublished eq false and (p/versionSignificance eq null or p/versionSignificance eq OemIq.RepairProcedures.Data.Models.Rp.ProcedureVersionSignificance'MajorRevision'))"
        );

    filterOnlyHotSheet &&
        filterExprs.push(
            `rpProcedure/stageArea/groups/$count eq 1 and rpProcedure/stageArea/groups/any(group: group/groupid eq ${hotSheetGroupId})`
        );

    return filterExprs;
};

export const buildFiltersFromSelections = f =>
    f.operator.buildFilter(f.property.name.replaceAll('.', '/'), f.property.type.formatValue(f.term));
