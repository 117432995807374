import { buildProcedureProperty } from './ProcedureProperty';
import { COMMON_FILTERS, COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const RULABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'systems',
        displayName: 'Systems',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'systems',
    }),
    buildProcedureProperty({
        name: 'subSystems',
        displayName: 'Sub Systems',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'subSystems',
    }),
    buildProcedureProperty({
        name: 'multiSubSystems',
        displayName: 'Multi SubSystems',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'multiSubSystems',
    }),
    buildProcedureProperty({
        name: 'components',
        displayName: 'Components',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'components',
    }),
    buildProcedureProperty({
        name: 'title',
        displayName: 'Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'title',
    }),
    buildProcedureProperty({
        name: 'infoTypes',
        displayName: 'Info Types',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'infoTypes',
    }),
    buildProcedureProperty({
        name: 'salesCodes',
        displayName: 'Sales Codes',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'salesCodes',
    }),
];

export const STELLANTIS_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 2,
    oemName: 'Stellantis',
    properties: [
        ...COMMON_PROPERTIES,
        ...RULABLE_PROPERTIES,
        buildProcedureProperty({
            name: 'stellantisProcedureId',
            displayName: 'Stellantis Procedure Id',
            type: PROPERTY_TYPE.number,
        }),
        buildProcedureProperty({ name: 'oemProcedureId', displayName: 'Oem Procedure Id', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'version', displayName: 'Version', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'htmlFileName', displayName: 'Html File Name', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'procedureTitle', displayName: 'Procedure Title', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'multiSystems', displayName: 'Multi Systems', type: PROPERTY_TYPE.string }),
    ],
    ruleKey: 'stellantisMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type StellantisMappingRule = OemMappingRuleBase & {
    stellantisMappingRuleId: number;
};

class _StellantisMappingEngineService implements OemEngineService<StellantisMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = STELLANTIS_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: StellantisMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: StellantisMappingRule): Promise<StellantisMappingRule> => {
        const newRule = { ...rule };
        const stellantisMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.stellantisMappingRuleId = stellantisMappingRuleId;

        return newRule;
    };
}

export const StellantisMappingEngineService = new _StellantisMappingEngineService();
