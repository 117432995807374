import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import {
    requestCompany,
    requestCompanyUsers,
    requestOrganization,
    requestDisableUser,
    requestEnableUser,
    requestSendForgotPasswordEmail,
} from 'api/SecurityApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faStopCircle, faPlayCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';

const useCompanyUsers = () => {
    // higher order states
    const params = useParams();
    const location = useLocation();
    const { organizationId, locationId } = params;
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { hasRole } = useContext(AccessControlContext);
    // local states
    const userHasCustomerSupportRole = useMemo(() => hasRole(roles.customerSupport), [hasRole]);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [displayUsers, setDisplayUsers] = useState([]);
    const [editUser, setEditUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isOpenUserDisable, setShowUserDisableModal] = useState(false);
    const [isOpenSendForgotPasswordEmail, setShowUserForgotPasswordEmailModal] = useState(false);
    const [org, setOrg] = useState(null);
    const [company, setCompany] = useState(null);
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);
    const tableColumnDetails = [
        { title: 'Id', columnProperty: 'userId', sortable: true },
        { title: 'First Name', columnProperty: 'firstName', sortable: true },
        { title: 'Last Name', columnProperty: 'lastName', sortable: true },
        { title: 'Username', columnProperty: 'userName', sortable: true },
        { title: 'Email', columnProperty: 'email', sortable: true },
        { title: 'Phone', columnProperty: 'phoneNumber', sortable: true },
        { title: 'Primary Location', columnProperty: 'companyName', sortable: true },
        { title: 'Locations', columnProperty: 'locations', sortable: true },
        { title: 'Roles', columnProperty: 'role', sortable: true },
        { title: 'Status', columnProperty: 'status', sortable: true },
        { title: 'Actions', columnProperty: 'actions', sortable: false },
    ];

    useEffect(() => {
        const getCompUsers = async () => {
            try {
                incrementLoading();
                const users = await requestCompanyUsers(locationId);
                setCompanyUsers(users);
                setDisplayUsers(users);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };
        const getOrg = async () => {
            if (location && location.state && location.state.org) {
                setOrg(location.state.org);
            } else {
                try {
                    incrementLoading();
                    const orgJson = await requestOrganization(organizationId);
                    setOrg(orgJson);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };
        const getCompany = async () => {
            if (location && location.state && location.state.company) {
                setCompany(location.state.company);
            } else {
                try {
                    incrementLoading();
                    const companyJson = await requestCompany(locationId);
                    setCompany(companyJson);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };

        getOrg();
        getCompany();
        getCompUsers();
    }, [locationId, showToast, incrementLoading, decrementLoading, location, organizationId]);

    //Search Users
    useEffect(() => {
        let newSortedAndSearchedCompanyUsers = companyUsers.slice();

        //If there is a column requested to be sorted by, sort by that property and the sort direction
        if (sortedPropertyKey !== null) {
            if (sortedPropertyDirection === 'A')
                newSortedAndSearchedCompanyUsers.sort((a, b) => {
                    if (sortedPropertyKey === 'userId') return a[sortedPropertyKey] < b[sortedPropertyKey] ? -1 : 1;
                    else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? -1 : 1;
                });
            else
                newSortedAndSearchedCompanyUsers.sort((a, b) => {
                    if (sortedPropertyKey === 'userId') return a[sortedPropertyKey] < b[sortedPropertyKey] ? 1 : -1;
                    else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? 1 : -1;
                });
        }

        if (searchTerm) {
            newSortedAndSearchedCompanyUsers = companyUsers.filter(
                u =>
                    u.userId.toString().indexOf(searchTerm) > -1 ||
                    (u.firstName && u.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (u.lastName && u.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (u.email && u.email.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (u.phoneNumber && u.phoneNumber.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
            );
        }

        //Add data at base level of object to access in generic table component
        newSortedAndSearchedCompanyUsers.forEach(u => {
            //If customer support role, don't show action buttons for functionality they should not have
            if (userHasCustomerSupportRole) {
                u.actions = (
                    <div>
                        <FontAwesomeIcon
                            id={`user-${u.userId}-resend`}
                            className="text-primary clickable m-1 customer-action-button"
                            icon={faEnvelope}
                            onClick={e => handleForgotEmailClick(e, u)}
                        />
                    </div>
                );
            } else {
                u.actions = (
                    <div>
                        <FontAwesomeIcon
                            id={`user-${u.userId}-edit`}
                            className="text-primary clickable m-1 customer-action-button"
                            icon={faEdit}
                            onClick={e => handleEditClick(e, u)}
                        />
                        {u.isDeactivated ? (
                            <FontAwesomeIcon
                                id={`user-${u.userId}-activate`}
                                className="text-primary clickable m-1 customer-action-button"
                                icon={faPlayCircle}
                                onClick={e => handleEnableClick(e, u)}
                            />
                        ) : (
                            <FontAwesomeIcon
                                id={`user-${u.userId}-deactivate`}
                                className="text-primary clickable m-1 customer-action-button"
                                icon={faStopCircle}
                                onClick={e => handleDisableClick(e, u)}
                            />
                        )}
                        <FontAwesomeIcon
                            id={`user-${u.userId}-resend`}
                            className="text-primary clickable m-1 customer-action-button"
                            icon={faEnvelope}
                            onClick={e => handleForgotEmailClick(e, u)}
                        />
                    </div>
                );
            }

            u.companyName = u.company ? u.company.companyName : '';
            u.locations =
                u.companies && u.companies.length > 1
                    ? `${u.companies[0].companyName}...`
                    : `${u.companies[0].companyName}`;
            u.role = u.roles && u.roles.join(', ');
            u.status = !u.isDeactivated && u.emailConfirmed ? 'Active' : u.isDeactivated ? 'Deactivated' : 'Pending';
        });

        setDisplayUsers(newSortedAndSearchedCompanyUsers);
    }, [companyUsers, searchTerm, sortedPropertyKey, sortedPropertyDirection, userHasCustomerSupportRole]);

    const onSaveOrEdit = async () => {
        try {
            incrementLoading();
            const users = await requestCompanyUsers(locationId);
            setCompanyUsers(users);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleToggle = () => {
        if (showModal) setEditUser(null);
        setShowModal(!showModal);
    };

    const handleEditClick = (e, user) => {
        setEditUser(user);
        setShowModal(true);
        e.stopPropagation();
    };

    const handleDisableEnable = async () => {
        try {
            incrementLoading();
            if (editUser.isDeactivated) await requestEnableUser(editUser.userId);
            else await requestDisableUser(editUser.userId);
            const users = await requestCompanyUsers(locationId);
            setCompanyUsers(users);
            setShowUserDisableModal(false);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleDisableClick = async (e, user) => {
        setEditUser(user);
        setShowUserDisableModal(true);
        e.stopPropagation();
    };

    const handleEnableClick = async (e, user) => {
        setEditUser(user);
        setShowUserDisableModal(true);
        e.stopPropagation();
    };

    const handleForgotEmailClick = async (e, user) => {
        setEditUser(user);
        setShowUserForgotPasswordEmailModal(true);
        e.stopPropagation();
    };

    useEffect(() => {
        if (!isOpenUserDisable && !isOpenSendForgotPasswordEmail && !showModal) {
            setEditUser(null);
        }
    }, [isOpenUserDisable, isOpenSendForgotPasswordEmail, showModal]);

    const handleSendForgotPassword = async () => {
        try {
            incrementLoading();
            await requestSendForgotPasswordEmail(editUser.email);
            decrementLoading();
            setShowUserForgotPasswordEmailModal(false);
            setEditUser(null);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const sortClickCallback = (retSortedProperty, retSortedPropertyDirection) => {
        setSortedPropertyKey(retSortedProperty);
        setSortedPropertyDirection(retSortedPropertyDirection);
    };

    return {
        editUser,
        locationId,
        organizationId,
        showModal,
        isOpenUserDisable,
        isOpenSendForgotPasswordEmail,
        org,
        company,
        searchTerm,
        displayUsers,
        tableColumnDetails,
        userHasCustomerSupportRole,
        handleToggle,
        onSaveOrEdit,
        setShowUserDisableModal,
        handleDisableEnable,
        setShowUserForgotPasswordEmailModal,
        setSearchTerm,
        handleSendForgotPassword,
        sortClickCallback,
    };
};

export default useCompanyUsers;
