import { buildProcedureProperty } from './ProcedureProperty';
import { COMMON_FILTERS, COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const RULABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'serviceCategoryTypeName',
        displayName: 'Service Category Type Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'serviceCategoryTypeName',
    }),
    buildProcedureProperty({
        name: 'serviceCategoryName',
        displayName: 'Service Category Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'serviceCategoryName',
    }),
    buildProcedureProperty({
        name: 'serviceSystemName',
        displayName: 'Service System Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'serviceSystemName',
    }),
    buildProcedureProperty({
        name: 'componentDTCName',
        displayName: 'Component DTC Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'componentDTCName',
    }),
    buildProcedureProperty({
        name: 'displayName',
        displayName: 'Display Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'displayName',
    }),
    buildProcedureProperty({
        name: 'sitqCode',
        displayName: 'SITQ Code',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sitqCode',
    }),
    buildProcedureProperty({
        name: 'sitqDescription',
        displayName: 'SITQ Description',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'sitqDescription',
    }),
    buildProcedureProperty({
        name: 'publicationTitle',
        displayName: 'Publication Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'publicationTitle',
    }),
    buildProcedureProperty({
        name: 'procedureTypeCode',
        displayName: 'Procedure Type Code',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureTypeCode',
    }),
    buildProcedureProperty({
        name: 'procedureType',
        displayName: 'Procedure Type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureType',
    }),
    buildProcedureProperty({
        name: 'dataGroupName',
        displayName: 'Data Group Name',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'dataGroupName',
    }),
];

export const HONDA_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 17,
    oemName: 'Honda',
    properties: [
        ...COMMON_PROPERTIES,
        ...RULABLE_PROPERTIES,
        buildProcedureProperty({
            name: 'hondaProcedureId',
            displayName: 'Honda Procedure Id',
            type: PROPERTY_TYPE.number,
        }),
        buildProcedureProperty({ name: 'oemProcedureId', displayName: 'Oem Procedure Id', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'version', displayName: 'Version', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'htmlFileName', displayName: 'HTML File Name', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'createDate', displayName: 'Create Date', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({ name: 'updateDate', displayName: 'Update Date', type: PROPERTY_TYPE.string }),
    ],
    ruleKey: 'hondaMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type HondaMappingRule = OemMappingRuleBase & {
    hondaMappingRuleId: number;
};

class _HondaMappingEngineService implements OemEngineService<HondaMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = HONDA_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: HondaMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: HondaMappingRule): Promise<HondaMappingRule> => {
        const newRule = { ...rule };
        const hondaMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.hondaMappingRuleId = hondaMappingRuleId;

        return newRule;
    };
}

export const HondaMappingEngineService = new _HondaMappingEngineService();
