import { useState, useEffect, useContext, useCallback } from 'react';
import ReactModal from 'components/Shared/ReactModal';
import { loadProcedureHtml, requestProcedureDetailsApi, requestProcedureHtmlByVersion } from 'api/RepairProcedureApi';
import HtmlIFrame from './HtmlFrame/HtmlIFrame';
import { ToastContext } from 'components/ToastProvider';
import HtmlDiff from 'htmldiff-js';
import SmallSpinner from 'components/SmallSpinner';
import { useParams } from 'react-router-dom';
import './ViewProcedureModal.scss';

const customHtmlDiffStylesheet = `
ins {
    background-color: #cfc;
    text-decoration: none;
}

del {
    color: #999;
    background-color: #fec8c8;
}
`;

const ViewProcedureModal = ({
    procedureId,
    resetProcedureId,
    headerButtons,
    isRemoved = false,
    defaultCompareVersions = true,
}) => {
    const [procedure, setProcedure] = useState(null);
    const { showToast } = useContext(ToastContext);
    const [loading, setLoading] = useState(false);
    const { oemId } = useParams();
    const [shouldCompareVersions, setShouldCompareVersions] = useState(defaultCompareVersions);
    const [showVersionToggle, setShowVersionToggle] = useState(false);

    const onToggle = value => {
        const region = document.querySelector('#procedure_iframe');
        if (region) region.scrollIntoView({ block: 'center', behavior: 'smooth' });
        setShouldCompareVersions(value);
    };

    useEffect(() => {
        setShowVersionToggle(false);
    }, [procedureId]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (procedureId) {
                    const procedure = await requestProcedureDetailsApi(procedureId, isRemoved);

                    const latestProcedureDetails = procedure.procedureDetails.filter(pd => pd.isLatest);
                    const procedureHtml = await loadProcedureHtml(procedureId, latestProcedureDetails, isRemoved);
                    procedure.procedureHtml = {
                        html: procedureHtml,
                    };
                    if (shouldCompareVersions) {
                        // precondition: the procedure details returned from api call above are sorted by start date in descending order
                        if (procedure && procedure.procedureDetails) {
                            // re-use the procedure html property since test book's procedures do not have valid versions
                            const latestHtml = procedure.procedureHtml.html;

                            // remove unrelated procedure html
                            // since we are going to populate one
                            delete procedure.procedureHtml;

                            let html = null;
                            const oldProcedureDetails = procedure.procedureDetails.filter(pd => !pd.isLatest);
                            const secondLatest =
                                oldProcedureDetails && oldProcedureDetails.length > 0 ? oldProcedureDetails[0] : null;
                            if (secondLatest) {
                                const secondLatestHtml = await requestProcedureHtmlByVersion(
                                    procedureId,
                                    secondLatest.version,
                                    isRemoved
                                );

                                //parse the html inputs as DOM documents
                                const parser = new DOMParser();
                                let parsedOldHTMLDoc = parser.parseFromString(secondLatestHtml, 'text/html');
                                let parsedNewHTMLDoc = parser.parseFromString(latestHtml, 'text/html');

                                //remove the old css (note: heads are automatically added back in as blank tags)
                                let oldHead = parsedOldHTMLDoc.getElementsByTagName('head')[0];
                                parsedOldHTMLDoc.documentElement.removeChild(oldHead);

                                //store the new css, remove the new head content for the comparison step
                                let newHead = parsedNewHTMLDoc.getElementsByTagName('head')[0]; //contains the CSS we will favour
                                parsedNewHTMLDoc.documentElement.removeChild(newHead);

                                //remove all "invisible" changes to image tags, focus only on changes to src
                                let oldImgs = parsedOldHTMLDoc.getElementsByTagName('img');
                                let newImgs = parsedNewHTMLDoc.getElementsByTagName('img');

                                let newImageMap = new Map();
                                for (let i = 0; i < newImgs.length; ++i) {
                                    newImageMap[newImgs[i].src] = i;
                                }

                                for (let c = 0; c < oldImgs.length; ++c) {
                                    let idx = newImageMap[oldImgs[c].src];
                                    if (idx >= 0) {
                                        let copy = newImgs[idx].cloneNode(true);
                                        oldImgs[c].replaceWith(copy);
                                    }
                                }

                                //prepare raw HTML for diffing, correct for known irrelevant differences
                                let oldHtmlToDiff = parsedOldHTMLDoc.documentElement.innerHTML;
                                let newHtmlToDiff = parsedNewHTMLDoc.documentElement.innerHTML;

                                oldHtmlToDiff = oldHtmlToDiff.replaceAll('U+00a0', '&nbsp');
                                newHtmlToDiff = newHtmlToDiff.replaceAll('U+00a0', '&nbsp');
                                oldHtmlToDiff = oldHtmlToDiff.replaceAll('%2F', '/'); //Changed image path to not encode / anymore so this will fix the difference from showing up.
                                newHtmlToDiff = newHtmlToDiff.replaceAll('%2F', '/');

                                //diff
                                const diffedHtml = HtmlDiff.execute(oldHtmlToDiff, newHtmlToDiff);

                                //add the new CSS back in
                                let diffedDoc = parser.parseFromString(diffedHtml, 'text/html');
                                let diffedHead = diffedDoc.getElementsByTagName('head')[0];
                                diffedHead.replaceWith(newHead, oldHead);
                                let displayDIffedDoc = diffedDoc.documentElement.innerHTML;

                                if (oemId === '4')
                                    //Nissan
                                    displayDIffedDoc = displayDIffedDoc
                                        .replaceAll('background-color: #666666;', '')
                                        .replaceAll('color: white;', '');

                                html = displayDIffedDoc;
                                defaultCompareVersions && setShowVersionToggle(true);
                            } else {
                                html = latestHtml;
                            }

                            if (html) {
                                procedure.procedureHtml = { html: html };
                            } else {
                                throw new Error(`Html not found pId = ${procedureId}`);
                            }
                        } else {
                            throw new Error(`Procedure details not found pId = ${procedureId}`);
                        }
                    }

                    setProcedure(procedure);
                }
            } catch (error) {
                showToast(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [procedureId, showToast, oemId, shouldCompareVersions, isRemoved, defaultCompareVersions]);

    const handleModalToggle = useCallback(() => {
        setProcedure(null);
        resetProcedureId();
    }, [resetProcedureId]);

    return (
        <>
            <ReactModal
                id="procedure-html-modal"
                className="modal-max-height"
                container={undefined}
                headerComponent={
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{ fontSize: '1rem', lineHeight: 'normal', maxWidth: '60%' }}>
                            {procedure && procedure.procedureTitle}
                        </div>
                        {/* TODO: make this sharable component in oemiq-common. BookFilterTools.jsx also uses this */}
                        <div className="align-items-center d-flex justify-content-between ms-2">
                            {showVersionToggle && (
                                <div className="content-switches me-4">
                                    <div className="switch switch-sm" title="Show Version Difference">
                                        <input
                                            type="checkbox"
                                            className="switch"
                                            id={'toggle-show-diff'}
                                            checked={shouldCompareVersions}
                                            onChange={e => onToggle(e.target.checked)}
                                        />
                                        <label className="text-darkblue mt-2 ms-2" htmlFor={'toggle-show-diff'}></label>
                                    </div>
                                    <span style={{ verticalAlign: 'middle' }}>Show Version Difference</span>
                                </div>
                            )}
                            {headerButtons}
                        </div>
                    </div>
                }
                isOpen={procedureId !== null}
                toggle={handleModalToggle}
                bodyComponent={
                    loading ? (
                        <SmallSpinner />
                    ) : (
                        procedure !== null && (
                            <HtmlIFrame
                                procedureSource={procedure.procedureHtml.html}
                                customStylesheet={shouldCompareVersions ? customHtmlDiffStylesheet : null}
                            />
                        )
                    )
                }
            />
        </>
    );
};

export default ViewProcedureModal;
