import { Buttons, Text } from 'components/Shared/Table/Cells';
import VehicleHistory from './VehicleHistory/VehicleHistory';
import { readyForPublish, notReady, readyForUnpublish } from './vehicleState';
import '../../../Shared/Table/Table.scss';

const tableConfiguration = [
    {
        label: 'Vehicle',
        id: 'label',
        component: Text({ class: item => (item.isVehicle ? 'fw-bold vehicle-label-color' : 'ps-5') }),
    },
    {
        label: 'Mapping Status',
        id: 'mappingStatus',
    },
    {
        label: 'Total Tags',
        id: 'totalTags',
    },
    {
        label: 'Status',
        component: Buttons({
            buttons: [
                {
                    id: 'publishVehicle',
                    class: 'btn-success',
                    text: 'Publish',
                    icon: 'paper-plane',
                    title: 'Publish vehicle',
                    show: i => i.isVehicle && readyForPublish(i),
                    disabled: () => false,
                },
                {
                    id: 'vehicleNotReady',
                    class: 'btn-secondary',
                    text: 'Not ready',
                    title: 'Vehicle not ready',
                    show: i => i.isVehicle && notReady(i),
                    disabled: () => true,
                },
                {
                    id: 'unPublishVehicle',
                    class: 'btn-danger',
                    text: 'Unpublish',
                    icon: 'paper-plane',
                    title: 'Unpublish vehicle',
                    show: i => i.isVehicle && readyForUnpublish(i),
                    disabled: () => false,
                },
            ],
        }),
    },
    {
        label: 'Date Published',
        id: 'datePublished',
        component: VehicleHistory,
    },
];

export default tableConfiguration;
