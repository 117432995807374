import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { COMMON_FILTERS, COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { OemMetadata, buildOemMetadata } from './BuildOemMetadata';
import { OemEngineService, OemMappingRuleBase } from './types';
import { buildProcedureProperty } from './ProcedureProperty';
import { PROPERTY_TYPE } from './PropertyType';

const RULEABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'mainGroup',
        displayName: 'Main Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'mainGroup',
    }),
    buildProcedureProperty({
        name: 'repairGroup',
        displayName: 'Repair Group',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'repairGroup',
    }),
    buildProcedureProperty({
        name: 'publicationGroupTitle',
        displayName: 'Publication Group Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'publicationGroupTitle',
    }),
    buildProcedureProperty({
        name: 'mainChapterTitle',
        displayName: 'Main Chapter Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'mainChapterTitle',
    }),
    buildProcedureProperty({
        name: 'procedureTitle',
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'procedureTitle',
    }),
    buildProcedureProperty({
        name: 'manualType',
        displayName: 'Manual Type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'manualType',
    }),
    buildProcedureProperty({
        name: 'chapterType',
        displayName: 'Chapter Type',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'chapterType',
    }),
    buildProcedureProperty({
        name: 'chapterTitle',
        displayName: 'Chapter Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'chapterTitle',
    }),
    buildProcedureProperty({
        name: 'typeKeywords',
        displayName: 'Type Keywords',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'typeKeywords',
    }),
    buildProcedureProperty({
        name: 'isLinksOnly',
        displayName: 'Links Only',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'isLinksOnly',
    }),
];

const NON_RULEABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'vwProcedureId',
        displayName: 'VW Procedure Id',
        type: PROPERTY_TYPE.number,
    }),
    buildProcedureProperty({ name: 'oemProcedureId', displayName: 'Oem Procedure Id', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'secondaryId', displayName: 'Secondary Id', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'version', displayName: 'Version', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'htmlFileName', displayName: 'HTML File Name', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'originalFileName', displayName: 'Original File Name', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'externalId', displayName: 'External Id', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'rawExternalId', displayName: 'Raw External Id', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'workshopTaskId', displayName: 'Workshop Task Id', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({
        name: 'externalChapterNumber',
        displayName: 'External Chapter Number',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: 'publicationGroupNumber',
        displayName: 'Publication Group Number',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({ name: 'typeCode', displayName: 'Type Code', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'salesType', displayName: 'Sales Type', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'engineCode', displayName: 'Engine Code', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'transmissionCode', displayName: 'Transmission Code', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'partCode', displayName: 'Part Code', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'modelYear', displayName: 'Model Year', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'createDate', displayName: 'Create Date', type: PROPERTY_TYPE.string }),
    buildProcedureProperty({ name: 'updateDate', displayName: 'Update Date', type: PROPERTY_TYPE.string }),
];

export const VW_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 26,
    oemName: 'VW',
    properties: [...COMMON_PROPERTIES, ...RULEABLE_PROPERTIES, ...NON_RULEABLE_PROPERTIES],
    ruleKey: 'vwMappingRuleId', // placeholder, update as needed
    filters: [...COMMON_FILTERS],
});

interface VWMappingRule extends OemMappingRuleBase {
    vwMappingRuleId: number;
}

class _VWMappingEngineService implements OemEngineService<VWMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = VW_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: VWMappingRule): boolean =>
        rule.typeId && rule.groupIds.length && this.metadata.ruleable.some(r => rule[r.rulePropertyName]);

    createRule = async (rule: VWMappingRule): Promise<VWMappingRule> => {
        const newRule = { ...rule };
        const vwMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.vwMappingRuleId = vwMappingRuleId;

        return newRule;
    };
}

export const VWMappingEngineService = new _VWMappingEngineService();
