import { useCallback, useMemo } from 'react';
import { publishTemplate, unpublishTemplate } from './ConfirmationTemplates/confirmationTemplates';
import usePublishingConfirmation from './usePublishingConfirmation';

const useTableActions = (setData, notifications, confirmRef) => {
    const { openConfirmation } = usePublishingConfirmation({ confirmRef, setData, notifications });

    const publishVehicle = useCallback(
        async e => {
            await openConfirmation(e, true, publishTemplate(e));
        },
        [openConfirmation]
    );

    const unPublishVehicle = useCallback(
        async e => {
            await openConfirmation(e, false, unpublishTemplate(e));
        },
        [openConfirmation]
    );

    return useMemo(
        () => ({
            publishVehicle,
            unPublishVehicle,
        }),
        [publishVehicle, unPublishVehicle]
    );
};

export default useTableActions;
