import React, { useState, useContext, useCallback, useMemo } from 'react';
import { PrimaryButton } from 'oemiq-common';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import useOemService from 'hooks/OemModels/useOemService';
import useProcedures from 'components/OemProceduresTable/items/useProcedures';
import useColumnSettings from './useColumnSettings';
import useSorting from './useSorting';
import ColumnSettings from 'components/locations/MappingProcess/MapperList/Actions/ColumnSettings/ColumnSettings';
import BuildMappingRuleModal from './BuildMappingRuleModal/BuildMappingRuleModal';
import OemProceduresTable from 'components/OemProceduresTable/OemProceduresTable';
import { buildFiltersFromCheckboxes } from './MappingRuleFilters/ApplyFilters';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import TableFilters from 'components/Shared/TableFilters/TableFilters';
import buildFiltersConfiguration from './buildFiltersConfiguration';
import ProcedureFilterCheckboxes from './MappingRuleFilters/ProcedureFilterCheckboxes';
import { isFilterRulable } from 'components/Shared/TableFilters/operators';
import { createFiltersFromSearchParams } from 'components/Shared/TableFilters/tableFilterHelpers';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import ViewProcedureMappingHistoryModal from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { useSearchParams } from 'react-router-dom';
import SmallSpinner from 'components/SmallSpinner';
import './mappingProceduresTool.scss';

export const buildFilters = (dataFilters, filterCheckboxes) => {
    const filterExprs = buildFiltersFromCheckboxes(filterCheckboxes);
    filterExprs.push(...dataFilters.map(f => f.urlFilter));
    return filterExprs.join(' and ');
};

const defaultFilterCheckboxes = {
    filterNoGroups: false,
    filterNoType: false,
    filterRefreshedPending: false,
    filterOnlyHotSheet: false,
};

const MappingProceduresTool = () => {
    const routeParams = useLocation();
    const [searchParams] = useSearchParams();
    const searchParamsObject = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
    const navigate = useNavigate();
    const { hasAccess } = useContext(AccessControlContext);

    const { oemId } = useParams();
    const { oemMetadata, oemEngineService } = useOemService(oemId);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isCreateMappingRuleModalOpen, setCreateMappingRuleModalOpen] = useState(false);
    const { notifications } = useContext(NotificationsContext);
    const filtersConfiguration = buildFiltersConfiguration(oemMetadata);
    const [newFilters, setNewFilters] = useState(
        routeParams.search ? createFiltersFromSearchParams(searchParamsObject, filtersConfiguration) : []
    );

    const mappingDefinitions = useContext(MappingDefinitionsContext);

    if (!oemMetadata) {
        !errorMessage && setErrorMessage('Oem selected does not currently support mapping engine functionality');
    }

    const handleCreateMappingRule = useCallback(
        async rule => {
            try {
                await oemEngineService.createRule(rule);
                notifications.pushSuccess('New mapping rule created');
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, oemEngineService]
    );

    const [orderBy, setOrderBy] = useState('');
    const [filterCheckboxes, setFilterCheckboxes] = useState(defaultFilterCheckboxes);

    const {
        data,
        hasMoreData,
        loading: isLoadingProcedures,
        totalCount,
        loadMoreCallback,
    } = useProcedures(buildFilters(newFilters, filterCheckboxes), orderBy, oemId);
    const { columnSettings, handleSaveColumnSettings, setShowColumnSettings, showColumnSettings } = useColumnSettings(
        'MappingEngineTool',
        oemMetadata.properties
    );

    const { updateSortRules } = useSorting();

    const handleSorting = (columnName, order) => {
        let sortRules = updateSortRules(columnName, order);
        const sortRulesString = sortRules.map(r => {
            if (r.order) {
                return r.columnName + ' ' + r.order;
            }
        });
        setOrderBy(sortRulesString);
    };

    const canCreateRule = () => {
        const filters = newFilters.filter(f => f.property);
        const ruleableFilters = filters.filter(isFilterRulable);
        const uniqueFilterCount = new Set(filters.map(f => f.property)).size;
        return (
            filters.length > 0 &&
            !filterCheckboxes.filterRefreshedPending &&
            !filterCheckboxes.filterNoGroups &&
            !filterCheckboxes.filterNoType &&
            ruleableFilters.length === filters.length &&
            ruleableFilters.length === uniqueFilterCount
        );
    };

    const navigateToRules = useCallback(() => {
        const filters = newFilters
            .filter(f => f.ruleable)
            .map(f => ({
                id: oemMetadata.properties.find(p => p.id === f.id).rulePropertyName,
                operator: f.operator.value,
                value: f.value,
            }));

        navigate(`/mapping-process/rules-runner/${oemId}`, { state: { filters } });
    }, [newFilters, navigate, oemId, oemMetadata.properties]);

    const [modalProcedureId, setModalProcedureId] = useState(null);
    const [historyModalProcedure, setHistoryModalProcedure] = useState(null);

    return (
        <div className="container-main pt-3 pe-3 ps-3 d-flex flex-column">
            <div className="d-flex">
                <h2 className="flex-grow-1">Procedures</h2>
                <PrimaryButton id="link-goto-view-rules" type="button" className="me-3" onClick={navigateToRules}>
                    View Rules <FontAwesomeIcon className="ms-1" icon="arrow-up-right-from-square" />
                </PrimaryButton>
                <PrimaryButton
                    id="btn-modal-create-mapping-rule"
                    className="me-3"
                    type="button"
                    disabled={!canCreateRule() || !hasAccess('rule.create')}
                    onClick={() => setCreateMappingRuleModalOpen(true)}>
                    Create New Mapping Rule
                </PrimaryButton>
                <PrimaryButton id="column-settings" type="button" onClick={() => setShowColumnSettings(true)}>
                    Column Settings
                </PrimaryButton>
            </div>

            <div className="text-danger">{errorMessage && errorMessage}</div>

            <TableFilters
                configuration={filtersConfiguration}
                filters={newFilters}
                setFilters={setNewFilters}></TableFilters>

            <ProcedureFilterCheckboxes filterCheckboxes={filterCheckboxes} setFilterCheckboxes={setFilterCheckboxes} />

            <ViewProcedureModal procedureId={modalProcedureId} resetProcedureId={() => setModalProcedureId(null)} />
            <ViewProcedureMappingHistoryModal
                procedure={historyModalProcedure}
                resetProcedureId={() => setHistoryModalProcedure(null)}
            />

            <OemProceduresTable
                data={data}
                hasMoreData={hasMoreData}
                oemMetadata={oemMetadata}
                columnSettings={columnSettings}
                isLoading={isLoadingProcedures}
                onSorting={handleSorting}
                loadMoreCallback={loadMoreCallback}
                onClickModalProcedureId={setModalProcedureId}
                onclickHistoryModalProcedure={setHistoryModalProcedure}
            />

            <footer id="status-bar" className="align-self-start m-1">
                <TotalCount totalCount={totalCount} current={data.length} />
            </footer>

            <BuildMappingRuleModal
                isModalOpen={isCreateMappingRuleModalOpen}
                setModalOpen={setCreateMappingRuleModalOpen}
                filters={newFilters.filter(f => f.property)}
                mappingDefinitions={mappingDefinitions}
                proceduresCount={totalCount}
                oemMetadata={oemMetadata}
                onCreateMappingRule={handleCreateMappingRule}
            />

            <ColumnSettings
                showColumnSettings={showColumnSettings}
                setShowColumnSettings={setShowColumnSettings}
                hideDynamicKeyList={[]}
                dynamicProcedureKeys={columnSettings}
                onSaveColumnSettings={handleSaveColumnSettings}
            />
        </div>
    );
};

const TotalCount = ({ totalCount, current }) => {
    if (totalCount.loadingCount) {
        return <SmallSpinner />;
    }

    if (totalCount.error) {
        return <span className="total-count-error">Unable to load total count (loaded {current} procedures)</span>;
    }

    return (
        <>
            {current} procedures of {totalCount.value}
        </>
    );
};

export default MappingProceduresTool;
