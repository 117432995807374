import { OemId } from 'helpers/OemId';
import { useMemo } from 'react';
import { match } from 'ts-pattern';
import IngestionProcessList from './CustomCells/IngestionProcess';

const useTableConfiguration = (oemId: OemId) => {
    const conf = useMemo(() => {
        const table = match(oemId)
            .with(OemId.Ford, () => FordTableConfiguration)
            .with(OemId.GMC, () => GMTableConfiguration)
            .run();
        return table;
    }, [oemId]);

    return conf;
};

const FordTableConfiguration = [
    {
        label: 'Year',
        id: 'yearName',
    },
    {
        label: 'Model',
        id: 'modelName',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'ingestion-process-header',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
];

const GMTableConfiguration = [
    {
        label: 'Name',
        id: 'pubName',
    },
    {
        label: 'Ingestion Process',
        id: 'Ingestion Process',
        thClass: 'ingestion-process-header',
        component: IngestionProcessList({
            newButton: {
                id: 'handleNewButton',
                title: "The vehicle hasn't been reviewed yet",
            },
            onButton: {
                id: 'handleOnButton',
                title: 'The vehicle has been reviewed and will be scrapped and imported during the next refresh',
            },
            offButton: {
                id: 'handleOffButton',
                title: 'The vehicle has been reviewed and will NOT be scrapped and imported during the next refresh',
            },
        }),
    },
];

export default useTableConfiguration;
