import { RequestRestGetWithOdataParams } from '../RequestRestGetWithOdataParams';
import {
    FordProcedure,
    GMProcedure,
    HondaProcedure,
    NissanProcedure,
    OemIqFordProcedure,
    StellantisProcedure,
    SubaruProcedure,
    ToyotaProcedure,
    VWProcedure,
} from './OemProcedureTypes';
import { OdataResponse } from './types';

export class OemProceduresApiService<TProcedure> {
    readonly proceduresRoute: string;
    readonly defaultOrderBy: string;
    // TODO: Filter `isTestOem` excluding test procedures (includes one test books) should be remove, but first data in OemId != 100 needs to be cleaned
    readonly isTestOem: boolean;

    readonly TestBookFilter = 'RpProcedure/BooksForProcedure/all(b: not b/Book/IsTestBook)';
    readonly ProceduresExpand =
        'RpProcedure($select=ProcedureTitle,MappingRuleId,StageArea;$expand=BooksForProcedure($select=Book;$expand=Book($select=BookId,BookName,IsTestBook)))';

    constructor({ url, defaultOrderBy, isTestOem }: { url: string; defaultOrderBy: string; isTestOem?: boolean }) {
        this.proceduresRoute = url;
        this.defaultOrderBy = defaultOrderBy;
        this.isTestOem = isTestOem || false;
    }

    buildFilter = (filter: string | null): string =>
        this.isTestOem ? filter : filter ? `${filter} and ${this.TestBookFilter}` : this.TestBookFilter;

    countProcedures = async (filter: string | null): Promise<number> => {
        filter = this.buildFilter(filter);
        const oDataParams = { filter, top: 0, count: true };
        const result = await RequestRestGetWithOdataParams(this.proceduresRoute, oDataParams);
        return result['@odata.count'];
    };

    getProcedures = async (
        filter: string | null,
        orderBy: string,
        top: number,
        skip: number
    ): Promise<OdataResponse<TProcedure[]>> => {
        const result = await RequestRestGetWithOdataParams(this.proceduresRoute, {
            filter: this.buildFilter(filter),
            orderBy: orderBy || this.defaultOrderBy,
            top,
            skip,
            expand: this.ProceduresExpand,
            count: false,
        });

        return result;
    };
}

export const FordProceduresApiService = new OemProceduresApiService<FordProcedure>({
    url: '/api/RepairProcedure/odata/FordProcedure',
    defaultOrderBy: 'FordProcedureId',
});

export const ToyotaProceduresApiService = new OemProceduresApiService<ToyotaProcedure>({
    url: '/api/RepairProcedure/odata/ToyotaProcedure',
    defaultOrderBy: 'ToyotaProcedureId',
});

export const NissanProceduresApiService = new OemProceduresApiService<NissanProcedure>({
    url: '/api/RepairProcedure/odata/NissanProcedure',
    defaultOrderBy: 'NissanProcedureId',
});

export const GMProceduresApiService = new OemProceduresApiService<GMProcedure>({
    url: '/api/RepairProcedure/odata/GmProcedure',
    defaultOrderBy: 'gmProcedureId',
});

export const HondaProceduresApiService = new OemProceduresApiService<HondaProcedure>({
    url: '/api/RepairProcedure/odata/HondaProcedure',
    defaultOrderBy: 'HondaProcedureId',
});

export const StellantisProceduresApiService = new OemProceduresApiService<StellantisProcedure>({
    url: '/api/RepairProcedure/odata/StellantisProcedure',
    defaultOrderBy: 'StellantisProcedureId',
});

export const VWProceduresApiService = new OemProceduresApiService<VWProcedure>({
    url: '/api/RepairProcedure/odata/VWProcedure',
    defaultOrderBy: 'VWProcedureId',
});

export const SubaruProceduresApiService = new OemProceduresApiService<SubaruProcedure>({
    url: '/api/RepairProcedure/odata/SubaruProcedure',
    defaultOrderBy: 'SubaruProcedureId',
});

export const OemIqFordProceduresApiService = new OemProceduresApiService<OemIqFordProcedure>({
    url: '/api/RepairProcedure/odata/OemIqFordProcedure',
    defaultOrderBy: 'FordProcedureId',
    isTestOem: true,
});
