import { useState, useContext, useCallback } from 'react';
import { requestUpdateProcedureMappingStatus } from 'api/RepairProcedureApi';
import { LoadingContext } from 'components/Layout';
import { useParams } from 'react-router-dom';
import { MapperListContext } from 'contexts/MapperListContext';

const useBulkMoveModal = (statusModalProcedureIds, resetProcedureIds, procedures, resetBulkSelection) => {
    const [selectedStatusId, setSelectedStatusId] = useState(-1);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { updateProcedures, refreshProcedures, toggleBulkTaskHistoryModal } = useContext(MapperListContext);
    const { oemId } = useParams();

    const handleModalToggle = useCallback(() => {
        resetProcedureIds();
        setSelectedStatusId(-1);
    }, [resetProcedureIds]);

    const handleBulkChangeStatusProcedures = useCallback(async () => {
        incrementLoading();
        const selectedStatusIdInt = parseInt(selectedStatusId);

        let idHash = {};
        statusModalProcedureIds.forEach(id => (idHash[id] = id));

        let proceduresIdsToUpdate = [];
        let regionIdsToUpdate = [];
        let updatedProcedures = [];

        procedures.forEach(procedure => {
            if (idHash[procedure.procedureId]) {
                proceduresIdsToUpdate.push(procedure.procedureId);

                procedure.stageArea.type.mappingStatusId = selectedStatusIdInt;
                procedure.stageArea.groups.forEach(group => {
                    regionIdsToUpdate.push(group.groupId);
                    group.mappingStatusId = selectedStatusIdInt;
                });

                updatedProcedures.push(procedure);
            }
        });

        const { isQueued } = await requestUpdateProcedureMappingStatus(
            proceduresIdsToUpdate,
            selectedStatusIdInt,
            parseInt(oemId)
        );

        updateProcedures(updatedProcedures);

        handleModalToggle();
        resetBulkSelection();
        decrementLoading();
        await refreshProcedures();

        if (isQueued) {
            toggleBulkTaskHistoryModal();
        }
    }, [
        decrementLoading,
        handleModalToggle,
        incrementLoading,
        procedures,
        refreshProcedures,
        resetBulkSelection,
        selectedStatusId,
        statusModalProcedureIds,
        updateProcedures,
        oemId,
        toggleBulkTaskHistoryModal,
    ]);

    return {
        handleModalToggle,
        selectedStatusId,
        setSelectedStatusId,
        handleBulkChangeStatusProcedures,
    };
};

export default useBulkMoveModal;
