import { fetchWithAuthHeader } from './AuthUtils';
import { ODataParams, constructUrlFromODataParams } from './OData/OData';

export const requestFordBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/FordBook', params);
    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve Ford books');

    const res = await response.json();
    return res.value;
};

export const requestOemIqFordBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/OemIqFordBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve OemIqFord books');

    const res = await response.json();
    return res.value;
};

export const requestGMCBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/GMBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve GMC books');

    const res = await response.json();
    return res.value;
};

export const requestNissanBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/NissanBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve Nissan books');

    const res = await response.json();
    return res.value;
};

export const requestToyotaBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/ToyotaBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve Toyota books');

    const res = await response.json();
    return res.value;
};

export const requestHondaBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/HondaBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve Honda books');

    const res = await response.json();
    return res.value;
};

export const requestStellantisBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/StellantisBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve Stellantis books');

    const res = await response.json();
    return res.value;
};

export const requestVWBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/VWBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve VW books');

    const res = await response.json();
    return res.value;
};

export const requestSubaruBooks = async (params?: ODataParams) => {
    const url = constructUrlFromODataParams('api/RepairProcedure/odata/SubaruBook', params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve Subaru books');

    const res = await response.json();
    return res.value;
};

export const requestBookByBookId = async (bookId: number, params?: ODataParams) => {
    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/book(${bookId})`, params);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Could not retrieve book');

    const res = await response.json();
    return res;
};

export const requestToyotaBookByRpBookId = async (rpBookId: number) => {
    const odataParams: ODataParams = {
        filter: `RpBookId eq ${rpBookId} and bookProcedures/Any()`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/ToyotaBook`, odataParams);

    const response = await fetchWithAuthHeader(url, {
        method: 'GET',
    });

    if (!response.ok) throw new Error('Failed to retrieve Toyota books');

    const res = await response.json();
    return res.value;
};

export const requestFordProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `FordRawBooks/any(b:b/fordBookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `FordRawBooks/any(b:b/fordBookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/FordProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve Ford procedures');

    const res = await response.json();
    return res.value;
};

export const requestOemIqProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `FordRawBooks/any(b:b/fordBookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `FordRawBooks/any(b:b/fordBookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/OemIqFordProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve OemIqFord procedures');

    const res = await response.json();
    return res.value;
};

export const requestGMCProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `RpProcedure/BooksForProcedure/any(bp: bp/BookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `RpProcedure/BooksForProcedure/any(bp: bp/BookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/GmProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve GMC procedures');

    const res = await response.json();
    return res.value;
};

export const requestToyotaProceduresByToyotaBookApi = async (
    rpBookId: number,
    toyotaBookId: number,
    isRemoved: boolean
) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `RpProcedure/BooksForProcedure/any(bp: bp/BookId eq ${rpBookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted) and ToyotaBooksForProcedure/any(bp: bp/ToyotaBookId eq ${toyotaBookId})`
            : `ToyotaBooksForProcedure/any(bp: bp/ToyotaBookId eq ${toyotaBookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/ToyotaProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve Toyota procedures');

    const res = await response.json();
    return res.value;
};

export const requestHondaProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `HondaBooks/Any(b: b/HondaBookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `HondaBooks/Any(b: b/HondaBookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/HondaProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve Honda procedures');

    const res = await response.json();
    return res.value;
};

export const requestNissanProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `NissanBooksForProcedure/any(b:b/NissanBookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `NissanBooksForProcedure/any(b:b/NissanBookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/NissanProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve Nissan procedures');

    const res = await response.json();
    return res.value;
};

export const requestStellantisProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `StellantisBooks/Any(b: b/StellantisBookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `StellantisBooks/Any(b: b/StellantisBookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/StellantisProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve Stellantis procedures');

    const res = await response.json();
    return res.value;
};

export const requestVWProceduresByBookApi = async (vwBookId: number, isRemoved: boolean) => {
    const expandFilter = isRemoved
        ? '($filter=rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted eq true)'
        : '';
    const odataParams: ODataParams = {
        expand: `vwProcedures${expandFilter}`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/VWBook(${vwBookId})`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error(`Failed to retrieve VW procedures for vwBookId ${vwBookId}`);

    const res = await response.json();
    return res.vwProcedures;
};

export const requestSubaruProceduresByBookApi = async (bookId: number, isRemoved: boolean) => {
    const odataParams: ODataParams = {
        filter: isRemoved
            ? `SubaruBooks/Any(b: b/SubaruBookId eq ${bookId}) and (rpProcedure/isDeleted eq true or rpProcedure/stageArea/isDeleted)`
            : `SubaruBooks/Any(b: b/SubaruBookId eq ${bookId})`,
    };

    const url = constructUrlFromODataParams(`api/RepairProcedure/odata/SubaruProcedure`, odataParams);

    const response = await fetchWithAuthHeader(url, isRemoved ? { headers: { 'x-client': 'none' } } : {});
    if (!response.ok) throw new Error('Could not retrieve Subaru procedures');

    const res = await response.json();
    return res.value;
};
