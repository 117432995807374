import { buildProcedureProperty } from './ProcedureProperty';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { COMMON_FILTERS, COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const RULABLE_PROPERTIES = [
    buildProcedureProperty({
        name: 'tmsMetaLevel1',
        displayName: 'TMS Meta Level1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'tmsMetaLevel1',
    }),
    buildProcedureProperty({
        name: 'tmsMetaLevel2',
        displayName: 'TMS Meta Level2',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'tmsMetaLevel2',
    }),
    buildProcedureProperty({
        name: 'tmsMetaLevel3',
        displayName: 'TMS Meta Level3',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'tmsMetaLevel3',
    }),
    buildProcedureProperty({
        name: 'title',
        displayName: 'Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'title',
    }),
    buildProcedureProperty({
        name: 'repairIntelligence1',
        displayName: 'Repair Intelligence 1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'repairIntelligence1',
    }),
];

export const TOYOTA_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 3,
    oemName: 'Toyota',
    properties: [
        ...COMMON_PROPERTIES,
        ...RULABLE_PROPERTIES,
        buildProcedureProperty({
            name: 'toyotaProcedureId',
            displayName: 'Toyota Procedure Id',
            type: PROPERTY_TYPE.number,
        }),
        buildProcedureProperty({
            name: 'oemProcedureId',
            displayName: 'Oem Procedure Id',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'version',
            displayName: 'Version',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'personName',
            displayName: 'Person Name',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'personId',
            displayName: 'Person Id',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'publicationNumber',
            displayName: 'Publication Number',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'appDivision',
            displayName: 'App Division',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'appModelName',
            displayName: 'App Model Name',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'startYear',
            displayName: 'Start Year',
            type: PROPERTY_TYPE.number,
        }),
        buildProcedureProperty({
            name: 'endYear',
            displayName: 'End Year',
            type: PROPERTY_TYPE.number,
        }),
        buildProcedureProperty({ name: 'VDS', displayName: 'VDS', type: PROPERTY_TYPE.string }),
        buildProcedureProperty({
            name: 'engineFamily',
            displayName: 'Engine Family',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'audience',
            displayName: 'Audience',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'objectType',
            displayName: 'Object Type',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'metaStructure',
            displayName: 'Meta Structure',
            type: PROPERTY_TYPE.string,
        }),
        buildProcedureProperty({
            name: 'xmlType',
            displayName: 'Xml Type',
            type: PROPERTY_TYPE.string,
        }),
    ],
    ruleKey: 'toyotaMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type ToyotaMappingRule = OemMappingRuleBase & {
    toyotaMappingRuleId: number;
};

class _ToyotaMappingEngineService implements OemEngineService<ToyotaMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = TOYOTA_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: ToyotaMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: ToyotaMappingRule): Promise<ToyotaMappingRule> => {
        const newRule = { ...rule };
        const toyotaMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.toyotaMappingRuleId = toyotaMappingRuleId;

        return newRule;
    };
}

export const ToyotaMappingEngineService = new _ToyotaMappingEngineService();
