import React, { useEffect } from 'react';
import './../ViewProcedureModal.scss';

const HtmlIFrame = ({ procedureSource, customStylesheet = null }) => {
    useEffect(() => {
        if (procedureSource) {
            const iframe = document.getElementById('procedure_iframe');
            const iframeDocument = iframe.contentDocument;
            iframeDocument.open();
            iframeDocument.write(procedureSource);
            iframeDocument.close();

            if (customStylesheet) {
                // add custom stylesheet to the iframe
                const head = iframeDocument.head || iframeDocument.getElementsByTagName('head')[0];
                const styleElement = iframeDocument.createElement('style');
                styleElement.type = 'text/css';
                if (styleElement.styleSheet) {
                    styleElement.styleSheet.cssText = customStylesheet;
                } else {
                    styleElement.appendChild(iframeDocument.createTextNode(customStylesheet));
                }
                head.appendChild(styleElement);
            }
        }
    }, [procedureSource, customStylesheet]);

    return (
        <iframe title="procedure_iframe" id="procedure_iframe" frameBorder="no" className="procedure-content"></iframe>
    );
};

export default HtmlIFrame;
