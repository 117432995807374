import { fetchWithAuthHeader } from './AuthUtils';

export const downloadPlansInCSVFormat = async repairPlanIds => {
    const url = 'api/RepairPlan/DownloadPlansInCSVFormat';
    await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(repairPlanIds),
    }).then(async res => {
        if (!res.ok) throw new Error('Failed to download');

        let a = document.createElement('a');
        a.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(await res.json());
        a.download = 'plan.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
    });
};

export const getRepairPlansByCompanyId = async companyId => {
    const url = `api/RepairPlan/GetRepairPlansByCompanyId/${companyId}`;
    let response = await fetchWithAuthHeader(url);

    if (!response.ok) throw new Error('Failed to get repair plans');

    let res = await response.json();

    return res.value;
};
