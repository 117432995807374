import CompanyModal from './CompanyModal/CompanyModal';
import CustomerBreadCrumbs from '../CustomerBreadCrumbs';
import useOrganizationCompanies from './useOrganizationCompanies';
import { Table } from 'oemiq-common';
import OrganizationModal from '../Organizations/OrganizationModal/OrganizationModal';
import useOrganizations from '../Organizations/useOrganizations';
import { OEC_STAGING_ORG } from '../../ManageCustomers/ManageCustomersConstants';
const OrganizationCompanies = () => {
    // take in a portion of the organization page state management to power the staging area functionality
    const {
        onSaveOrEdit: onOrgSaveOrEdit,
        editOrgId,
        setEditOrgId,
        handleCreateOrEditOrgClick: handleOrgEditClick,
    } = useOrganizations();

    const {
        showModal,
        organizationId,
        editCompanyId,
        org,
        searchTerm,
        displayCompanies,
        tableColumnDetails,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleCompModalToggle,
        setSearchTerm,
        handleOrgUsersClick,
        setShowModal,
        handleRowClick,
        sortClickCallback,
        showOrgModal,
        handleOrgModalToggle,
    } = useOrganizationCompanies(handleOrgEditClick, setEditOrgId);

    const inStagingOrg = parseInt(organizationId) === OEC_STAGING_ORG.organizationId;

    return (
        <div>
            {showModal && (
                <CompanyModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={organizationId}
                    isOpen={showModal}
                    companyId={editCompanyId}
                    toggle={handleCompModalToggle}
                    targetOrgId={editOrgId}
                />
            )}
            {showOrgModal && (
                <OrganizationModal
                    onSaveOrEdit={onOrgSaveOrEdit}
                    stagedCompId={editCompanyId ? editCompanyId : null}
                    isOpen={showOrgModal}
                    toggle={handleOrgModalToggle}
                />
            )}
            <CustomerBreadCrumbs org={org} />
            <div className="row mt-3">
                <div className="col-2">
                    <input
                        id="company-search"
                        className="form form-control"
                        type="text"
                        placeholder="Search Companies"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.currentTarget.value)}
                    />
                </div>
                <div className="col">
                    {!inStagingOrg && (
                        <button
                            type="button"
                            id="org-users"
                            className="btn btn-sm btn-primary h-100"
                            onClick={handleOrgUsersClick}>
                            View Organization Users
                        </button>
                    )}
                    {!userHasCustomerSupportRole && !inStagingOrg && (
                        <button
                            type="button"
                            id="org-create-company"
                            className="btn btn-sm btn-primary h-100 ms-3"
                            onClick={() => setShowModal(!showModal)}>
                            Create New Location
                        </button>
                    )}
                </div>
            </div>
            <div className="mt-3">
                <Table
                    tableId="org-companies-table"
                    tableClassName="mt-3"
                    isTableRowsClickable={true}
                    columnDetails={tableColumnDetails}
                    data={displayCompanies}
                    sortClickCallback={sortClickCallback}
                    rowClickCallback={handleRowClick}
                />
            </div>
        </div>
    );
};

export default OrganizationCompanies;
