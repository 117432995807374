import React, { useContext, useRef } from 'react';
import Table from 'components/Shared/Table/Table';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

import useTableActions from './useTableActions';
import tableConfiguration from './tableConfiguration';
import useVehicles from './useVehicles';

const VehicleList = ({ oemId, modelId, showBooks = true }) => {
    const confirmRef = useRef();

    const { notifications } = useContext(NotificationsContext);
    const { data, setData } = useVehicles(oemId, modelId, showBooks, notifications);

    const headers = tableConfiguration;
    const actions = useTableActions(setData, notifications, confirmRef);

    return (
        <div>
            <Confirmation ref={confirmRef} />
            <Table idKey="id" headers={headers} data={data} cellEventCallbacks={actions} />
        </div>
    );
};

export default VehicleList;
