import ReactModal from 'components/Shared/ReactModal';
import React from 'react';
import BulkMoveBody from './BulkMoveBody';
import BulkMoveFooter from './BulkMoveFooter';
import useBulkMoveModal from './useBulkMoveModal';

const BulkMoveModal = ({ isOpen, statusModalProcedureIds, resetProcedureIds, procedures, resetBulkSelection }) => {
    const { handleModalToggle, selectedStatusId, setSelectedStatusId, handleBulkChangeStatusProcedures } =
        useBulkMoveModal(statusModalProcedureIds, resetProcedureIds, procedures, resetBulkSelection);

    return (
        <ReactModal
            id="bulk-move-modal"
            headerComponent={<div style={{ fontSize: '1rem', lineHeight: 'normal' }}>Bulk Move</div>}
            isOpen={isOpen}
            toggle={handleModalToggle}
            bodyComponent={
                <BulkMoveBody
                    statusModalProcedureIds={statusModalProcedureIds}
                    selectedStatusId={selectedStatusId}
                    setSelectedStatusId={setSelectedStatusId}
                />
            }
            footerComponent={
                <BulkMoveFooter
                    handleModalToggle={handleModalToggle}
                    handleBulkChangeStatusProcedures={handleBulkChangeStatusProcedures}
                />
            }
        />
    );
};

export default BulkMoveModal;
