import ReactModal from 'components/Shared/ReactModal';
import { LightButton, PrimaryButton } from 'oemiq-common';
import BulkTaskHistoryModalBody from './BulkTaskHistoryModalBody';
import useBulkTaskHistoryModal from './useBulkTaskHistoryModal';
import { useMemo } from 'react';

const BulkTaskHistoryModal = ({ isOpen, onToggle }) => {
    const top = useMemo(() => 15, []);
    const { isLoading, historyEntries, handleToggle, handleCloseButtonClick, handleRefreshButtonClick } =
        useBulkTaskHistoryModal(isOpen, onToggle, top);

    return (
        <ReactModal
            id="bulk-action-history-modal"
            headerComponent={
                <div>
                    Bulk Task History <small className="ms-3">(last {top} entries loaded)</small>
                </div>
            }
            isOpen={isOpen}
            toggle={handleToggle}
            bodyComponent={<BulkTaskHistoryModalBody isLoading={isLoading} items={historyEntries} />}
            footerComponent={
                <div>
                    <PrimaryButton
                        id="bulk-action-history-modal-refresh-button"
                        type="button"
                        className="me-3"
                        onClick={handleRefreshButtonClick}>
                        Refresh
                    </PrimaryButton>
                    <LightButton
                        id="bulk-action-history-modal-close-button"
                        type="button"
                        onClick={handleCloseButtonClick}>
                        Close
                    </LightButton>
                </div>
            }
        />
    );
};

export default BulkTaskHistoryModal;
