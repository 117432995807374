import React, { useState } from 'react';

import SearchBar from 'components/Shared/SearchBar/SearchBar';

interface IngestionManagerSearchProps {
    onSearch: (filter: string) => void;
}

const IngestionManagerSearch = ({ onSearch }: IngestionManagerSearchProps) => {
    const [searchValue, setSearchValue] = useState<string>('');

    const handleSearchKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
        event.key === 'Enter' && event.currentTarget.blur();

    return (
        <SearchBar
            value={searchValue}
            onChange={setSearchValue}
            onBlur={() => onSearch(searchValue)}
            onKeyDown={e => handleSearchKeyPress(e)}
        />
    );
};

export default IngestionManagerSearch;
