import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import useOemService from 'hooks/OemModels/useOemService';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

const useProcedures = (filterBy, orderBy, oemid) => {
    const [data, setData] = useState([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState({ error: false, value: 0, loadingCount: 0 });
    const { oemService } = useOemService(oemid);
    const page = useRef(0);
    const pageSize = 100;
    const { notifications } = useContext(NotificationsContext);

    useEffect(() => {
        setData([]);
        setHasMoreData(true);
        page.current = 0;
    }, [filterBy, orderBy]);

    useEffect(() => {
        setTotalCount(c => {
            return {
                error: false,
                value: c.value,
                loadingCount: true,
            };
        });
        oemService
            .countProcedures(filterBy)
            .then(count =>
                setTotalCount({
                    error: false,
                    value: count,
                    loadingCount: false,
                })
            )
            .catch(() =>
                setTotalCount({
                    error: true,
                    value: undefined,
                    loadingCount: false,
                })
            );
    }, [filterBy, oemService]);

    const loadMoreCallback = useCallback(async () => {
        setLoading(true);
        try {
            const oDataResponse = await oemService.getProcedures(filterBy, orderBy, pageSize, pageSize * page.current);
            const data = oDataResponse.value;
            setData(d => [...d, ...data]);
            setHasMoreData(data.length === pageSize);
            page.current++;
        } catch (error) {
            setData([]);
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    }, [oemService, filterBy, orderBy, notifications]);

    return { data, hasMoreData, loading, totalCount, loadMoreCallback };
};

export default useProcedures;
