import { PROPERTY_TYPE } from './PropertyType';
import { buildProcedureProperty } from './ProcedureProperty';
import { FilterProperty } from './BuildOemMetadata';
import { MappingRuleId } from 'components/Shared/TableFilters/Types';
import { Group } from 'api/RepairProcedures/types';

export const COMMON_PROPERTIES = [
    buildProcedureProperty({
        name: 'rpProcedureId',
        displayName: 'Rp Procedure Id',
        type: PROPERTY_TYPE.historyView,
    }),
    buildProcedureProperty({
        id: 'firstBookId',
        name: 'rpProcedure.booksForProcedure.0.book.bookId',
        displayName: 'Book Id',
        type: PROPERTY_TYPE.bookId,
    }),
    buildProcedureProperty({
        id: 'firstBookName',
        name: 'rpProcedure.booksForProcedure.0.book.bookName',
        displayName: 'Book name',
        type: PROPERTY_TYPE.bookName,
    }),
    buildProcedureProperty({
        name: 'rpProcedure.procedureTitle',
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.procedureView,
    }),
    buildProcedureProperty<Group[]>({
        name: 'rpProcedure.stageArea.groups',
        displayName: 'Group Ids',
        multiple: true,
        type: PROPERTY_TYPE.groupsIds,
    }),
    buildProcedureProperty<Group[]>({
        id: 'groupsView',
        name: 'rpProcedure.stageArea.groups',
        displayName: 'Groups',
        type: PROPERTY_TYPE.groupView,
    }),
    buildProcedureProperty({
        id: 'TypeId',
        name: 'rpProcedure.stageArea.type.typeId',
        displayName: 'Type Id',
        type: PROPERTY_TYPE.typeId,
    }),
    buildProcedureProperty({
        id: 'TypeName',
        name: 'rpProcedure.stageArea.type.typeId',
        displayName: 'Type Name',
        type: PROPERTY_TYPE.typeName,
    }),
];

export const COMMON_FILTERS: FilterProperty[] = [
    {
        id: 'mappingRuleId',
        label: 'Mapping Rule Id',
        allowInstances: 10,
        property: 'rpProcedure',
        ruleable: false,
        type: MappingRuleId,
    },
];
