import React from 'react';
import GroupTypeBodyGroups from './GroupTypeBodyGroups';
import GroupTypeBodyType from './GroupTypeBodyType';

const GroupTypeBody = ({
    groupTypeModalProcedureIds,
    handleRegionClick,
    selectedTypeIdWithStatusId,
    handleTypeStatusSelection,
    typeSelectionOptions,
    selectedGroupIdsWithStatusId,
}) => {
    return (
        <div>
            <GroupTypeBodyGroups
                handleRegionClick={handleRegionClick}
                selectedGroupIdsWithStatusId={selectedGroupIdsWithStatusId}
            />

            <div className="mt-3">
                <GroupTypeBodyType
                    selectedTypeIdWithStatusId={selectedTypeIdWithStatusId}
                    handleTypeStatusSelection={handleTypeStatusSelection}
                    typeSelectionOptions={typeSelectionOptions}
                />
            </div>

            <div className="mt-3">{groupTypeModalProcedureIds.length} PROCEDURES SELECTED</div>
        </div>
    );
};

export default GroupTypeBody;
