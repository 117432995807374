import React, { useCallback, useContext } from 'react';
import ReactSelect from 'react-select';
import { Input } from 'oemiq-common';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BasicViewValueComponent = ({ value, column }) => {
    if (column.multiple) {
        return (
            value &&
            (Array.isArray(value) ? value : value.split(',')).map(column.valueProvider).map(s => (
                <span className="badge text-bg-primary me-1" key={s}>
                    {s}
                </span>
            ))
        );
    }

    return <span>{column.valueProvider(value)}</span>;
};

export const BasicEditValueComponent = ({ value, property, onChange, ...props }) => {
    const handleChange = useCallback(e => onChange(e.target.value), [onChange]);

    return <Input type={property.type.type} onChange={handleChange} value={value} {...props} />;
};

export const BoolEditValueComponent = ({ value, onChange, ...props }) => {
    return (
        <ReactSelect
            autosize={false}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            value={value}
            isClearable={true}
            options={[
                { value: true, label: 'true' },
                { value: false, label: 'false' },
            ]}
            onChange={onChange}
            {...props}
        />
    );
};

export const ProcedureDisplayViewValueComponent = ({ value, row, onClick }) => {
    return (
        <a onClick={() => onClick(row.rpProcedureId)} className="link-primary" style={{ cursor: 'pointer' }}>
            {value}
        </a>
    );
};

export const ProcedureIdAndHistoryComponent = ({ value, row, onHistoryClick }) => {
    return (
        <>
            <div>
                {value}{' '}
                <FontAwesomeIcon
                    className="clickable"
                    icon="history"
                    onClick={() =>
                        onHistoryClick({
                            procedureId: row.rpProcedureId,
                            procedureTitle: row.rpProcedure.procedureTitle,
                        })
                    }
                />
            </div>
        </>
    );
};

export const GroupDisplayViewValueComponent = ({ row }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);
    const groups = row.rpProcedure?.stageArea.groups;

    return (
        mappingDefinitions?.groups?.length &&
        groups.map(group => (
            <span className="badge text-bg-primary me-1" key={group.groupId}>
                {mappingDefinitions.groups.find(g => g.value === group.groupId)?.label ?? group.groupId}
                <span className="badge text-bg-light rounded-pill ms-1">{group.mappingRuleId ?? 'M'}</span>
            </span>
        ))
    );
};

export const GroupIdDisplayViewValueComponent = ({ row }) => {
    const groups = row.rpProcedure?.stageArea.groups;

    return groups.map(group => (
        <span className="badge text-bg-primary me-1" key={group.groupId}>
            {group.groupId}
        </span>
    ));
};

export const GroupEditViewValueComponent = ({ value, onChange }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        mappingDefinitions?.groups?.length && (
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                placeholder="Select Available Groups"
                isMulti={true}
                options={mappingDefinitions.groups}
                value={value}
                onChange={onChange}
            />
        )
    );
};

export const GroupIdEditViewValueComponent = ({ value, onChange }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        mappingDefinitions?.groups?.length && (
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                placeholder="Select Available Groups"
                isMulti={true}
                options={mappingDefinitions.groups}
                value={value}
                getOptionLabel={v => v.value}
                onChange={onChange}
            />
        )
    );
};

export const TypeNameViewValueComponent = ({ value, row }) => {
    const { mappingRuleId } = row.rpProcedure.stageArea.type;
    const mappingDefinitions = useContext(MappingDefinitionsContext);
    if (!mappingDefinitions.groups || !mappingDefinitions.groups.length) return;
    const oemiqType = mappingDefinitions.types.find(type => type.value === value);
    return (
        value && (
            <span className="badge text-bg-primary me-1" key={value}>
                {oemiqType && oemiqType.text}
                <span className="badge text-bg-light rounded-pill ms-1">{mappingRuleId ?? 'M'}</span>
            </span>
        )
    );
};

export const TypeIdViewValueComponent = ({ value }) => {
    return (
        <span className="badge text-bg-primary me-1" key={value}>
            {value}
        </span>
    );
};
