import React from 'react';
import InfiniteScrollTableRow from 'components/InfiniteScrollTableRow';
import { OemProceduresHeaderCell } from './items/OemProceduresHeaderCell';

const OemProceduresTable = ({
    data,
    hasMoreData,
    oemMetadata,
    style,
    columnSettings,
    isLoading,
    onSorting,
    loadMoreCallback,
    onClickModalProcedureId,
    onclickHistoryModalProcedure,
}) => {
    const visibleColumns = columnSettings
        .filter(c => !c.isHidden)
        .map(cs => oemMetadata.properties.find(dt => dt.id === cs.id));

    return (
        <div id="procedures-table" className="overflow-auto flex-grow-1" style={style}>
            <table className="table mt-3">
                <thead className="sticky-top bg-white">
                    <tr>
                        {visibleColumns.map(column => (
                            <OemProceduresHeaderCell key={column.id} element={column} onSorting={onSorting} />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((row, i) => (
                            <tr key={i}>
                                {visibleColumns.map(column => {
                                    let value = column.name.split('.').reduce((o, i) => (o ? o[i] : null), row);
                                    return (
                                        <td key={column.id}>
                                            <column.type.viewComponent
                                                value={value}
                                                row={row}
                                                column={column}
                                                onClick={onClickModalProcedureId}
                                                onHistoryClick={onclickHistoryModalProcedure}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                </tbody>
                <tfoot>
                    {hasMoreData && (
                        <InfiniteScrollTableRow
                            columnsCount={columnSettings.length}
                            isLoading={isLoading}
                            loadMoreCallback={loadMoreCallback}
                        />
                    )}
                </tfoot>
            </table>
        </div>
    );
};

export default OemProceduresTable;
