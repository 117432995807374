import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { OemId } from 'helpers/OemId';

const redirectOems = [
    { from: OemId.Lexus, to: OemId.Toyota },
    { from: OemId.Acura, to: OemId.Honda },
    { from: OemId.Infiniti, to: OemId.Nissan },
    { from: OemId.RAM, to: OemId.Chrysler },
    { from: OemId.Fiat, to: OemId.Chrysler },
    { from: OemId.Dodge, to: OemId.Chrysler },
    { from: OemId.Jeep, to: OemId.Chrysler },
    { from: OemId.AlfaRomeo, to: OemId.Chrysler },
    { from: OemId.Audi, to: OemId.Volkswagen },
];

const redirectOemRoute = (matchedPath: string, absolutePath: string) => {
    return (
        <>
            {redirectOems.map((redirectOem, i) => (
                <Route
                    key={i}
                    path={`${matchedPath}${redirectOem.from}`}
                    element={<Navigate replace to={`${absolutePath}${redirectOem.to}`} />}
                />
            ))}
        </>
    );
};

export default redirectOemRoute;
