import React from 'react';
import { Select } from 'oemiq-common';
import { useCallback } from 'react';

const selectOptions = [
    { text: 'In Review', value: 1 },
    { text: 'Complete', value: 2 },
    { text: 'Needs Help', value: 3 },
];
const defaultOption = { text: 'Select Status', value: -1 };

const BulkMoveBody = ({ statusModalProcedureIds, setSelectedStatusId, selectedStatusId }) => {
    const handleChange = useCallback(e => setSelectedStatusId(e.currentTarget.value), [setSelectedStatusId]);

    return (
        <div>
            <div>
                <h6>Status</h6>
                <Select
                    id="status-selection"
                    value={selectedStatusId}
                    className="form-select"
                    onChange={handleChange}
                    options={selectOptions}
                    defaultOption={defaultOption}
                />
            </div>
            <div className="mt-3">{statusModalProcedureIds.length} PROCEDURES SELECTED</div>
        </div>
    );
};

export default BulkMoveBody;
