export interface Procedure {
    // TODO add other data fields (listed below)
    // procedureId, oemProcedureId, procedureVersion, procedureTitle, procedureTypeId,
    // createDate, updateDate, procedureDetails(versionSignificance, isLatest, isPublished)
    stageArea: {
        groups: [
            {
                groupId: number;
                mappingStatusId: number;
                mappingRuleId?: number;
            }
        ];
        type: {
            typeId?: number;
            mappingStatusId?: number;
            mappingRuleId?: number;
        };
    };
    isDeleted: boolean;
}

export enum MappingWorkflowStatus {
    InReview = 1,
    Completed = 2,
    NeedHelp = 3,
}
