import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import NavigationTile from 'components/Shared/Components/NavigationTile';
import React, { useContext } from 'react';

const ToolSelection = ({ tools }) => {
    const { hasAccess } = useContext(AccessControlContext);

    return (
        <div className="container mt-4">
            <div className="row">
                {tools.map((t, i) => (
                    <NavigationTile
                        key={i}
                        path={t.path}
                        text={t.text}
                        icon={t.icon}
                        tileDescription={t.tileDescription}
                        display={hasAccess(t.access)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ToolSelection;
