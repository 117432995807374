//Adding font awesome icons to be used in app. This allows them to be referenced through props
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import {
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faAward,
    faBars,
    faBoxesStacked,
    faCalculator,
    faCar,
    faCarSide,
    faCheck,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClipboard,
    faClock,
    faDolly,
    faEdit,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileImport,
    faFilePdf,
    faFillDrip,
    faFilter,
    faFishFins,
    faFlag,
    faGripVertical,
    faHistory,
    faInfoCircle,
    faLink,
    faLongArrowAltRight,
    faMap,
    faPalette,
    faPaperPlane,
    faPencilAlt,
    faPersonRunning,
    faProjectDiagram,
    faRefresh,
    faRepeat,
    faRocket,
    faScroll,
    faSearch,
    faSink,
    faSitemap,
    faSnowplow,
    faSort,
    faSortDown,
    faSortUp,
    faSquareCheck,
    faSquareMinus,
    faSquarePlus,
    faStickyNote,
    faTags,
    faTextWidth,
    faThumbTack,
    faThumbsDown,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashRestore,
    faUserFriends,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';

//Summary: Used to move the imports of all the icons out of the App.jsx directly.
//Should only be called at highest level of component tree to ensure icons are available everywhere
const useFontAwesomeIcons = () => {
    library.add(
        faArrowUpRightFromSquare,
        faArrowsRotate,
        faAward,
        faBars,
        faBoxesStacked,
        faCalculator,
        faCar,
        faCarSide,
        faCheck,
        faChevronCircleLeft,
        faChevronCircleRight,
        faChevronDown,
        faChevronLeft,
        faChevronRight,
        faChevronUp,
        faClipboard,
        faClock,
        faDolly,
        faEdit,
        faExternalLinkAlt,
        faEye,
        faEyeSlash,
        faFileImport,
        faFilePdf,
        faFillDrip,
        faFilter,
        faFishFins,
        faFlag,
        faGripVertical,
        faHistory,
        faInfoCircle,
        faLink,
        faLongArrowAltRight,
        faMap,
        faPalette,
        faPaperPlane,
        faPencilAlt,
        faPersonRunning,
        faProjectDiagram,
        faRefresh,
        faRepeat,
        faRocket,
        faScroll,
        faSearch,
        faSink,
        faSitemap,
        faSnowplow,
        faSort,
        faSortDown,
        faSortUp,
        faSquare,
        faSquareCheck,
        faSquareMinus,
        faSquarePlus,
        faStickyNote,
        faTags,
        faTextWidth,
        faThumbTack,
        faThumbsDown,
        faThumbsUp,
        faTimes,
        faTimesCircle,
        faTrash,
        faTrashRestore,
        faUserFriends,
        faWrench
    );

    return {};
};

export default useFontAwesomeIcons;
