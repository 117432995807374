import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { colors, validatePath, getValidIds, getCssPath } from 'helpers/TaggerHelper';
import { requestCreateOneTimeUseTag, requestGetTagById } from 'api/RepairProcedureApi';
import { ToastContext } from 'components/ToastProvider';
import { LoadingContext } from 'components/Layout';
import { isEqual } from 'lodash';
import { FormatCurrency } from 'helpers/CurrencyHelper';
import currency from 'currency.js';
import { OneTimeUseFlag } from '../../../types';

const useOneTimeUseFlagConvert = (
    flag: OneTimeUseFlag,
    setIsConvertFlag: (isConvert: boolean) => void,
    setFlags: (flags) => void,
    setTags: (tags) => void,
    quantityConditions
) => {
    const init = {
        title: '',
        quantity: flag.quantity?.toString() ?? '',
        quantityCondition: flag.quantityCondition ?? 'none',
        partNumber: flag.partNumber ?? '',
        price: flag.price ? FormatCurrency(flag.price, 2) : 0,
        partTypeId: flag.partType ? flag.partType.oneTimeUsePartTypeId.toString() : '3',
        text: flag.text ?? '',
        note: flag.note ?? '',
        oneTimeUseFlagImages: flag.oneTimeUseFlagImage ? [flag.oneTimeUseFlagImage.imageUrl] : [],
        highlightStartPath: '',
        highlightEndPath: '',
    };

    const [title, setTitle] = useState<string>(init.title);
    const [quantity, setQuantity] = useState<string>(init.quantity);
    const [quantityCondition, setQuantityCondition] = useState<string>(init.quantityCondition);
    const [partNumber, setPartNumber] = useState<string>(init.partNumber);
    const [price, setPrice] = useState<number | currency>(init.price);
    const [partTypeId, setPartTypeId] = useState<string>(init.partTypeId);
    const [text, setText] = useState<string>(init.text);
    const [note, setNote] = useState<string>(init.note);
    const [selectedImages, setSelectedImages] = useState(init.oneTimeUseFlagImages);
    const [highlightStartPath, setHighlightStartPath] = useState<string>(init.highlightStartPath);
    const [highlightEndPath, setHighlightEndPath] = useState<string>(init.highlightEndPath);
    const [isSelectingImg, setIsSelectingImage] = useState<boolean>(false);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const isChanged =
        init.title !== title ||
        init.quantity !== quantity ||
        init.quantityCondition !== quantityCondition ||
        init.partNumber !== partNumber ||
        init.price !== price ||
        init.partTypeId !== partTypeId ||
        init.text !== text ||
        init.note !== note ||
        !isEqual(init.oneTimeUseFlagImages, selectedImages) ||
        init.highlightStartPath !== highlightStartPath ||
        init.highlightEndPath !== highlightEndPath;
    const isTitleBlank = !title;

    const calcConditions = useMemo(
        () =>
            quantityConditions.some(c => c.value === flag.quantityCondition)
                ? quantityConditions
                : [...quantityConditions, { value: flag.quantityCondition, label: flag.quantityCondition }],
        [flag, quantityConditions]
    );

    const handleDocumentClick = useCallback(
        event => {
            if (isSelectingImg) {
                const currentTarget = event.target;
                if (currentTarget.tagName === 'IMG') {
                    const currentImages = [...selectedImages];
                    currentImages.push(currentTarget.attributes.src.value);
                    setSelectedImages(currentImages);

                    setIsSelectingImage(false);
                }
                if (currentTarget.tagName === 'DIV' && currentTarget.classList.contains('object-wrapper')) {
                    const currentImages = [...selectedImages];
                    const imageObjects = currentTarget.querySelectorAll('object');
                    const src = imageObjects[0].data;
                    currentImages.push(src);
                    setSelectedImages(currentImages);

                    setIsSelectingImage(false);
                }
            }
        },
        [isSelectingImg, selectedImages, setSelectedImages]
    );

    useEffect(() => {
        const objects = document.querySelectorAll('object.svg-image');
        for (let i = 0; i < objects.length; i++) {
            const wrapper = document.createElement('div');
            objects[i].parentNode.insertBefore(wrapper, objects[i]);
            objects[i].classList.add('pe-none');
            wrapper.appendChild(objects[i]);
            wrapper.classList.add('object-wrapper');
        }
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isSelectingImg, handleDocumentClick]);

    const handleRemoveImageClick = img => {
        const newSelectedImages = selectedImages.filter(i => i !== img);
        setSelectedImages(newSelectedImages);
    };

    const clearHighlight = () => {
        const highlight = document.getElementById('highlight');
        highlight.style.left = '0';
        highlight.style.top = '0';
        highlight.style.width = '0';
        highlight.style.height = '0';
    };

    const handleRevertRegion = () => {
        clearHighlight();
        setHighlightStartPath('');
        setHighlightEndPath('');
    };

    const handleSelectTagRegion = () => {
        const selection = document.getSelection();
        if (!selection) return;

        const range = selection.getRangeAt(0);

        const start = getCssPath(range.startContainer.parentNode);
        const end = start.includes('li') || start.includes('td') ? start : getCssPath(range.endContainer.parentNode);

        const actualRange = document.createRange();
        actualRange.setStartBefore(document.querySelector(start));
        actualRange.setEndAfter(document.querySelector(end));

        const validIds = getValidIds(flag.procedureId);
        const validPath = validatePath(start, validIds) && validatePath(end, validIds);
        const clientRect = actualRange.getBoundingClientRect();
        const highlight = document.getElementById('highlight');
        const spacing = 3;
        highlight.style.pointerEvents = 'none';
        highlight.style.left = `${clientRect.x + window.scrollX - spacing}px`;
        highlight.style.top = `${clientRect.y + window.scrollY - spacing}px`;
        // prettier-ignore
        highlight.style.width = `${clientRect.width + (2 * spacing)}px`;
        // prettier-ignore
        highlight.style.height = `${clientRect.height + (2 * spacing)}px`;

        if (!validPath) highlight.style.background = '#F44336';
        else highlight.style.background = '#4CAF50';

        setHighlightStartPath(start);
        setHighlightEndPath(end);

        // Update Flag Text
        setText(selection.toString());
    };

    const convertFlagToTag = async () => {
        const tagColor = colors[Math.floor(Math.random() * colors.length)];
        const createOneTimeUseTagCommand = [
            {
                oneTimeUseFlagId: flag.oneTimeUseFlagId,
                procedureId: flag.procedureId,
                title: title,
                quantity: parseFloat(quantity),
                quantityCondition: quantityCondition,
                partNumber: partNumber,
                price: price,
                text: text,
                colorHex: tagColor,
                note: note,
                workFlowStatusid: 1,
                oneTimeUsePartTypeid: parseInt(partTypeId),
                startPath:
                    highlightStartPath === '' && flag.oneTimeUseFlagElement
                        ? flag.oneTimeUseFlagElement.contentStart
                        : highlightStartPath,
                endPath:
                    highlightEndPath === '' && flag.oneTimeUseFlagElement
                        ? flag.oneTimeUseFlagElement.contentEnd
                        : highlightEndPath,
                tagImages: selectedImages,
            },
        ];

        try {
            incrementLoading();
            const newTagIds = await requestCreateOneTimeUseTag(createOneTimeUseTagCommand);
            if (newTagIds.length) {
                // we only created one staged tag
                const id = newTagIds[0];
                const newTag = await requestGetTagById(id);
                setTags(prev => [...prev, newTag]);
                setFlags(prev => prev.filter(f => f.oneTimeUseFlagId !== newTag.oneTimeUseFlagId));
            }

            setIsConvertFlag(false);
            clearHighlight();
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    return {
        title,
        setTitle,
        quantity,
        setQuantity,
        quantityCondition,
        setQuantityCondition,
        partNumber,
        setPartNumber,
        price,
        setPrice,
        partTypeId,
        setPartTypeId,
        isSelectingImg,
        setIsSelectingImage,
        selectedImages,
        text,
        setText,
        note,
        setNote,
        handleRemoveImageClick,
        handleSelectTagRegion,
        handleRevertRegion,
        convertFlagToTag,
        isTitleBlank,
        isChanged,
        calcConditions,
    };
};

export default useOneTimeUseFlagConvert;
