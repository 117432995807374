import { useState, useEffect, useContext } from 'react';
import {
    requestMapProceduresToType,
    requestMapProcedureGroupApi,
    requestRemoveMappingApi,
    requestUpdateRegionStatusApi,
    requestUnmapType,
} from 'api/RepairProcedureApi';
import useMappingWorkflowStatus from 'hooks/useMappingWorkflowStatus';
import { LoadingContext } from 'components/Layout';
import { MapperListContext } from 'contexts/MapperListContext';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';

const useGroupTypeModal = (
    groupTypeModalProcedureIds,
    resetProcedureIds,
    setNewGroupListToProcedureByProcedureId,
    procedures,
    resetBulkSelection,
    groupTypeModalBulkType,
    setGroupTypeModalBulkType
) => {
    const [selectedGroupIdsWithStatusId, setSelectedGroupIdsWithStatusId] = useState([]);
    const [selectedTypeIdWithStatusId, setSelectedTypeIdWithStatusId] = useState(null);
    const [typeSelectionOptions, setTypeSelectionOptions] = useState([]);
    const { getMappingWorkFlowStatusById } = useMappingWorkflowStatus();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { types } = useContext(MappingDefinitionsContext);
    const { refreshProcedures, updateProcedures, updateOemIqTypeForProcedureIds } = useContext(MapperListContext);
    const { hasRole } = useContext(AccessControlContext);

    useEffect(() => {
        if (groupTypeModalProcedureIds.length === 1) {
            const procedure = procedures.find(p => p.procedureId === groupTypeModalProcedureIds[0]);
            if (!procedure) return;

            const procGroupIdsWithStatusIds = procedure.stageArea.groups.map(group => ({
                groupId: group.groupId,
                statusId: group.mappingStatusId,
            }));
            const procedureType = procedure.stageArea.type;
            const procTypeIdWithStatusId =
                procedureType.typeId === null
                    ? null
                    : { typeId: procedureType.typeId, statusId: procedureType.mappingStatusId };
            setSelectedGroupIdsWithStatusId(procGroupIdsWithStatusIds);
            setSelectedTypeIdWithStatusId(procTypeIdWithStatusId);
        }
    }, [procedures, groupTypeModalProcedureIds]);

    useEffect(() => {
        let typeOptions = [];
        types.map(o => {
            let option = { value: o.oemIqSectionId, text: o.oemIqSectionName };
            return typeOptions.push(option);
        });
        setTypeSelectionOptions(typeOptions);
    }, [types]);

    const handleModalToggle = () => {
        resetProcedureIds();
        setSelectedGroupIdsWithStatusId([]);
        setSelectedTypeIdWithStatusId(null);
        setGroupTypeModalBulkType(null);
    };

    const handleRegionClick = async groupId => {
        setSelectedGroupIdsWithStatusId(currState => {
            let newState = [...currState];
            let index = newState.findIndex(n => n.groupId === groupId);

            if (groupTypeModalBulkType && groupTypeModalBulkType === 'Remove') {
                if (index < 0) newState.push({ groupId: groupId, statusId: 100 });
                else newState = newState.filter(n => n.groupId !== groupId);
            } else {
                if (index < 0) newState.push({ groupId: groupId, statusId: 1 });
                else if (newState[index].statusId === 1) newState[index].statusId = 2;
                else if (newState[index].statusId === 2) newState[index].statusId = 3;
                else newState = newState.filter(n => n.groupId !== groupId);
            }

            return newState;
        });
    };

    const handleTypeStatusSelection = typeId => {
        if (groupTypeModalBulkType && groupTypeModalBulkType === 'Remove') {
            if (selectedTypeIdWithStatusId && selectedTypeIdWithStatusId.typeId === typeId) {
                if (selectedTypeIdWithStatusId.statusId === 100) setSelectedTypeIdWithStatusId(null);
            } else setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 100 });
        } else if (selectedTypeIdWithStatusId && selectedTypeIdWithStatusId.typeId === typeId) {
            if (selectedTypeIdWithStatusId.statusId === 1 && hasRole(roles.admin, roles.siteAdmin, roles.dataSME))
                setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 2 });
            else if (selectedTypeIdWithStatusId.statusId === 1)
                setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 3 });
            else if (selectedTypeIdWithStatusId.statusId === 2)
                setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 3 });
            else if (selectedTypeIdWithStatusId.statusId === 3) setSelectedTypeIdWithStatusId(null);
        } else setSelectedTypeIdWithStatusId({ typeId: typeId, statusId: 1 });
    };

    const handleBulkMap = async () => {
        let updatedProcedures = [...procedures];

        //Bulk Map Groups
        if (selectedGroupIdsWithStatusId.length > 0) {
            let selectedGroupIds = selectedGroupIdsWithStatusId.map(n => n.groupId);
            let completeGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 2).map(n => n.groupId);
            let needsHelpGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 3).map(n => n.groupId);
            await requestMapProcedureGroupApi(groupTypeModalProcedureIds, selectedGroupIds);

            if (completeGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, completeGroupIds, 2);
            if (needsHelpGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, needsHelpGroupIds, 3);

            groupTypeModalProcedureIds.forEach(procedureId => {
                const procedure = updatedProcedures.find(pr => pr.procedureId === procedureId);
                let newGroups = [...procedure.stageArea.groups];
                selectedGroupIdsWithStatusId.forEach(selectedGroup => {
                    if (!newGroups.find(group => group.groupId === selectedGroup.groupId)) {
                        newGroups.push({
                            groupId: selectedGroup.groupId,
                            mappingStatusId: selectedGroup.statusId,
                            mappingRuleId: null,
                        });
                    }
                });
                procedure.stageArea.groups = newGroups;
            });
        }

        //Bulk Map Type
        if (selectedTypeIdWithStatusId !== null) {
            await requestMapProceduresToType(
                parseInt(selectedTypeIdWithStatusId.typeId),
                groupTypeModalProcedureIds,
                selectedTypeIdWithStatusId.statusId
            );
            const proceduresToUpdate = updatedProcedures.filter(procedure =>
                groupTypeModalProcedureIds.includes(procedure.procedureId)
            );
            proceduresToUpdate.forEach(
                procedure =>
                    (procedure.stageArea.type = {
                        typeId: selectedTypeIdWithStatusId.typeId, // parseInt()
                        mappingStatusId: selectedTypeIdWithStatusId.statusId,
                        mappingRuleId: null,
                    })
            );
        }

        updateProcedures(updatedProcedures); // merged
    };

    const handleBulkRemove = async () => {
        const removeGroupIdList = selectedGroupIdsWithStatusId.filter(n => n.statusId === 100).map(n => n.groupId);
        let updatedProcedures = [...procedures];

        //Bulk Remove Groups
        if (removeGroupIdList.length > 0) {
            await requestRemoveMappingApi(groupTypeModalProcedureIds, removeGroupIdList);
            groupTypeModalProcedureIds.forEach(procedureId => {
                const procedure = updatedProcedures.find(up => up.procedureId === procedureId);
                procedure.stageArea.groups = procedure.stageArea.groups.filter(
                    group => !removeGroupIdList.includes(group.groupId)
                );
            });
        }

        //Bulk Remove Type
        if (selectedTypeIdWithStatusId !== null) {
            const { typeId } = selectedTypeIdWithStatusId;
            if (typeId) {
                await requestUnmapType(groupTypeModalProcedureIds, typeId);

                const proceduresToUpdate = updatedProcedures.filter(procedure =>
                    groupTypeModalProcedureIds.includes(procedure.procedureId)
                );
                proceduresToUpdate.forEach(
                    procedure =>
                        (procedure.stageArea.type = {
                            typeId: null,
                            mappingStatusId: null,
                            mappingRuleId: null,
                        })
                );
            }
        }

        updateProcedures(updatedProcedures);
    };

    const handleMapProcedures = async () => {
        incrementLoading();

        if (groupTypeModalBulkType && groupTypeModalBulkType === 'Remove') {
            await handleBulkRemove();
        } else if (groupTypeModalBulkType && groupTypeModalBulkType === 'Assign') {
            await handleBulkMap();
        } else {
            await handleMapProcedureSingle();
            await handleMapTypeSingle();
        }

        resetProcedureIds();
        setSelectedGroupIdsWithStatusId([]);
        resetBulkSelection();
        setSelectedTypeIdWithStatusId(null);
        setGroupTypeModalBulkType(null);
        refreshProcedures(); //TODO: when this temp solution is removed, confirm that react state updating is still working as this line removes the need for it currently
        decrementLoading();
    };

    const handleMapTypeSingle = async () => {
        if (selectedTypeIdWithStatusId !== null) {
            await requestMapProceduresToType(
                parseInt(selectedTypeIdWithStatusId.typeId),
                groupTypeModalProcedureIds,
                selectedTypeIdWithStatusId.statusId
            );
            updateOemIqTypeForProcedureIds(
                types.filter(o => o.oemIqSectionId === parseInt(selectedTypeIdWithStatusId.typeId))[0],
                groupTypeModalProcedureIds,
                selectedTypeIdWithStatusId.statusId
            );
        } else {
            await requestUnmapType(groupTypeModalProcedureIds);
            updateOemIqTypeForProcedureIds(null, groupTypeModalProcedureIds, null);
        }
    };

    const handleMapProcedureSingle = async () => {
        const procId = groupTypeModalProcedureIds[0];
        let procGroupIds = procedures
            .find(pr => pr.procedureId === procId)
            .stageArea.groups.map(group => group.groupId);
        let removeGroupsList = [...procGroupIds];
        let addGroupIdsList = selectedGroupIdsWithStatusId
            .filter(selected => !procGroupIds.includes(selected.groupId))
            .map(group => group.groupId);
        let inReviewGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 1).map(n => n.groupId);
        let completeGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 2).map(n => n.groupId);
        let needsHelpGroupIds = selectedGroupIdsWithStatusId.filter(n => n.statusId === 3).map(n => n.groupId);
        let newGroupList = [];

        if (selectedGroupIdsWithStatusId.length > 0) {
            selectedGroupIdsWithStatusId.forEach(g => {
                removeGroupsList = removeGroupsList.filter(r => r !== g.groupId);
                newGroupList.push({
                    procedureId: procId,
                    mappingWorkFlowStatus: getMappingWorkFlowStatusById(g.statusId),
                    regionId: g.groupId,
                });
            });

            setNewGroupListToProcedureByProcedureId(newGroupList, procId);

            //Map the new group ids first, then set the new status accordingly
            if (addGroupIdsList.length > 0)
                await requestMapProcedureGroupApi(groupTypeModalProcedureIds, addGroupIdsList);
            if (inReviewGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, inReviewGroupIds, 1);
            if (completeGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, completeGroupIds, 2);
            if (needsHelpGroupIds.length > 0)
                await requestUpdateRegionStatusApi(groupTypeModalProcedureIds, needsHelpGroupIds, 3);
            if (removeGroupsList.length > 0)
                await requestRemoveMappingApi(groupTypeModalProcedureIds, removeGroupsList);
        } else {
            if (removeGroupsList.length > 0)
                await requestRemoveMappingApi(groupTypeModalProcedureIds, removeGroupsList);
            setNewGroupListToProcedureByProcedureId(newGroupList, procId);
        }
    };

    return {
        handleRegionClick,
        handleMapProcedures,
        handleModalToggle,
        selectedTypeIdWithStatusId,
        handleTypeStatusSelection,
        typeSelectionOptions,
        selectedGroupIdsWithStatusId,
    };
};

export default useGroupTypeModal;
