import { useCallback } from 'react';
import { requestVehiclePublish } from 'api/vehicleInfo';

const usePublishingConfirmation = ({ confirmRef, setData, notifications }) => {
    const setItemPublishing = useCallback(
        (value, index) => {
            setData(currentBooks => {
                currentBooks[index] = { ...currentBooks[index], publishing: value };
                return [...currentBooks];
            });
        },
        [setData]
    );

    const openConfirmation = useCallback(
        (e, publish, body) => {
            confirmRef.current.open(
                async () => {
                    try {
                        setItemPublishing(true, e.index);
                        await requestVehiclePublish(e.item.vehicleId, publish);
                        setData(current => {
                            current[e.index] = {
                                ...current[e.index],
                                published: publish,
                            };
                            return [...current];
                        });
                    } catch (e) {
                        notifications.pushExceptionDanger(e);
                    } finally {
                        const vehicleName = `${e.item.year.yearValue} ${e.item.model.modelName} - ${
                            e.item.trim.trimName !== null ? e.item.trim.trimName : 'Base'
                        }`;
                        notifications.pushSuccess(
                            publish ? `${vehicleName} published successfuly` : `${vehicleName} unpublished successfuly`
                        );
                        setItemPublishing(false, e.index);
                    }
                },
                { body: body }
            );
        },
        [confirmRef, setData, notifications, setItemPublishing]
    );
    return {
        openConfirmation,
    };
};

export default usePublishingConfirmation;
