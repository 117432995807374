import { useNavigate } from 'react-router-dom';
import { CUST_TOOLS, BREADCRUMB_DELIMITER } from './ManageCustomersConstants';

// todo: depreciate this
const CustomerBreadCrumbs = ({ org, company, users }) => {
    const navigate = useNavigate();
    return (
        <div className="mt-3 display-flex">
            <h5>
                {!org && <span>{CUST_TOOLS.orgTool.name}</span>}
                {org && (
                    <span
                        className="text-primary clickable"
                        onClick={() => navigate(`/${CUST_TOOLS.baseTool.route}/${CUST_TOOLS.orgTool.route}`)}>
                        {CUST_TOOLS.orgTool.name}
                    </span>
                )}
                {org && <span>{BREADCRUMB_DELIMITER}</span>}
                {org && !company && !users && (
                    <span>
                        {org.name} {CUST_TOOLS.compTool.name}
                    </span>
                )}
                {org && !company && users && (
                    <span>
                        {org.name} {CUST_TOOLS.compUserTool.name}
                    </span>
                )}
                {org && company && (
                    <span
                        className="text-primary clickable"
                        onClick={() => navigate(`${CUST_TOOLS.orgTool.Path(org.organizationId)}`)}>
                        {org.name} {CUST_TOOLS.compTool.name}
                    </span>
                )}
                {company && <span>{BREADCRUMB_DELIMITER}</span>}
                {company && (
                    <span>
                        {company.companyName} {CUST_TOOLS.compUserTool.name}
                    </span>
                )}
            </h5>
        </div>
    );
};

export default CustomerBreadCrumbs;
