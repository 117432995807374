import {
    requestGMBulkSetShouldVehicleBeRun,
    requestGMSetShouldVehicleBeRun,
    requestGMCImportPublicationOdata,
} from 'api/RepairProcedureApi';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { GMVehicle } from '../types';
import { ToastContext } from 'components/ToastProvider';
import { LoadingContext } from 'components/Layout';

const useGMVehicles = notifications => {
    const [vehicles, setVehicles] = useState<GMVehicle[]>([]);

    const [filters, setFilters] = useState<string>('');

    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const [loading, setLoading] = useState<boolean>(false);
    const { showToast } = useContext<{ showToast }>(ToastContext);

    const page = useRef(0);
    const pageSize = 100;

    const loadVehicles = useCallback(async () => {
        try {
            setLoading(true);
            incrementLoading();

            const vehicles = await getVehicles(page.current, pageSize, filters);
            page.current++;

            if (vehicles.data) {
                setVehicles(v => [...v, ...vehicles.data]);
            }
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            setLoading(false);
            decrementLoading();
        }
    }, [incrementLoading, filters, setVehicles, notifications, decrementLoading]);

    useEffect(() => {
        const getInitialVehicles = async () => {
            try {
                setLoading(true);
                incrementLoading();

                page.current = 0;

                const vehicles = await getVehicles(page.current, pageSize, filters);

                if (vehicles.error) {
                    showToast(vehicles.error);
                }
                setVehicles(vehicles.data);
                page.current++;
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                setLoading(false);
                decrementLoading();
            }
        };
        getInitialVehicles();
    }, [decrementLoading, filters, incrementLoading, notifications, setVehicles, showToast]);

    const turnGMVehiclesOn = useCallback(
        async (gmVehicleId: number) => {
            try {
                await requestGMSetShouldVehicleBeRun(gmVehicleId, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        v.importPublicationId === gmVehicleId
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications]
    );

    const turnGMVehiclesOff = useCallback(
        async (gmVehicleId: number) => {
            try {
                await requestGMSetShouldVehicleBeRun(gmVehicleId, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        v.importPublicationId === gmVehicleId
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications]
    );

    const bulkTurnGMVehiclesOn = useCallback(
        async (ids: number[]) => {
            try {
                await requestGMBulkSetShouldVehicleBeRun(ids, true, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.importPublicationId)
                            ? {
                                  ...v,
                                  shouldBeRun: true,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications]
    );

    const bulkTurnGmVehiclesOff = useCallback(
        async (ids: number[]) => {
            try {
                await requestGMBulkSetShouldVehicleBeRun(ids, false, true);
                setVehicles(vehicles =>
                    vehicles.map(v =>
                        ids.includes(v.importPublicationId)
                            ? {
                                  ...v,
                                  shouldBeRun: false,
                                  hasBeenReviewed: true,
                              }
                            : v
                    )
                );
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications]
    );

    const onSearch = (filter: string) => {
        const parsedFilters = `contains(pubName,'${filter}')`;
        setFilters(parsedFilters);
    };

    return {
        vehicles,
        isLoading: loading,
        loadMoreCallback: loadVehicles,
        onSearch,
        turnGMVehiclesOn,
        turnGMVehiclesOff,
        bulkTurnGMVehiclesOn,
        bulkTurnGmVehiclesOff,
    };
};

const getVehicles = async (page: number, pageSize: number, filter: string) => {
    try {
        const vehicles = await requestGMCImportPublicationOdata({
            top: pageSize,
            skip: pageSize * page,
            filter: filter ? filter : undefined,
        });
        return { data: vehicles.value, error: '' };
    } catch (e) {
        return { data: [], error: e };
    }
};

export default useGMVehicles;
