import React, { useContext, useEffect, useState } from 'react';
import ReactModal from 'components/Shared/ReactModal';
import EditScheduleModalHeader from './EditScheduleModalHeader';
import EditScheduleModalBody from './EditScheduleModalBody';
import EditScheduleModalFooter from './EditScheduleModalFooter';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { OemRefreshSchedule } from '../../types';
import ImportManagerDashboardContext from '../importManagerDashboardContext';

interface EditScheduleModalProps {
    data: OemRefreshSchedule | null;
    onToggle: () => void;
    onSave: (id: number, isActive: boolean) => void;
    onPause: (id: number) => void;
}

const EditScheduleModal: React.FC<EditScheduleModalProps> = ({ data, onToggle, onSave, onPause }) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const { hasAccess } = useContext(AccessControlContext);
    const { importJobDownloadTypes } = useContext(ImportManagerDashboardContext);

    useEffect(() => {
        setIsActive(data?.isActive ?? false);
    }, [data]);

    const handleIsActiveCheckboxChange = () => setIsActive(prev => !prev);
    const handleSaveButtonClick = () => {
        onSave(data.id, isActive);
    };
    const handlePauseButtonClick = () => {
        onPause(data.id);
    };

    const oemName = data?.oem.oemName ?? null;
    const nextScheduledRun = data?.nextScheduledRun ?? '';
    const cronInterval = data?.cronInterval ?? '';
    const saveButtonDisabled = isActive === data?.isActive || !hasAccess('importManager.editRefreshSchedule');
    const pauseButtonDisabled = data?.isActive !== true || !hasAccess('importManager.pauseRefreshSchedule');
    const switchDisabled = !hasAccess('importManager.editRefreshSchedule');
    const downloadType =
        (data?.downloadTypeId
            ? importJobDownloadTypes.find(t => t.importJobDownloadTypeId === data.downloadTypeId)
                  ?.importJobDownloadTypeName
            : null) ?? '-';

    return (
        <ReactModal
            id="edit-refresh-schedule-modal"
            headerComponent={<EditScheduleModalHeader oemName={oemName} />}
            isOpen={!!oemName}
            container={undefined}
            toggle={onToggle}
            bodyComponent={
                <EditScheduleModalBody
                    downloadType={downloadType}
                    isActive={isActive}
                    nextScheduledRun={nextScheduledRun}
                    cronInterval={cronInterval}
                    onIsActiveCheckboxChange={handleIsActiveCheckboxChange}
                    pauseButtonDisabled={pauseButtonDisabled}
                    switchDisabled={switchDisabled}
                    onPauseButtonClick={handlePauseButtonClick}
                />
            }
            footerComponent={
                <EditScheduleModalFooter
                    saveButtonDisabled={saveButtonDisabled}
                    onCancel={onToggle}
                    onSave={handleSaveButtonClick}
                />
            }
        />
    );
};

export default EditScheduleModal;
