import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
    DataGrid,
    GridActionsCellItem,
    GridCell,
    GridCellProps,
    GridColDef,
    GridEditInputCell,
    GridPreProcessEditCellProps,
    GridRenderEditCellParams,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridRowParams,
    useGridApiContext,
} from '@mui/x-data-grid';
import { requestCreateCompanyUser, requestOrganizationCompanies, requestRoles } from 'api/SecurityApi';
import { LoadingContext } from 'components/Layout';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { ReactModal } from 'oemiq-common';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import { Chip, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { IMultiUserModal, IOrgLocation, IRole, IUserData } from './types';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import {
    CELL_MODES,
    COLUMN_FIELD_NAME,
    COLUMN_HEADER_NAME,
    columnPlaceholders,
    maxUserCount,
    validEmail,
    validName,
    validPhoneNumber,
} from './MultiUserModalConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme } from '@mui/material/styles';
import DropdownIcon from 'components/Shared/Components/DropdownIcon';

const MultiUserModal = ({ isOpen, toggle, orgId, companyId, onSaveOrEdit }: IMultiUserModal) => {
    const initialUserRowData: IUserData = {
        id: 1,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        primaryLocation: null,
        additionalLocations: [],
        role: null,
    } as IUserData;
    const ref = useRef([initialUserRowData]);
    const [userDetails, setUserDetails] = useState<IUserData[]>([initialUserRowData]);
    const [failedEmails, setFailedEmails] = useState<string[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState<boolean>(false);
    const [hasInCompleteEntry, setHasInCompleteEntry] = useState<boolean>(true);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [roles, setRoles] = useState<IRole[]>([]);
    const [orgLocations, setOrgLocations] = useState<IOrgLocation[]>([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { notifications } = useContext(NotificationsContext);
    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState<IOrgLocation>({} as IOrgLocation);
    const customTheme = createTheme({
        palette: {
            error: {
                main: '#dc3545',
            },
        },
    });

    //Handles Row Click to reset previous edit mode row to view mode
    const handleRowClick = (params: GridRowParams) => {
        setRowModesModel(prevModel => {
            return {
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: { mode: GridRowModes.View },
                    }),
                    {}
                ),
                [params.id]: { mode: GridRowModes.Edit },
            };
        });
    };

    ///useEffect to load Roles and Locations
    useEffect(() => {
        if (isOpen) {
            const getRoles = async () => {
                try {
                    incrementLoading();
                    const rolesJson = await requestRoles();
                    setRoles(rolesJson);
                } catch (error) {
                    notifications.pushExceptionDanger(error);
                } finally {
                    decrementLoading();
                }
            };
            const getLocations = async () => {
                try {
                    incrementLoading();
                    const orgCompanies: IOrgLocation[] = await requestOrganizationCompanies(orgId);
                    if (companyId) {
                        if (orgCompanies.some((company: IOrgLocation) => company.companyId === companyId)) {
                            const selectedCompanyDetail = orgCompanies.find(
                                (company: IOrgLocation) => company.companyId === companyId
                            );
                            if (selectedCompanyDetail) {
                                setSelectedCompanyDetail(selectedCompanyDetail);
                            }
                        }
                    }
                    setOrgLocations(orgCompanies);
                } catch (error) {
                    notifications.pushExceptionDanger(error);
                } finally {
                    decrementLoading();
                }
            };
            getRoles();
            getLocations();
        }
    }, [orgId, notifications, incrementLoading, decrementLoading, companyId, isOpen]);

    //useEffect to update Primary and Secondary Location if user added from organization's location screen
    useEffect(() => {
        if (
            companyId &&
            selectedCompanyDetail.companyId &&
            !userDetails.every(
                (userData: IUserData) =>
                    userData.additionalLocations &&
                    userData.additionalLocations.some((location: IOrgLocation) => location.companyId === companyId)
            )
        ) {
            const _updatedUserDetails = userDetails.map((userData: IUserData) => {
                const _updatedUserData: IUserData = { ...userData, primaryLocation: selectedCompanyDetail };
                if (
                    _updatedUserData.additionalLocations &&
                    !_updatedUserData.additionalLocations.some(
                        (location: IOrgLocation) => location.companyId === selectedCompanyDetail.companyId
                    )
                ) {
                    _updatedUserData.additionalLocations = [selectedCompanyDetail].concat(
                        _updatedUserData.additionalLocations
                    );
                }
                return _updatedUserData;
            });
            ref.current = _updatedUserDetails;
            setUserDetails(_updatedUserDetails);
        }
    }, [selectedCompanyDetail, userDetails, companyId]);

    //Slot Component to highlight missing data cell
    const CustomCell = (props: GridCellProps) => {
        const { children, ...other } = props;
        const placeholder = (
            <Typography
                className="input-placeholder"
                sx={{
                    color: `${
                        userDetails.length > 1 &&
                        parseInt(other.rowId.toString()) < userDetails.length &&
                        props.field !== COLUMN_FIELD_NAME.PHONE &&
                        !isNotEmptyField(props.field, props.value)
                            ? customTheme.palette.error.main
                            : '#6c757d'
                    }`,
                    lineHeight: '49px',
                    fontWeight: '400',
                    fontSize: '1rem',
                    fontFamily:
                        "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,'Open Sans', 'Helvetica Neue', sans-serif",
                }}>
                {`${getPlaceholders[props.field]}`}
            </Typography>
        );
        return (
            <GridCell {...other}>
                {other.cellMode === CELL_MODES.EDIT || isNotEmptyField(props.field, props.value) || !other.isEditable
                    ? children
                    : placeholder}
            </GridCell>
        );
    };

    //To Check if the field has a valid data
    const isNotEmptyField = (field: string, value: string | string[]) =>
        (value !== undefined && value != null && value !== '' && field !== COLUMN_FIELD_NAME.ADDITIONAL_LOCATIONS) ||
        (field === COLUMN_FIELD_NAME.ADDITIONAL_LOCATIONS && value && value.length > 0);

    //Resets all state values
    const resetAll = () => {
        setUserDetails([initialUserRowData]);
        ref.current = [initialUserRowData];
        setHasInCompleteEntry(false);
        setHasError(false);
        toggle();
        onSaveOrEdit();
        setIsConfirmationModalOpened(false);
        setFailedEmails([]);
        setRowModesModel({});
    };

    // Render Primary Location on View Mode
    const renderPrimaryLocationCell = params => {
        const primaryLocation = companyId
            ? selectedCompanyDetail
            : userDetails[params.id - 1]
            ? userDetails[params.id - 1].primaryLocation
            : undefined;
        return <Typography>{primaryLocation ? primaryLocation.companyName : ''}</Typography>;
    };

    // Render Role on View Mode
    const renderRoleCell = params => {
        const selectedRole = roles.find((role: IRole) => params.value && role.name === params.value.name);
        return <Typography>{selectedRole ? selectedRole.name : ''}</Typography>;
    };

    // Render Additional Location on View Mode
    const renderAdditionalLocation = params => {
        const { value, row } = params;
        const { primaryLocation } = row;
        return (
            value &&
            value.map((option: IOrgLocation) => (
                <Chip
                    key={`chip${option.companyId}`}
                    label={option.companyName}
                    className="additional-chip-margin"
                    disabled={
                        primaryLocation?.companyId === option.companyId ||
                        selectedCompanyDetail?.companyId === option.companyId
                    }
                    onDelete={() => {
                        const updatedUserDetails = userDetails.map((userData: IUserData) => {
                            if (userData.primaryLocation.companyId !== option.companyId && userData.id === params.id) {
                                userData.additionalLocations = userData.additionalLocations.filter(
                                    (location: IOrgLocation) => location.companyId !== option.companyId
                                );
                            }
                            return userData;
                        });
                        ref.current = updatedUserDetails;
                        setUserDetails(updatedUserDetails);
                    }}></Chip>
            ))
        );
    };

    // Render Additional Location on Edit Mode
    const renderAdditionalLocationEditInputCell: GridColDef['renderCell'] = params => {
        return <AdditionalLocationEditInputCell {...params} />;
    };

    // Render Primary Location on Edit Mode
    const renderPrimaryLocationEditInputCell: GridColDef['renderCell'] = params => {
        return <PrimaryLocationEditInputCell {...params} />;
    };

    // Render Role on Edit Mode
    const renderRoleEditInputCell: GridColDef['renderCell'] = params => {
        return <RoleEditInputCell {...params} />;
    };

    // Additional Location Edit Component
    const AdditionalLocationEditInputCell = params => {
        const { id, value, field, hasFocus, row } = params;
        const { primaryLocation } = row;
        const apiRef = useGridApiContext();
        const ref = React.useRef<HTMLElement>();

        // handle additional location selection and removal event
        // do not remove primary location from Additional Location list
        const handleChange = (event: React.SyntheticEvent, newValue: IOrgLocation[] | null) => {
            if (
                primaryLocation === null ||
                (primaryLocation.companyId &&
                    !newValue.some((location: IOrgLocation) => location.companyId === primaryLocation.companyId))
            ) {
                newValue =
                    value && value.length > 0
                        ? value.filter(
                              (location: IOrgLocation) =>
                                  primaryLocation === null || location.companyId === primaryLocation.companyId
                          )
                        : newValue;
            }
            updateField(newValue, field, id as number);
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        //set focus to additional location cell on tab key
        useEnhancedEffect(() => {
            if (hasFocus && ref.current) {
                const input = ref.current.querySelector<HTMLInputElement>(`input[value=""]`);
                input?.focus();
            }
        }, [hasFocus, value]);

        //if loaded from location page of an organization then preselect it even if it is not selected
        let selectedAdditionalLocations: IOrgLocation[] = [];
        if (companyId)
            selectedAdditionalLocations = [selectedCompanyDetail].concat(
                value.filter((option: IOrgLocation) => option.companyId !== selectedCompanyDetail.companyId)
            );
        else selectedAdditionalLocations = value;

        return (
            <Autocomplete
                ref={ref}
                multiple
                fullWidth
                popupIcon={<DropdownIcon />}
                options={orgLocations}
                filterSelectedOptions
                getOptionLabel={option => option.companyName}
                renderInput={params => (
                    <TextField
                        {...params}
                        className="chevron-top-aligned"
                        placeholder={value.length === 0 && getPlaceholders[field]}
                    />
                )}
                value={selectedAdditionalLocations}
                onChange={(e, nv) => {
                    handleChange(e, nv);
                }}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            key={`chip${option.companyId}`}
                            label={option.companyName}
                            className="disabled-chip"
                            {...getTagProps({ index })}
                            disabled={
                                primaryLocation?.companyId === option.companyId ||
                                selectedCompanyDetail?.companyId === option.companyId
                            }
                        />
                    ))
                }
                isOptionEqualToValue={(option: IOrgLocation, value: IOrgLocation) =>
                    option.companyId === value.companyId
                }
                disableClearable
            />
        );
    };

    // Primary Location Edit Component
    const PrimaryLocationEditInputCell = params => {
        const { id, value, field, hasFocus, row } = params;
        const { additionalLocations } = row;
        const apiRef = useGridApiContext();
        const ref = React.useRef<HTMLElement>();

        // handle Primary Location selection
        // update additional location selection based on primary location selection
        const handleChange = (event: React.SyntheticEvent, newValue: IOrgLocation | null) => {
            let newAdditionalLocations: IOrgLocation[] = [];
            if (newValue) {
                if (additionalLocations.some((location: IOrgLocation) => location.companyId === newValue.companyId)) {
                    newAdditionalLocations = additionalLocations;
                } else {
                    additionalLocations.push(newValue);
                    newAdditionalLocations = additionalLocations;
                }
                updateField(newAdditionalLocations, COLUMN_FIELD_NAME.ADDITIONAL_LOCATIONS, id as number);
            }
            updateField(newValue, field, id as number);
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        //set focus on Additional Location on tab key press
        useEnhancedEffect(() => {
            if (hasFocus && ref.current) {
                const input = ref.current.querySelector<HTMLInputElement>(`input[value=""]`);
                input?.focus();
            }
        }, [hasFocus, value]);

        return (
            <Autocomplete
                ref={ref}
                fullWidth
                popupIcon={<DropdownIcon />}
                className="auto-complete-height-100"
                options={orgLocations}
                getOptionLabel={option => option.companyName}
                renderInput={params => (
                    <TextField {...params} className="chevron-top-aligned" placeholder={getPlaceholders[field]} />
                )}
                value={value}
                onChange={(e, nv) => {
                    handleChange(e, nv);
                }}
                isOptionEqualToValue={(option: IOrgLocation, value: IOrgLocation) =>
                    option.companyId === value.companyId
                }
                clearIcon={null}
            />
        );
    };

    // Role Edit Component
    const RoleEditInputCell = params => {
        const { id, value, field, hasFocus } = params;
        const apiRef = useGridApiContext();
        const ref = React.useRef<HTMLElement>();

        //handle role selection
        const handleChange = (event: React.SyntheticEvent, newValue: IRole | null) => {
            if (newValue) {
                updateField(newValue, field, id as number);
            }
            apiRef.current.setEditCellValue({ id, field, value: newValue });
        };

        //set focus on to Role Cell on tab key press
        useEnhancedEffect(() => {
            if (hasFocus && ref.current) {
                const input = ref.current.querySelector<HTMLInputElement>(`input[value=""]`);
                input?.focus();
            }
        }, [hasFocus, value]);

        return (
            <Autocomplete
                ref={ref}
                fullWidth
                popupIcon={<DropdownIcon />}
                className="auto-complete-height-100"
                options={roles}
                getOptionLabel={role => role.name}
                renderInput={params => (
                    <TextField {...params} className="chevron-top-aligned" placeholder={getPlaceholders[field]} />
                )}
                value={value !== '' ? value : null}
                onChange={(e, nv) => {
                    handleChange(e, nv);
                }}
                isOptionEqualToValue={(option: IRole, value: IRole) => option.id === value.id}
            />
        );
    };

    const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip
            {...props}
            arrow
            classes={{ popper: className }}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 6],
                            },
                        },
                    ],
                },
            }}
        />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: customTheme.palette.error.main,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: customTheme.palette.error.main,
            color: theme.palette.error.contrastText,
        },
    }));

    // handle Delete Row event.
    // before deleting row ignore any changes that are saved to the table modal
    const handleDeleteRow = (rowId: GridRowId, userDetails: IUserData[]) => {
        setRowModesModel(prevModel => {
            return {
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: { mode: GridRowModes.View, ignoreModifications: true },
                    }),
                    {}
                ),
            };
        });

        if (userDetails.some((userData: IUserData) => userData.id === rowId) && userDetails.length > 1) {
            const newData = userDetails
                .filter((userData: IUserData) => userData.id !== rowId)
                .map((userData: IUserData, index: number) => {
                    const _userData = { ...userData, id: index + 1 };
                    return _userData;
                });

            if (rowId === maxUserCount && newData.length === 19) {
                newData.push({
                    ...initialUserRowData,
                    id: maxUserCount,
                    primaryLocation: selectedCompanyDetail.companyId ? selectedCompanyDetail : null,
                    additionalLocations: selectedCompanyDetail.companyId ? [selectedCompanyDetail] : [],
                });
            }

            ref.current = newData;
            setUserDetails(newData);

            const validUserDetails = newData.filter((userData: IUserData) => validateRow(userData));
            setHasInCompleteEntry(validUserDetails.length !== newData.length - 1);
        }
    };

    // validate all text fields
    const validateField = (fieldValue: string, fieldName: string, rowId: number) => {
        switch (fieldName) {
            case COLUMN_FIELD_NAME.FIRST_NAME:
                return fieldValue === '' ||
                    (isNotEmptyField(COLUMN_FIELD_NAME.FIRST_NAME, fieldValue) &&
                        fieldValue.trim() !== '' &&
                        validName.test(fieldValue))
                    ? ''
                    : 'Invalid First Name';
            case COLUMN_FIELD_NAME.LAST_NAME:
                return fieldValue === '' ||
                    (isNotEmptyField(COLUMN_FIELD_NAME.LAST_NAME, fieldValue) &&
                        fieldValue.trim() !== '' &&
                        validName.test(fieldValue))
                    ? ''
                    : 'Invalid Last Name';
            case COLUMN_FIELD_NAME.EMAIL:
                if (
                    isNotEmptyField(COLUMN_FIELD_NAME.EMAIL, fieldValue) &&
                    (fieldValue === '' || fieldValue.trim() !== '')
                )
                    if (!validEmail.test(fieldValue)) return 'Invalid Email';
                    else if (
                        userDetails.some(
                            (userData: IUserData) =>
                                userData.id < rowId && userData.email !== '' && userData.email === fieldValue
                        )
                    )
                        return 'Email Id already taken';
                    else return '';
                else return '';
            case COLUMN_FIELD_NAME.PHONE:
                return fieldValue === '' ||
                    (isNotEmptyField(COLUMN_FIELD_NAME.PHONE, fieldValue) &&
                        fieldValue.trim() !== '' &&
                        validPhoneNumber.test(fieldValue))
                    ? ''
                    : 'Invalid Phone Number';
            default:
                break;
        }
    };

    // Update field value to table data state
    const updateField = (value: string | IOrgLocation | IOrgLocation[] | IRole, field: string, rowId: number) => {
        const _updatedUserDetails = ref.current.map((userData: IUserData) => {
            const _userData = { ...userData };
            if (_userData.id === rowId) {
                _userData[field] = value;
            }
            return _userData;
        });
        setHasInCompleteEntry(
            _updatedUserDetails
                .filter((userData: IUserData) => userData.id !== _updatedUserDetails.length)
                .some((userData: IUserData) => !validateRow(userData))
        );
        ref.current = _updatedUserDetails;
    };

    // Edit Component of all other cells
    const EditInputCell = (props: GridRenderEditCellParams) => {
        const { error } = props;

        return (
            <StyledTooltip open={error && !isConfirmationModalOpened} title={error}>
                <div>
                    <GridEditInputCell {...props} placeholder={getPlaceholders[props.field]} />
                </div>
            </StyledTooltip>
        );
    };

    // Render Other cell Edit field
    function renderEditField(params: GridRenderEditCellParams) {
        return <EditInputCell {...params} />;
    }

    // Verifies if new Row can be added and adds a new row
    const checkAndAddNewRow = (id: number, fieldValue: string, fieldName: string) => {
        const _updatedRows = userDetails.map(userData => {
            const _userData = { ...userData };
            if (_userData.id === id) {
                _userData[fieldName] = fieldValue;
            }
            return _userData;
        });
        setHasInCompleteEntry(
            _updatedRows
                .filter((userData: IUserData) => userData.id !== _updatedRows.length)
                .some((userData: IUserData) => !validateRow(userData))
        );
        if (_updatedRows.length < maxUserCount) {
            _updatedRows.push({
                ...initialUserRowData,
                id: id + 1,
                primaryLocation: selectedCompanyDetail.companyId ? selectedCompanyDetail : null,
                additionalLocations: selectedCompanyDetail.companyId ? [selectedCompanyDetail] : [],
            });
        }
        ref.current = _updatedRows;
        setUserDetails(_updatedRows);
        setHasError(false);
    };

    const preProcessEditCell = (params: GridPreProcessEditCellProps, fieldName: string) => {
        if (params.hasChanged) {
            const lastCell = userDetails[userDetails.length - 1];
            if ((params.id as number) === lastCell.id) {
                checkAndAddNewRow(params.id as number, params.props.value, fieldName);
            } else updateField(params.props.value, fieldName, params.id as number);
        }

        const errorMessage = validateField(params.props.value, fieldName, params.id as number);
        return { ...params.props, error: errorMessage };
    };

    const getCellClassName = params => {
        const isInValidField = validateField(params.value, params.field, params.row.id);
        if (isInValidField || (params.field === COLUMN_FIELD_NAME.EMAIL && failedEmails.indexOf(params.value) >= 0)) {
            return 'error-cell';
        } else return '';
    };

    const renderDeleteIcon = (id: GridRowId) => {
        return [
            userDetails[userDetails.length - 1]?.id === id && userDetails.length < maxUserCount ? (
                <GridActionsCellItem
                    style={{ backgroundColor: 'var(--bs-btn-disabled-bg)' }}
                    className="btn btn-secondary btn-sm p-2 rounded"
                    icon={<FontAwesomeIcon icon="trash" color="white" size="sm" />}
                    label="Delete"
                    disabled
                />
            ) : (
                <GridActionsCellItem
                    style={{ backgroundColor: customTheme.palette.error.main }}
                    className="btn btn-danger btn-sm p-2 rounded"
                    icon={<FontAwesomeIcon icon="trash" color="white" size="sm" />}
                    label="Delete"
                    onClick={() => handleDeleteRow(id, userDetails)}
                />
            ),
        ];
    };

    // Grid Columns
    const columns: GridColDef[] = [
        {
            field: COLUMN_FIELD_NAME.ROW_NO,
            headerName: COLUMN_HEADER_NAME.ROW_NO,
            width: 50,
            sortable: false,
            valueGetter: params => params.row.id,
        },
        {
            field: COLUMN_FIELD_NAME.FIRST_NAME,
            headerName: COLUMN_HEADER_NAME.FIRST_NAME,
            width: 150,
            editable: true,
            renderEditCell: renderEditField,
            cellClassName: getCellClassName,
            sortable: false,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.FIRST_NAME),
        },
        {
            field: COLUMN_FIELD_NAME.LAST_NAME,
            headerName: COLUMN_HEADER_NAME.LAST_NAME,
            width: 150,
            editable: true,
            sortable: false,
            renderEditCell: renderEditField,
            cellClassName: getCellClassName,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.LAST_NAME),
        },
        {
            field: COLUMN_FIELD_NAME.EMAIL,
            headerName: COLUMN_HEADER_NAME.EMAIL,
            width: 180,
            editable: true,
            sortable: false,
            renderEditCell: renderEditField,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.EMAIL),
            cellClassName: getCellClassName,
        },
        {
            field: COLUMN_FIELD_NAME.PHONE,
            headerName: COLUMN_HEADER_NAME.PHONE,
            width: 140,
            editable: true,
            sortable: false,
            renderEditCell: renderEditField,
            cellClassName: getCellClassName,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.PHONE),
        },
        {
            field: COLUMN_FIELD_NAME.PRIMARY_LOCATION,
            headerName: COLUMN_HEADER_NAME.PRIMARY_LOCATION,
            width: 230,
            sortable: false,
            editable: companyId ? false : true,
            renderCell: renderPrimaryLocationCell,
            renderEditCell: renderPrimaryLocationEditInputCell,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.PRIMARY_LOCATION),
        },
        {
            field: COLUMN_FIELD_NAME.ADDITIONAL_LOCATIONS,
            headerName: COLUMN_HEADER_NAME.ADDITIONAL_LOCATIONS,
            width: 300,
            editable: true,
            sortable: false,
            renderCell: renderAdditionalLocation,
            renderEditCell: renderAdditionalLocationEditInputCell,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.ADDITIONAL_LOCATIONS),
        },
        {
            field: COLUMN_FIELD_NAME.ROLE,
            headerName: COLUMN_HEADER_NAME.ROLE,
            width: 320,
            editable: true,
            sortable: false,
            renderCell: renderRoleCell,
            renderEditCell: renderRoleEditInputCell,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) =>
                preProcessEditCell(params, COLUMN_FIELD_NAME.ROLE),
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            sortable: false,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return renderDeleteIcon(id);
            },
        },
    ];

    // Gets Place Holders of every cell
    const getPlaceholders = columns.reduce((acc, column) => {
        acc[column.field] = columnPlaceholders[column.field];
        return acc;
    }, {});

    //updated Row Mode when clicked
    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    //To Validate every row before sending an API call
    const validateRow = (updatedRow: IUserData) =>
        !(
            updatedRow.additionalLocations === null ||
            updatedRow.additionalLocations === undefined ||
            updatedRow.additionalLocations.length === 0 ||
            updatedRow.lastName === '' ||
            !validName.test(updatedRow.lastName) ||
            updatedRow.email === '' ||
            !validEmail.test(updatedRow.email) ||
            updatedRow.firstName === '' ||
            !validName.test(updatedRow.firstName) ||
            (updatedRow.phone !== '' && !validPhoneNumber.test(updatedRow.phone)) ||
            updatedRow.primaryLocation === undefined ||
            updatedRow.primaryLocation === null ||
            updatedRow.role === null ||
            updatedRow.role === undefined
        );

    //Function that gets triggered when a particular row switches from Edit to View mode
    const processRowUpdate = (updatedRow: IUserData) => {
        const updatedUserDetails = userDetails.map((userData: IUserData) => {
            const _userData = { ...userData };
            if (_userData.id === updatedRow.id) return updatedRow;
            return _userData;
        });
        ref.current = updatedUserDetails;
        setUserDetails(updatedUserDetails);
        if (userDetails.length !== updatedRow.id)
            setHasInCompleteEntry(
                updatedUserDetails
                    .filter((userData: IUserData) => userData.id !== userDetails.length)
                    .some((updatedRow: IUserData) => !validateRow(updatedRow))
            );
        return updatedRow;
    };

    const removeUserDetailsWithSameEmailId = () => {
        const uniqueUserDetails: IUserData[] = [ref.current[0]];
        for (let index = 1; index < ref.current.length; index++) {
            if (
                !uniqueUserDetails.some(
                    (uniqueUserData: IUserData) => uniqueUserData.email === ref.current[index].email
                )
            )
                uniqueUserDetails.push(ref.current[index]);
        }
        return uniqueUserDetails;
    };

    //handles Save User
    // step 1: get unique user details based on email id. No need to api call for duplicate email id as it might fail for sure.
    // step 2: loops and filters valid rows
    // step 3: maps all the request into one promise array
    // step 4: if promise array has atleast one promise waits for all promises to be settled in backed
    // step 5: if promise has been fulfilled updated processed User Details
    //          else update failedEmail Id (from API code we are currently validating only existing email id) and push a notification
    // step 6: Remove processed users from user list and show only error user list on the gird
    //          If all the details are updated successfully close popup
    const handleSaveUsers = async () => {
        const processedUserDetails: IUserData[] = [];
        const uniqueUserDetails = removeUserDetailsWithSameEmailId();
        const validUserDetails = uniqueUserDetails.filter((userData: IUserData) => validateRow(userData));
        if (
            uniqueUserDetails.length < userDetails.length ||
            (userDetails.length === maxUserCount && validUserDetails.length < userDetails.length) ||
            validUserDetails.length < userDetails.length - 1
        ) {
            setHasInCompleteEntry(true);
            return;
        }
        const promiseArray = validUserDetails.map(async (userData: IUserData) => InvokeSaveOpertaion(userData));
        if (promiseArray && promiseArray.length > 0) {
            Promise.allSettled(promiseArray).then(responses => {
                const failedEmails = [];
                responses.forEach(response => {
                    if (response.status === 'fulfilled' && response.value) {
                        processedUserDetails.push(response.value);
                    } else if (response.status === 'rejected' && response.reason) {
                        const failedEmail = JSON.parse(response.reason.message).message.split(' ')[0];
                        failedEmails.push(failedEmail);
                        notifications.pushExceptionDanger(response.reason.message, {
                            pinned: false,
                            body: response.reason.message.split(';'),
                        });
                    }
                });
                setFailedEmails(failedEmails);
                if (
                    processedUserDetails &&
                    processedUserDetails.length <
                        ref.current.filter((userData: IUserData) => validateRow(userData)).length
                ) {
                    const updatedUserDetails = ref.current.filter(
                        (userData: IUserData) =>
                            !processedUserDetails.some(
                                (processedUserData: IUserData) => processedUserData.id === userData.id
                            )
                    );
                    setUserDetails(updatedUserDetails);
                    ref.current = updatedUserDetails;
                    setHasError(true);
                    setHasInCompleteEntry(false);
                } else {
                    notifications.pushSuccess(`User(s) successfully added!`);
                    resetAll();
                }
            });
        }
    };

    // Invokes Save API
    // if success returns user data that got processed
    const InvokeSaveOpertaion = async (userData: IUserData) => {
        let response = {};

        response = await requestCreateCompanyUser(
            userData.primaryLocation.companyId,
            userData.firstName,
            userData.lastName,
            userData.email,
            userData.phone,
            userData.role.name,
            userData.additionalLocations.map(l => l.companyId)
        );
        if (response) {
            return userData;
        } else {
            return initialUserRowData;
        }
    };

    return (
        <>
            <ReactModal
                id="create-or-edit-user"
                headerComponent={'Create New User'}
                isOpen={isOpen}
                toggle={() => {
                    if (userDetails.length > 1) {
                        setIsConfirmationModalOpened(true);
                    } else {
                        resetAll();
                    }
                }}
                backdrop="true"
                className="multi-user-modal modal-dialog modal-xl"
                bodyComponent={
                    <div className="multi-user">
                        <DataGrid
                            rows={userDetails}
                            columns={columns}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            processRowUpdate={processRowUpdate}
                            slots={{ cell: CustomCell }}
                            sx={{
                                '& .MuiDataGrid-cell': {
                                    flexWrap: 'wrap',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 700,
                                    fontSize: '1rem',
                                },
                            }}
                            getRowHeight={() => 'auto'}
                            onRowClick={handleRowClick}
                            hideFooterPagination
                            hideFooter
                            disableColumnFilter
                            disableColumnMenu
                        />
                    </div>
                }
                footerComponent={
                    <div className="w-100 d-flex flex-column justify-content-between">
                        <div className="d-flex flex-column">
                            {/* {hasInCompleteEntry && <b className="text-danger-multi-user">*Fill all the mandatory fields.</b>} */}
                            {failedEmails.length > 0 && (
                                <b className="text-danger-multi-user">*Highlighted EmailId(s) are already in use</b>
                            )}
                            <b>*Indicates a required field</b>
                            {hasError && <b className="text-danger-multi-user">*Error in creating above user(s).</b>}
                        </div>
                        <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn btn-danger h-100"
                                id="user-cancel-button"
                                onClick={() => {
                                    if (userDetails.length > 1) {
                                        setIsConfirmationModalOpened(true);
                                    } else {
                                        resetAll();
                                    }
                                }}>
                                Cancel
                            </button>
                            <button
                                id="user-modal-save"
                                type="button"
                                className={`btn btn-primary ms-3 h-100 ${
                                    hasInCompleteEntry || userDetails.length === 0 ? 'disabled' : ''
                                }`}
                                onClick={handleSaveUsers}>
                                <div className="d-flex">Save</div>
                            </button>
                        </div>
                    </div>
                }
            />
            <ReactModal
                id="confirm-cancel-users-creation"
                isOpen={isConfirmationModalOpened}
                headerComponent={<b>{'Unsaved Changes'}</b>}
                bodyComponent={<div>Do you want to save your changes before closing this window?</div>}
                footerComponent={
                    <div>
                        <button type="button" className="btn btn-sm btn-danger h-100" onClick={resetAll}>
                            Dont Save
                        </button>
                        <button
                            type="button"
                            className={`btn btn-sm btn-primary ms-3 h-100 ${
                                hasInCompleteEntry || userDetails.length === 0 ? 'disabled' : ''
                            }`}
                            onClick={() => {
                                setIsConfirmationModalOpened(false);
                                handleSaveUsers();
                            }}>
                            Save
                        </button>
                    </div>
                }
            />
        </>
    );
};

export default MultiUserModal;
