import { BasicCellOptions } from 'components/Shared/Table/types';

export interface FordVehicle {
    fordScrapeManagerVehicleId: number;
    yearName: number;
    yearId: string;
    modelName: string;
    modelId: number;
    shouldVehicleBeRun: boolean;
    hasBeenReviewed: boolean;
    hasWorkshopManual: boolean;
    createDate: string;
    updateDate: string;
    [key: string]: string | boolean | number;
}

export interface GMVehicle {
    importPublicationId: number;
    pubsyskey: number;
    pubName: string;
    shouldBeRun: boolean;
    hasBeenReviewed: boolean;
    createDate: string;
    updateDate: string;
    [key: string]: string | boolean | number;
}

export enum IngestionButton {
    NewButton,
    OnButton,
    OffButton,
}

export interface IngestionProcess extends BasicCellOptions {
    newButton: {
        id: string;
        idKey?: string;
        title?: string;
        class?: string;
    };
    onButton: {
        id: string;
        idKey?: string;
        title?: string;
        class?: string;
    };
    offButton: {
        id: string;
        idKey?: string;
        title?: string;
        class?: string;
    };
}
