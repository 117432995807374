import { Text } from './Text';

export default {
    component: Text,
    format: v => `'${v}'`,
    operators: [
        {
            value: 'containsbookname',
            label: 'contains',
            buildFilter: (propertyName, value) =>
                `RpProcedure/BooksForProcedure/any(bp: contains(bp/Book/BookName, ${encodeURIComponent(value)}))`,
        },
        {
            value: 'notcontainsbookname',
            label: 'not contains',
            buildFilter: (propertyName, value) =>
                `RpProcedure/BooksForProcedure/any(bp: not contains(bp/Book/BookName, ${encodeURIComponent(value)}))`,
        },
        {
            value: 'eqbookname',
            label: '==',
            buildFilter: (propertyName, value) =>
                `RpProcedure/BooksForProcedure/any(bp: bp/Book/BookName eq ${encodeURIComponent(value)})`,
        },
        {
            value: 'nebookname',
            label: '!=',
            buildFilter: (propertyName, value) =>
                `RpProcedure/BooksForProcedure/any(bp: bp/Book/BookName ne ${encodeURIComponent(value)})`,
        },
    ],
    defaultValue: 0,
    allowFalse: false,
    allowInstances: 1,
};
