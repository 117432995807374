import React, { useState, useEffect, useMemo } from 'react';
import useModal from 'hooks/useModal';
import PreviewProceduresModal from './PreviewProceduresModal';
import { OemMetadata } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import useOemService from 'hooks/OemModels/useOemService';
import { buildFilters } from '../../Procedures/MappingProceduresTool';
import useColumnSettings from '../../Procedures/useColumnSettings';

import { PrimaryButton } from 'oemiq-common';
import { Operator } from '../RulableValuePicker/types';
import { operatorsList } from 'components/Shared/TableFilters/operators';
import { CreatorFilter } from '../types';

export const translateMetadataFilters = (filter: CreatorFilter) => {
    return {
        id: filter.propertyName,
        operator: convertOperators(filter.operator),
        value: filter.value,
    };
};

const previewProceduresColumnsIds = [
    'rpProcedureId',
    'firstBookId',
    'firstBookName',
    'rpProcedure.procedureTitle',
    'rpProcedure.groups',
    'groupsView',
    'TypeId',
    'TypeName',
];

interface PreviewProceduresProps {
    urlFilters: { urlFilter: string }[];
    oemMetadata: OemMetadata;
    oemId: number;
}

const PreviewProcedures = ({ urlFilters, oemMetadata, oemId }: PreviewProceduresProps) => {
    const filterBy = useMemo(() => buildFilters(urlFilters, [false, false, false]), [urlFilters]);
    const { oemService } = useOemService(oemId);
    const [totalCount, setTotalCount] = useState(null);
    useEffect(() => {
        async function countProcedures() {
            const count = await oemService.countProcedures(filterBy);
            setTotalCount(count);
        }

        setTotalCount(null);
        filterBy && countProcedures();
    }, [filterBy, oemService]);

    const { columnSettings } = useColumnSettings('MappingEngineTool', oemMetadata.properties);
    const previewProceduresColumnSettings = columnSettings.filter(x => previewProceduresColumnsIds.includes(x.id));

    const {
        isModalOpen: isPreviewProceduresModalOpen,
        openModal: openPreviewProceduresModal,
        closeModal: closePreviewProceduresModal,
    } = useModal();

    const handleSorting = () => {
        return null;
    };

    return (
        <div>
            <PrimaryButton
                type="button"
                className="show-procedures-button me-3"
                disabled={totalCount === null}
                onClick={openPreviewProceduresModal}>
                {totalCount === null ? '...' : `${totalCount} Procedures`}
            </PrimaryButton>
            <div>
                {isPreviewProceduresModalOpen && (
                    <PreviewProceduresModal
                        filterBy={filterBy}
                        oemId={oemId}
                        oemMetadata={oemMetadata}
                        isPreviewProceduresModalOpen={isPreviewProceduresModalOpen}
                        closePreviewProceduresModal={closePreviewProceduresModal}
                        columnSettings={previewProceduresColumnSettings}
                        handleSorting={handleSorting}
                        totalCount={totalCount}
                    />
                )}
            </div>
        </div>
    );
};

const convertOperators = (operator: Operator) => {
    switch (operator) {
        case Operator.Equal:
            return operatorsList.eq;
        case Operator.Contains:
            return operatorsList.contains;
        case Operator.NotContains:
            return operatorsList.notContains;
        default:
            return operatorsList.eq;
    }
};

export default PreviewProcedures;
