import { Number } from './Number';

export default {
    component: Number,
    format: v => v,
    operators: [
        {
            value: 'eqbookid',
            label: '==',
            buildFilter: (propertyName, value) => `RpProcedure/BooksForProcedure/any(bp: bp/BookId eq ${value})`,
        },
    ],
    defaultValue: 0,
    allowFalse: false,
    allowInstances: 1,
};
