import UserModal from '../UserModal';
import { ReactModal } from 'oemiq-common';
import CustomerBreadCrumbs from '../CustomerBreadCrumbs';
import useCompanyUsers from './useCompanyUsers';
import { Table } from 'oemiq-common';
import MultiUserModal from '../MultiUserModal';

const CompanyUsers = () => {
    const {
        editUser,
        locationId,
        organizationId,
        showModal,
        isOpenUserDisable,
        isOpenSendForgotPasswordEmail,
        org,
        company,
        searchTerm,
        displayUsers,
        tableColumnDetails,
        userHasCustomerSupportRole,
        handleToggle,
        onSaveOrEdit,
        setShowUserDisableModal,
        handleDisableEnable,
        setShowUserForgotPasswordEmailModal,
        setSearchTerm,
        handleSendForgotPassword,
        sortClickCallback,
    } = useCompanyUsers();

    return (
        <div>
            {/* Customer support doesn't have access to this, so hide it. This avoids 403 on API calls they can't do in this modal */}
            {(showModal && editUser && (
                <UserModal
                    onSaveOrEdit={onSaveOrEdit}
                    user={editUser}
                    companyId={locationId}
                    orgId={organizationId}
                    isOpen={showModal}
                    toggle={handleToggle}
                />
            )) || (
                <MultiUserModal
                    onSaveOrEdit={onSaveOrEdit}
                    orgId={organizationId}
                    isOpen={showModal}
                    toggle={handleToggle}
                    companyId={parseInt(locationId)}
                />
            )}
            <ReactModal
                id="confirm-user-disable-enable"
                isOpen={isOpenUserDisable}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser ? (
                        editUser.isDeactivated ? (
                            <div>{'Activate ' + editUser.userName}</div>
                        ) : (
                            <div>{'Deactivate ' + editUser.userName}</div>
                        )
                    ) : (
                        <></>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowUserDisableModal(false)}>
                            Cancel
                        </button>
                        <button
                            id="confirm-enable-disable"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleDisableEnable}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <ReactModal
                id="send-forgot-password"
                isOpen={isOpenSendForgotPasswordEmail}
                headerComponent={editUser ? <div>{'Are you sure?'}</div> : <></>}
                bodyComponent={
                    editUser ? (
                        <div>{'Send ' + editUser.userName + ' a forgot email password to ' + editUser.email + '?'}</div>
                    ) : (
                        <></>
                    )
                }
                footerComponent={
                    <div>
                        <button
                            type="button"
                            className="btn btn-sm btn-danger h-100"
                            onClick={() => setShowUserForgotPasswordEmailModal(false)}>
                            Cancel
                        </button>
                        <button
                            id="send-forgot-password-confirm"
                            type="button"
                            className="btn btn-sm btn-primary ms-3 h-100"
                            onClick={handleSendForgotPassword}>
                            Confirm
                        </button>
                    </div>
                }
            />
            <CustomerBreadCrumbs org={org} company={company} />
            <div className="row mt-3">
                <div className="col-2">
                    <input
                        id="user-search"
                        className="form form-control"
                        type="text"
                        placeholder="Search Company Users"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.currentTarget.value)}
                    />
                </div>
                {/* If user is customer support, hide create new user button */}
                {!userHasCustomerSupportRole && (
                    <div className="col">
                        <button
                            id="company-new-user"
                            type="button"
                            className="btn btn-sm btn-primary h-100"
                            onClick={() => handleToggle()}>
                            Create New User
                        </button>
                    </div>
                )}
            </div>
            <Table
                tableId="company-users-table"
                tableClassName="mt-3"
                isTableRowsClickable={false}
                columnDetails={tableColumnDetails}
                data={displayUsers}
                sortClickCallback={sortClickCallback}
                rowClickCallback={null}
            />
        </div>
    );
};

export default CompanyUsers;
