import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { requestOrganizations, requestOrganization, requestOrganizationCompanies } from 'api/SecurityApi';
import { faEdit, faSquarePlus, faDolly } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { roles } from 'components/Shared/AccessControl/privilegesMap';
import { CUST_TOOLS, OEC_STAGING_ORG } from '../../ManageCustomers/ManageCustomersConstants';

const useOrganizationCompanies = (handleOrgEditClick = () => {}, setEditOrgId = () => {}) => {
    // higher order states
    const navigate = useNavigate();
    const { showToast } = useContext(ToastContext);
    const { hasRole } = useContext(AccessControlContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const location = useLocation();
    const params = useParams();
    const { organizationId } = params; // user-provided organizationId
    // local states
    const userHasCustomerSupportRole = useMemo(() => hasRole(roles.customerSupport), [hasRole]);
    const [org, setOrg] = useState(null);
    const [orgs, setOrgs] = useState([]);
    const [orgCompanies, setOrgCompanies] = useState([]);
    const [displayCompanies, setDisplayCompanies] = useState([]);
    const [editCompanyId, setEditCompanyId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [sortedPropertyKey, setSortedPropertyKey] = useState(null);
    const [sortedPropertyDirection, setSortedPropertyDirection] = useState(null);
    const [showOrgModal, setShowOrgModal] = useState(false);
    const tableColumnDetails = [
        { title: 'Id', columnProperty: 'companyId', sortable: true },
        { title: 'Location Name', columnProperty: 'companyName', sortable: true },
        { title: 'Address', columnProperty: 'address', sortable: true },
        { title: 'City', columnProperty: 'city', sortable: true },
        { title: 'State', columnProperty: 'state', sortable: true },
        { title: 'ZIP', columnProperty: 'zip', sortable: true },
        { title: 'Country', columnProperty: 'countryName', sortable: true },
        { title: 'Phone', columnProperty: 'businessPhone', sortable: true },
        { title: 'Website', columnProperty: 'website', sortable: true },
        { title: 'Actions', columnProperty: 'actions', sortable: false },
    ];

    useEffect(() => {
        const getOrgsAndComps = async () => {
            const hasLocationState = location && location.state;
            const allOrgsSet = hasLocationState && location.state.allOrgs;
            const selectedOrgSet = hasLocationState && location.state.org;
            const selectedOrgCompaniesSet = hasLocationState && location.state.orgComps;

            if (allOrgsSet) {
                setOrgs(location.state.allOrgs);
            } else {
                try {
                    incrementLoading();
                    const orgs = await requestOrganizations();
                    setOrgs(orgs);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }

            if (selectedOrgSet) {
                setOrg(location.state.org);
            } else {
                try {
                    incrementLoading();
                    const org = await requestOrganization(organizationId);
                    setOrg(org);
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }

            const cleanDisplayCompanies = orgComps =>
                orgComps && orgComps.length > 0
                    ? orgComps.map(c => ({ ...c, countryName: c.country?.countryName || '' }))
                    : orgComps;

            if (selectedOrgCompaniesSet) {
                setOrgCompanies(location.state.orgComps);
                setDisplayCompanies(cleanDisplayCompanies(location.state.orgComps));
            } else {
                try {
                    incrementLoading();
                    const orgCompanies = await requestOrganizationCompanies(organizationId);
                    setOrgCompanies(orgCompanies);
                    setDisplayCompanies(cleanDisplayCompanies(orgCompanies));
                } catch (error) {
                    showToast(error);
                } finally {
                    decrementLoading();
                }
            }
        };

        getOrgsAndComps();
    }, [organizationId, showToast, incrementLoading, decrementLoading, location, setOrg, setOrgs]);

    const handleRowClick = useMemo(() => {
        if (!orgCompanies || orgCompanies.length === 0) return () => {};
        return clickedObject => {
            const locationId = clickedObject.companyId;
            const company = orgCompanies.find(c => c.companyId === locationId);
            navigate(`${CUST_TOOLS.compUserTool.Path(organizationId, locationId)}`, {
                state: { allOrgs: orgs, org: org, company: company },
            });
        };
    }, [navigate, orgCompanies, orgs, org, organizationId]);

    const handleOrgUsersClick = useMemo(() => {
        if (!organizationId) return () => {};
        return () => {
            navigate(`${CUST_TOOLS.orgUserTool.Path(organizationId)}`, {
                state: { allOrgs: orgs, org: org, orgComps: orgCompanies, company: null },
            });
        };
    }, [navigate, orgs, org, orgCompanies, organizationId]);

    const handleEditClick = (e, companyId) => {
        setEditCompanyId(companyId);
        setShowModal(true);
        e.stopPropagation();
    };

    const handleCreateOrgClick = useCallback(
        (e, companyId) => {
            setEditCompanyId(companyId);
            handleOrgEditClick(e, null /* create new org */);
            setShowOrgModal(true);
            e.stopPropagation();
        },
        [setEditCompanyId, setShowOrgModal, handleOrgEditClick]
    );

    const handleCompModalToggle = useCallback(
        newCompId => {
            if (showModal) setEditCompanyId(newCompId);
            setShowModal(!showModal);
            if (!newCompId) setEditOrgId(null);
        },
        [showModal, setEditCompanyId, setShowModal, setEditOrgId]
    );

    const handleOrgModalToggle = (targetOrgId, stagedCompId) => {
        if (targetOrgId) setEditOrgId(targetOrgId);
        setShowOrgModal(!showOrgModal);
        if (targetOrgId && stagedCompId) handleCompModalToggle(stagedCompId);
    };

    const onSaveOrEdit = async () => {
        try {
            incrementLoading();
            const res = await requestOrganizationCompanies(organizationId);
            const orgCompanies = res.map(c => ({ ...c, countryName: c.country?.countryName || '' }));
            setOrgCompanies(orgCompanies);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const sortClickCallback = (retSortedProperty, retSortedPropertyDirection) => {
        setSortedPropertyKey(retSortedProperty);
        setSortedPropertyDirection(retSortedPropertyDirection);
    };

    // Search Companies
    useEffect(() => {
        let newSortedAndSearchedCompanies = orgCompanies.map(c => ({ ...c }));

        //If there is a column requested to be sorted by, sort by that property and the sort direction
        if (sortedPropertyKey !== null) {
            if (sortedPropertyDirection === 'A')
                newSortedAndSearchedCompanies.sort((a, b) => {
                    if (sortedPropertyKey === 'companyId') return a[sortedPropertyKey] < b[sortedPropertyKey] ? -1 : 1;
                    else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? -1 : 1;
                });
            else
                newSortedAndSearchedCompanies.sort((a, b) => {
                    if (sortedPropertyKey === 'companyId') return a[sortedPropertyKey] < b[sortedPropertyKey] ? 1 : -1;
                    else return a[sortedPropertyKey]?.toLowerCase() < b[sortedPropertyKey]?.toLowerCase() ? 1 : -1;
                });
        }

        // todo: use fuzzy search
        if (searchTerm) {
            newSortedAndSearchedCompanies = orgCompanies.filter(
                c =>
                    c.companyId.toString().indexOf(searchTerm) > -1 ||
                    (c.companyName && c.companyName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.address && c.address.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.zip && c.zip.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.city && c.city.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.state && c.state.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.countryName && c.countryName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.businesPhone && c.businesPhone.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) ||
                    (c.website && c.website.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
            );
        }

        // Add component to object to table can render action component
        newSortedAndSearchedCompanies.forEach(c => {
            //If user is customer support hide edit org button
            if (userHasCustomerSupportRole) {
                c.actions = null;
            } else {
                c.actions =
                    parseInt(organizationId) === OEC_STAGING_ORG.organizationId ? (
                        <>
                            <FontAwesomeIcon
                                id={`staged-company-${c.companyId}-transfer`}
                                className="text-primary clickable m-1 customer-action-button"
                                icon={faDolly}
                                onClick={e => handleEditClick(e, c.companyId)}
                            />
                            <FontAwesomeIcon
                                id={`staged-company-${c.companyId}-transfer`}
                                className="text-primary clickable m-1 customer-action-button"
                                icon={faSquarePlus}
                                onClick={e => handleCreateOrgClick(e, c.companyId)}
                            />
                        </>
                    ) : (
                        <FontAwesomeIcon
                            id={`company-${c.companyId}-edit`}
                            className="text-primary clickable customer-action-button"
                            icon={faEdit}
                            onClick={e => handleEditClick(e, c.companyId)}
                        />
                    );
            }
        });
        setDisplayCompanies(newSortedAndSearchedCompanies);
    }, [
        organizationId,
        orgCompanies,
        searchTerm,
        sortedPropertyKey,
        sortedPropertyDirection,
        userHasCustomerSupportRole,
        handleCreateOrgClick,
        setShowOrgModal,
    ]);

    return {
        showModal,
        organizationId,
        editCompanyId,
        org,
        searchTerm,
        displayCompanies,
        tableColumnDetails,
        userHasCustomerSupportRole,
        onSaveOrEdit,
        handleCompModalToggle,
        setSearchTerm,
        handleOrgUsersClick,
        setShowModal,
        handleRowClick,
        handleEditClick,
        sortClickCallback,
        showOrgModal,
        handleOrgModalToggle,
    };
};

export default useOrganizationCompanies;
